import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Select, MenuItem, InputLabel, FormControl } from "@mui/material";

function PersonalInformation({
  shippingInfo,
  setShippingInfo,
  handleChange,
  handleNextStep,
}) {
  const states = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ];

  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <Container>
      <Heading>Full Name (First and Last Name)</Heading>

      <Input
        ref={inputRef}
        // placeholder="Full Name (Required)"
        type="text"
        fullWidth
        name="fullname"
        value={shippingInfo.fullname}
        onChange={(e) => handleChange(e, setShippingInfo)}
      />

      <Row>
        <Heading>Mobile Number</Heading>

        {shippingInfo.mobileNumber.length == 10 && (
          <CheckCircleIcon
            style={{
              color: "green",
              fontSize: "4.3vw",
              marginLeft: "5px",
            }}
          />
        )}
      </Row>

      <Row>
        <MobileDiv>+91</MobileDiv>
        <MobileInput
          style={{ marginBottom: "0px" }}
          type="number"
          //   placeholder="eg. 9817251215"
          name="mobileNumber"
          value={shippingInfo.mobileNumber}
          onChange={(e) => handleChange(e, setShippingInfo)}
        />
      </Row>

      <Row style={{ marginTop: "10px" }}>
        <Heading>Email Address</Heading>
        {shippingInfo.email.length > 11 &&
          shippingInfo.email.includes("@gmail.com") && (
            <CheckCircleIcon
              style={{
                color: "green",
                fontSize: "4.3vw",
                marginLeft: "5px",
              }}
            />
          )}
      </Row>
      <Input
        type="text"
        //   placeholder="xyz@gmail.com"
        name="email"
        value={shippingInfo.email}
        onChange={(e) => handleChange(e, setShippingInfo)}
      />

      <Heading
        style={{ marginTop: "20px", fontSize: "3vw", fontWeight: "500" }}
      >
        Delivery Address :-
      </Heading>

      <Heading>Country</Heading>

      <Input
        style={{
          fontSize: "13px",
          fontWeight: "500",
          backgroundColor: "#f3f5f7",
        }}
        type="text"
        name="state"
        value={"India"}
        disabled
      />
      <Heading
        style={{
          marginTop: "-5px",
          fontSize: "2.8vw",
          color: "#1b1303",
          fontWeight: "400",
        }}
      >
        We currently deliver in India Only
      </Heading>

      <Heading style={{ marginTop: "15px" }}>
        Flat, House no., Building, Company, Apartment
      </Heading>

      <Input
        type="text"
        name="flatnumber"
        value={shippingInfo.flatnumber}
        onChange={(e) => handleChange(e, setShippingInfo)}
      />

      <Heading>Area, Street, Sector, Village</Heading>

      <Input
        type="text"
        name="area"
        value={shippingInfo.area}
        onChange={(e) => handleChange(e, setShippingInfo)}
      />

      <Heading>Landmark</Heading>

      <Input
        type="text"
        placeholder="E.g. near apollo hospital"
        name="landmark"
        value={shippingInfo.landmark}
        onChange={(e) => handleChange(e, setShippingInfo)}
      />

      <Row>
        <div>
          <Row>
            <Heading>Pin Code</Heading>
            {shippingInfo.pincode.length > 0 && (
              <CheckCircleIcon
                style={{
                  color: "green",
                  fontSize: "4.3vw",
                  marginLeft: "5px",
                }}
              />
            )}
          </Row>
          <Input
            style={{ width: "80%" }}
            type="number"
            placeholder="6-digit Pincode"
            name="pincode"
            value={shippingInfo.pincode}
            onChange={(e) => handleChange(e, setShippingInfo)}
          />
        </div>

        <div style={{ marginLeft: "10px" }}>
          <Heading>Town/City</Heading>

          <Input
            style={{ width: "80%" }}
            type="text"
            name="city"
            value={shippingInfo.city}
            onChange={(e) => handleChange(e, setShippingInfo)}
          />
        </div>
      </Row>

      <Heading style={{ marginBottom: "15px" }}>State</Heading>

      <FormControl
        variant="outlined"
        fullWidth
        style={{
          backgroundColor: "#f3f5f7",
          boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.05)",
        }}
      >
        <InputLabel>{"Choose State"}</InputLabel>
        <Select
          label={"Choose State"}
          style={{ textAlign: "start" }}
          value={shippingInfo.state}
          onChange={(e) => handleChange(e, setShippingInfo)}
          name={"state"}
        >
          {states.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Button
        onClick={() => {
          window.scrollTo(0, 0);
          handleNextStep();
        }}
      >
        Confirm Address
      </Button>

      {/* <Input
        type="text"
        name="state"
        value={shippingInfo.state}
        onChange={(e) => handleChange(e, setShippingInfo)}
      /> */}
    </Container>
  );
}

export default PersonalInformation;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin:0px 15px;
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.78vw;
  font-weight: 500;
  margin: 12px 2px 8px 2px;

  @media (max-width: 768px) {
    font-size: 3.2vw;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const MobileDiv = styled.div`
  font-size: 0.78vw;
  font-weight: 400;
  cursor: pointer;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 14.3px;
  border: 1px solid #c8c8c8;
  background-color: #f3f5f7;

  @media (max-width: 768px) {
    font-size: 4.1vw;
  }
`;

const MobileInput = styled.input`
  padding: 14px;
  outline: none;
  width: 76%;
  margin-bottom: 10px;
  border: 1px solid #c8c8c8;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-left: none;
  font-size: 0.78vw;
  font-weight: 400;

  @media (max-width: 768px) {
    font-size: 3.9vw;
  }
`;

const Input = styled.input`
  padding: 14px;
  outline: none;
  width: 90%;
  margin-bottom: 10px;
  border: 1px solid #c8c8c8;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.06ch;
`;

const Button = styled.button`
  position: sticky;
  bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 93%;
  border-radius: 40px;
  border: 0px;
  background-color: #0c5de7;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  /* font-weight: 300; */
  font-size: 14.5px;
  letter-spacing: 0.25ch;
  padding: 18px 0px;
  color: white;
  margin: 15px auto;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 100%;
    margin: 35px auto;
  }
`;

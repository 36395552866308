import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useWishlist } from "./components/WishlistContext";
import { firestore as db } from "../firebase.config";
import { collection, getDocs, query, where } from "firebase/firestore";
import CloseIcon from "@mui/icons-material/Close";
import SkeletonLoader from "../Global/Loading_Skeleton/SkeletonLoaderCart";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useCart } from "../Cart/components/CartContext";
import ProductCardSmaller from "../utils/Product/ProductCards/ProductCardSmaller";

function Wishlist({ isWishlistOpen, toggleWishlist, isMobile, products }) {
  const { wishlistState, dispatch } = useWishlist();
  const { state: cartState, dispatch: dispatchCart } = useCart();
  const [loadProducts, setLoadProducts] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProductDetails = async () => {
      const productIds = wishlistState?.items.map((item) => item.productId);
      if (productIds?.length > 0) {
        const q = query(
          collection(db, "products"),
          where("id", "in", productIds)
        );
        const querySnapshot = await getDocs(q);
        const products = {};
        querySnapshot.forEach((doc) => {
          products[doc.id] = doc.data();
        });
        setLoadProducts(products);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        setLoading(false);
      }
    };

    fetchProductDetails();
  }, [wishlistState?.items]);

  const addToCart = (product) => {
    dispatchCart({
      type: "ADD_ITEM",
      payload: { productId: product.id && product.id },
    });
  };

  const removeFromWishlist = (productId) => {
    dispatch({ type: "REMOVE_ITEM", payload: { productId } });
  };

  return (
    wishlistState && (
      <CartContainer isOpen={isWishlistOpen} isMobile={isMobile}>
        <TopBar isMobile={isMobile}>
          {/* <ArrowBackIcon style={{ fontSize: "6.2vw", marginRight: "10px" }} /> */}
          Prime Provision's Wishlist ❤️
          <CloseIcon
            onClick={() => {
              if (isMobile) {
                navigate(-1);
              } else {
                toggleWishlist();
              }
            }}
            style={{ cursor: "pointer" }}
          />
        </TopBar>

        <ProductContainer>
          <MidBar isMobile={isMobile}>
            {wishlistState?.items.length} (Items) ▼
          </MidBar>
          {loading ? (
            Array.from({ length: wishlistState?.items.length }).map(
              (_, index) => <SkeletonLoader key={index} howmany={3} />
            )
          ) : wishlistState.items.length > 0 ? (
            wishlistState.items.map((item) => {
              const product = loadProducts[item.productId];
              // check if the item is already added to Cart or not
              const isItemInCart = cartState.items.some(
                (cartItem) => cartItem.productId === item.productId
              );
              return (
                <ProductCard
                  key={item.productId}
                  onClick={() => {
                    navigate(`/products/${product.id}/`, {
                      state: { product: product },
                    });
                  }}
                >
                  <ProductImage
                    alt="product_image"
                    src={
                      product
                        ? product.imageUrls.ShowCaseURL
                        : "https://via.placeholder.com/150"
                    }
                  />
                  <Column
                    style={{
                      marginLeft: "25px",
                      width: "60%",
                      marginTop: "10px",
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "start",
                        fontSize: isMobile ? "4.7vw" : "1.05vw",
                        letterSpacing: "0.05ch",
                        fontWeight: "500",
                        width: "90%",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {product ? product.name : "Loading..."}
                    </Text>
                    <Text
                      style={{
                        textAlign: "start",
                        marginTop: "3px",
                        fontSize: isMobile ? "2.6vw" : "0.7vw",
                        color: "#555555",
                      }}
                    >
                      {product && product.category} |{" "}
                      {product && product.tags[0]}
                    </Text>
                    <Row
                      style={{
                        margin: 0,
                        marginTop: "12px",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: isMobile ? "4.5vw" : "1.2vw",
                          marginTop: "1px",
                          alignSelf: "flex-start",
                          color: "green",
                        }}
                      >
                        ₹
                      </Text>
                      <Text
                        style={{
                          fontSize: isMobile ? "5.2vw" : "1.3vw",
                          marginLeft: isMobile ? "2px" : "3px",
                          fontWeight: "600",
                          color: "green",
                        }}
                      >
                        {product ? product.prices.SellingPrice : "Loading..."}
                      </Text>

                      <Text
                        style={{
                          fontSize: isMobile ? "3.7vw" : "1vw",
                          marginLeft: "4px",
                          marginTop: "3px",
                          textDecoration: "line-through",
                          color: "grey",
                          fontWeight: "600",
                        }}
                      >
                        {product
                          ? ` ${product.prices["ComparedShowCase Price"]} `
                          : "Loading..."}
                      </Text>
                    </Row>

                    <ViewButton
                      style={{
                        backgroundColor: isItemInCart ? "#037562" : "#045df6",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!isItemInCart) addToCart(product);
                      }}
                    >
                      {isItemInCart ? "In Cart" : "Add To Cart"}
                    </ViewButton>

                    <DeleteForeverIcon
                      style={{ position: "absolute", bottom: 10, right: 10 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        removeFromWishlist(item.productId);
                      }}
                    />
                  </Column>
                </ProductCard>
              );
            })
          ) : (
            <div>
              <img
                style={{ width: "35%", marginTop: "30px" }}
                src="https://static.vecteezy.com/system/resources/previews/023/256/113/original/heart-character-emoji-is-crying-png.png"
                // src="https://static.thenounproject.com/png/334152-200.png"
                alt=""
              />
              <Text style={{ marginTop: "20px", fontSize: "14px" }}>
                There's nothing in your Wishlist
              </Text>
            </div>
          )}
          {products && products.length > 0 && (
            <>
              <MidBar
                isMobile={isMobile}
                style={{
                  marginTop: "50px",
                  display: "flex",
                  justifyContent: "center",
                  padding:"10px 0"
                }}
              >
                <div
                  style={{
                    alignSelf: "center",
                    height: "1px",
                    flex: 0.15,
                    marginRight: "5px",
                    background: "#c5c1c1",
                  }}
                />
                Our Most Selling Gadgets
                <div
                  style={{
                    alignSelf: "center",
                    height: "1px",
                    flex: 1,
                    marginLeft: "5px",
                    background: "#c5c1c1",
                  }}
                />
              </MidBar>

              <ProductRow>
                {products
                  .filter((product) => product.tags.includes("Best Sellers"))
                  .map((product, index) => (
                    <ProductCardSmaller
                      key={product.id}
                      product={product}
                      isFirst={index === 0}
                    />
                  ))}
              </ProductRow>
            </>
          )}
        </ProductContainer>
      </CartContainer>
    )
  );
}

export default Wishlist;

const CartContainer = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  right: ${(props) => (props.isOpen ? "0" : props.isMobile ? "-105%" : "-28%")};
  width: ${(props) => (props.isMobile ? "100%" : "25%")};
  min-height: 100vh;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transition: right 0.5s ease-in;
  z-index: 1003;
  overflow: hidden;
`;

const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  background-color: #d3e3fe;
  font-weight: 500;
  color: black;
  font-size: ${(props) => (props.isMobile ? "3.6vw" : "0.85vw")};
  /* margin-bottom: 15px; */
  text-align: center;
  letter-spacing: 0.06ch;
`;

const MidBar = styled.div`
  padding: 10px 18px;
  /* background-color: #393535; */
  color: black;
  font-size: ${(props) => (props.isMobile ? "3.3vw" : "0.75vw")};
  /* margin-bottom: 5px; */
  text-align: start;
  letter-spacing: 0.08ch;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin: 0px 25px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  font-size: 1vw;

  @media (max-width: 768px) {
    font-size: 3.2vw;
  }
`;

const Text = styled.p`
  color: black;
  font-weight: 400;
  margin: 0;
  font-size: 15px;

  @media (max-width: 768px) {
    font-size: 4.2vw;
  }
`;

const ProductContainer = styled.div`
  overflow-y: auto;
  background-color: #fdfcfc;
  &::-webkit-scrollbar {
    display: none; /* Hide the scrollbar for WebKit browsers */
  }

  & {
    -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
    scrollbar-width: none; /* Hide scrollbar for Firefox */
  }
`;

const ProductRow = styled.div`
  width: 100%;
  margin: 0px auto;
  display: flex;
  align-items: start;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 5px 0px;
  background-color: white;
  margin-top: 5px;
  margin-bottom: 50px;

  &::-webkit-scrollbar {
    display: none;
  }

  & {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

const ProductCard = styled.div`
  position: relative;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.04);
  /* border-bottom: 1px solid #efe9e9; */
  padding: 10px 10px;
  border-radius: 3px;
  background-color: white;
  display: flex;
  margin: 0px;
  align-items: flex-start;
  margin-bottom: 15px;
`;

const ProductImage = styled.img`
  width: 36%;
  height: auto;
  object-fit: contain;
  aspect-ratio: 1;
`;

const ViewButton = styled.button`
  width: 68%;
  border: none;
  border-radius: 50px;
  font-size: 0.9vw;
  margin: 15px 0px 8px 0px;
  padding: 12px 8px;
  /* background-color: #045df6; */
  background-color: #045df6;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #037562;
  }

  @media (max-width: 768px) {
    font-size: 3.4vw;
    width: 63%;
    padding: 10px 5px;
    margin: 14px 0px 8px 0px;
    cursor: auto;
  }
`;

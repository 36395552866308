import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

function NotFound({ isMobile }) {
  const navigate = useNavigate();
  return (
    <div style={{ minHeight: "100vh" }}>
      <img
        style={{ width: isMobile ? "100%" : "30%", marginTop: "70px" }}
        src="https://cdni.iconscout.com/illustration/premium/thumb/404-error-7621856-6167010.png?f=webp"
        alt=""
      />
      <Heading>The page you are looking for does not exist.</Heading>
      <ExploreMoreText onClick={()=>navigate("/")}>
        {"<- "}Explore More
      </ExploreMoreText>
    </div>
  );
}

export default NotFound;

const Heading = styled.p`
  margin: 40px 0px 10px 0px;
  font-size: 1vw;
  font-weight: 500;
  letter-spacing: -0.02em;
  color: #000;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 3.3vw;
  }
`;

const ExploreMoreText = styled.button`
  margin: 10px 0px 10px 0px;
  font-size: 1vw;
  font-weight: 500;
  letter-spacing: -0.02em;
  padding: 5px 10px;
  border: 1px solid #a9a9a9;
  color: #000;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 3.1vw;
  }
`;

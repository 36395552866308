import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import ProductCard from "../../utils/Product/ProductCards/ProductCardVertical";

function Collections({ isMobile, products }) {
  const { category, subCategory } = useParams();

  const filteredProducts = products.filter((product) => {
    if (
      category === "Home" ||
      category === "Kitchen" ||
      category === "Bathrom" ||
      category === "Technology"
    ) {
      return product["category"] === category;
    } else {
      return product["tags"].includes(category);
    }
  });

  // filteredProducts.length > 0 ? (
  return (
    <Container>
      {/* <Banner src="https://cdn.create.vista.com/downloads/eb6f79c8-688c-4844-bad1-f0f0b628caad_640.jpeg" /> */}
      {/* <RouteText>
        Home / {category} Products / {subCategory}
      </RouteText> */}

      <div>
        <Heading
          style={{
            marginBottom: 0,
            fontSize: isMobile ? "2.9vw" : "0.8vw",
            fontWeight: "500",
            color: "#3c3c3c",
          }}
        >
          {" "}
          The Prime Provision's
        </Heading>
        <Heading style={{ marginTop: isMobile ? "4px" : 0 }}>
          {" "}
          {category} Gadgets
        </Heading>
      </div>

      <Banner src="https://images.squarespace-cdn.com/content/v1/5c0c16cf12b13fa421e4f5f7/7a5c0cb8-2685-445f-a9de-1a1d29b6c8a5/te-line-6.png" />
      {/* <Banner src="https://www.freepnglogos.com/uploads/divider-png/divider-orthella-bardon-page-eternal-city-10.png"/> */}

      <ProductContainer>
        {filteredProducts.length > 0
          ? filteredProducts.map((product, index) => (
              <ProductCard
                key={index}
                product={product}
                isMobile={isMobile}
                isCategory={true}
                isCollection={true}
                isFirst={true}
              />
            ))
          : products.map((product, index) => (
              <ProductCard
                key={index}
                product={product}
                isMobile={isMobile}
                isCategory={true}
                isCollection={true}
                isFirst={true}
              />
            ))}
      </ProductContainer>
    </Container>
  );
  // ) : (
  //   <Container>
  //     <img
  //       style={{ width: isMobile ? "100%" : "30%" }}
  //       src="https://cdni.iconscout.com/illustration/premium/thumb/404-error-7621856-6167010.png?f=webp"
  //       alt=""
  //     />
  //     <Heading
  //       style={{
  //         fontSize: isMobile ? "3.5vw" : "1vw",
  //       }}
  //     >
  //       No products found.
  //     </Heading>
  //   </Container>
}

export default Collections;

const Container = styled.div`
  min-height: 100vh;
  color: black;
  padding-top: 40px;

  @media (max-width: 768px) {
    padding-top: 40px;
  }
`;

const Banner = styled.img`
  width: 85%;
  height: 35px;
  opacity: 0.1;
  margin-bottom: 40px;
  /* margin-top: 10px; */
  object-fit: fill;

  @media (max-width: 768px) {
    width: 100%;
    height: 23px;
    margin-bottom: 10px;
  }
`;

// const RouteText = styled.p`
//   margin-top: 10px;
//   margin-left: 30px;
//   font-size: 0.75vw;
//   line-height: 1.5;
//   letter-spacing: -0.02em;
//   text-decoration: underline;
//   color: #444;
//   text-align: start;
//   @media (max-width: 768px) {
//     margin-left: 10px;
//     font-size: 2.7vw;
//   }
// `;

const Heading = styled.p`
  margin: 40px 0px 10px 0px;
  font-size: 1.9vw;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #000;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 6.3vw;
    margin: 20px 0px 15px 0px;
  }
`;

const ProductContainer = styled.div`
  width: 85%;
  margin: 0px auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 20px;
  /* gap: 0px 10px; */

  @media (max-width: 768px) {
    width: 98%;
    grid-template-columns: repeat(2, 1fr);
  }
`;

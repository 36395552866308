import React from "react";

const ContactUs = () => {
  return (
    <div
      style={{
        padding: "50px 20px 100px 20px",
        maxWidth: "800px",
        margin: "auto",
        textAlign: "start",
      }}
    >
      <h1>Contact Us</h1>
      <p>
        We are here to help! Whether you have questions about your order, our
        products, or our policies, feel free to reach out to us. Our customer
        service team is dedicated to providing you with the best possible
        support.
      </p>

      <h2>1. Contact Information</h2>
      <p>You can contact us via the following methods:</p>
      <ul>
        <li>
          <strong>Email:</strong>{" "}
          <a href="mailto:theprimeprovisions@gmail.com">
            theprimeprovisions@gmail.com
          </a>
        </li>
        <li>
          <strong>Phone:</strong> +919992048539
        </li>
      </ul>

      <h2>2. Business Hours</h2>
      <p>
        Our customer service team is available during the following hours:
        <ul>
          <li>Monday to Friday: 9:00 AM - 10:00 PM</li>
          <li>Saturday: 10:00 AM - 11:00 PM</li>
        </ul>
      </p>

      <h2>3. Response Times</h2>
      <p>
        We strive to respond to all inquiries within 24 hours during business
        days. If you contact us outside of our regular business hours, we will
        get back to you as soon as possible on the next business day.
      </p>

      <h2>4. Feedback and Suggestions</h2>
      <p>
        We value your feedback and suggestions. If you have any ideas on how we
        can improve our services or products, please do not hesitate to share
        them with us.
      </p>
    </div>
  );
};

export default ContactUs;

import React from "react";
import styled from "styled-components";
import TypingEffect from "./sub-components/TypingEffect";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useNavigate } from "react-router-dom";
function Search({
  isFromComponent = false,
  searchInput,
  setSearchInput,
  inputRef,
}) {
  const navigate = useNavigate();
  const typingEffectTexts = [
    "Search For Products",
    "Mini Android Projector",
    "Kitchen Products",
    "Portable Washing Machine",
    "Mini Printer",
    "Home Products",
    "Unique Home Gadgets",
  ];
  const placeholder = TypingEffect(typingEffectTexts);

  return (
    <Row
      onClick={() => {
        if (!isFromComponent) {
          navigate("/search");
        }
      }}
    >
      <SearchContainer>
        <SearchInput
          ref={inputRef}
          placeholder={placeholder}
          value={searchInput}
          onChange={(e) => {
            setSearchInput(e.target.value);
          }}
        />
        <SearchOutlinedIcon />
      </SearchContainer>
      {/* <SearchButton>Search</SearchButton> */}
    </Row>
  );
}

export default Search;

const Row = styled.div`
  display: flex;
  justify-content: center;
  background-color: white;
  padding: 15px 30px 2px 30px;

  @media (max-width: 768px) {
    padding: 10px 10px 2px 10px;
  }
`;

const SearchContainer = styled.div`
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border: 1px solid #c8c7c7;
  border-radius: 20px;
  padding: 12px 20px;

  @media (max-width: 768px) {
    width: 100%;
    border-radius: 50px;
    padding: 8px 12px;
  }
`;

const SearchInput = styled.input`
  flex: 1;
  outline-color: transparent;
  border: none;
  font-size: .9vw;
  transition: font-size 0.3s ease;

  &:focus {
    font-size: 1vw;
  }

  @media (max-width: 768px) {
    font-size: 3.3vw;
    &:focus {
      font-size: 3.7vw;
    }
  }
`;

const SearchButton = styled.button`
  border: none;
  border-radius: 5px;
  font-size: 3.2vw;
  margin-left: 10px;
  padding: 12px 20px;
  background-color: #045df6;
  color: white;
  cursor: pointer;
  &:hover {
    background-color: #037562;
  }
`;

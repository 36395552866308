import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";
import { Navigate, useNavigate } from "react-router-dom";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIosNew";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
// import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
// import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FullDescription from "./FullDescription";
import ProductTable from "./Table";
import { useCart } from "../../../Cart/components/CartContext";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ShareIcon from "@mui/icons-material/Share";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useWishlist } from "../../../wishlist/components/WishlistContext";

// Install Swiper modules
SwiperCore.use([Navigation, Pagination, Autoplay]);

const SwiperContainer = styled(Swiper)`
  .swiper-slide img {
    width: 98%;
    object-fit: contain;
    height: auto;
    border-radius: 20px;
  }

  .swiper-pagination {
    bottom: 10px;
  }
`;

const CustomNextButton = styled(ArrowForwardIosIcon)`
  position: absolute;
  top: 45%;
  right: 30px;
  background-color: rgb(255, 255, 255, 0.5);
  transform: translateY(-50%);
  color: #005eff;
  text-align: center;
  cursor: pointer;
  padding: 10px;
  transition: background-color 1s ease-in-out;

  &:hover {
    background-color: rgb(255, 255, 255, 0.8);
  }

  @media (max-width: 768px) {
    top: 50%;
    right: 20px;
    padding: 4px;
  }
`;

const CustomPrevButton = styled(ArrowBackIosIcon)`
  background-color: rgb(255, 255, 255, 0.5);
  position: absolute;
  top: 45%;
  left: 30px;
  transform: translateY(-50%);
  color: #005eff;
  text-align: center;
  cursor: pointer;
  padding: 10px;

  transition: background-color 1s ease-in-out;

  &:hover {
    background-color: rgb(255, 255, 255, 0.8);
  }

  @media (max-width: 768px) {
    top: 50%;
    left: 20px;
    padding: 4px;
  }
`;

const SwiperSlider = ({ imageUrls }) => {
  const images = Object.keys(imageUrls)
    .filter((key) => key.startsWith("ProductImageURL"))
    .sort((a, b) => {
      const numA = parseInt(a.split(" ")[1], 10);
      const numB = parseInt(b.split(" ")[1], 10);
      return numA - numB;
    })
    .map((key) => ({ url: imageUrls[key] }));

  if (imageUrls.OrganicImageURL) {
    images.push({ url: imageUrls.OrganicImageURL });
  }

  return (
    <SwiperContainer
      spaceBetween={10}
      navigation={{
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      }}
      pagination={{ clickable: true }}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      loop={true}
      style={{ zIndex: 1000, backgroundColor: "white" }}
    >
      {images.map((image, index) => (
        <SwiperSlide key={index}>
          <img src={image.url} alt={`Slide ${index}`} />
        </SwiperSlide>
      ))}
      <CustomNextButton
        className="swiper-button-next"
        style={{ fontSize: "18px" }}
      />
      <CustomPrevButton
        className="swiper-button-prev"
        style={{ fontSize: "18px" }}
      />
    </SwiperContainer>
  );
};

function AboutandImages({ product, name, category, cover1, isMobile }) {
  const navigate = useNavigate();

  const [quantity, setQuantity] = useState(1);

  const { state: cartState, dispatch } = useCart();
  const { wishlistState, dispatch: wishlistDispatch } = useWishlist();

  const addToCart = () => {
    dispatch({
      type: "ADD_ITEM",
      payload: { productId: product.id && product.id },
    });
  };

  // check Item is already in cart
  const isItemInCart = cartState.items.some(
    (cartItem) => cartItem.productId === product.id
  );

  // check Item is already in wishlist

  const isItemInWishlist = wishlistState.items.some(
    (item) => item.productId === product.id
  );

  const toggleWishlist = () => {
    if (isItemInWishlist) {
      wishlistDispatch({
        type: "REMOVE_ITEM",
        payload: { productId: product.id },
      });
    } else {
      wishlistDispatch({
        type: "ADD_ITEM",
        payload: { productId: product.id },
      });
    }
  };

  // const addToCart = () => {
  //   setTimeout(() => {}, 2000);
  // };

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 >= 0.5;
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

    return (
      <>
        {Array(fullStars).fill(<FaStar />)}
        {halfStar && <FaStarHalfAlt />}
        {Array(emptyStars).fill(<FaRegStar />)}
      </>
    );
  };

  // const images = [
  //   {
  //     original: cover1,
  //     thumbnail: cover1,
  //   },
  //   {
  //     original: "https://7daysorganic.com/wp-content/uploads/2021/11/3-35.jpg",
  //     thumbnail:
  //       "https://7daysorganic.com/wp-content/uploads/2021/11/3-35-150x150.jpg",
  //   },
  //   {
  //     original: "https://7daysorganic.com/wp-content/uploads/2021/11/7-15.jpg",
  //     thumbnail:
  //       "https://7daysorganic.com/wp-content/uploads/2021/11/7-15-150x150.jpg",
  //   },
  //   {
  //     original: "https://7daysorganic.com/wp-content/uploads/2021/11/7-15.jpg",
  //     thumbnail:
  //       "https://7daysorganic.com/wp-content/uploads/2021/11/7-15-150x150.jpg",
  //   },
  // ];

  return (
    <Container>
      <MainDiv
        style={{
          flexDirection: isMobile ? "column" : "row",
          marginTop: !isMobile && "30px",
        }}
      >
        <ImageDiv className="change-zIndex">
          {/* <CustomImageGallery items={images} /> */}
          <SwiperSlider imageUrls={product.imageUrls} isMobile={isMobile} />
          {/* <CubeSlider imageUrls={product.imageUrls}/> */}
        </ImageDiv>
        <AboutDiv>
          {/* <CategoryText>{category}</CategoryText> */}
          <AboutDivInner className="change-zIndex">
            <CategoryText style={{ margin: 0 }}>
              {product.category} Products / {product.tags[0]}
            </CategoryText>
            <NameText>{product.name}</NameText>

            <RatingsDiv
              style={{
                margin: "0px 0px",
              }}
            >
              <Stars>
                {renderStars(product.rating)} {product.rating}
              </Stars>

              <div
                style={{
                  width: "1px",
                  alignSelf: "stretch",
                  backgroundColor: "#000",
                  margin: "0px 13px",
                }}
              />

              <RatingsDiv
                style={{
                  // flexDirection: isMobile && "column",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    marginRight: "4px",
                    fontWeight: "600",
                    fontSize: isMobile ? "4vw" : "0.9vw",
                  }}
                >
                  {product.totalUnitsSold} +
                </span>
                {` Sold`}
              </RatingsDiv>
              <div
                style={{
                  width: "1px",
                  alignSelf: "stretch",
                  backgroundColor: "black",
                  margin: "0px 13px",
                }}
              />

              <RatingsDiv
                style={{
                  cursor: "pointer",
                  borderBottom: "1px solid black",
                }}
              >
                <span
                  style={{
                    marginRight: "5px",
                    fontWeight: "600",
                    fontSize: isMobile ? "4vw" : "0.9vw",
                  }}
                >
                  {`${Math.floor(
                    (product.totalUnitsSold / product.reviews.length) * 0.6
                  )}`}
                </span>
                {` Comments`}
              </RatingsDiv>

              {/* <RatingsDiv style={{ color: "#827f7f", cursor: "pointer" }}>
            {`(${Math.floor(
              (product.totalUnitsSold / product.reviews.length) * 0.6
            )} Comments & Reviews)`}
          </RatingsDiv> */}
            </RatingsDiv>
            <PriceText>
              <PriceText
                style={{
                  fontSize: isMobile ? "4.7vw" : "1.1vw",
                  color: "grey",
                  marginTop: "0px",
                  fontWeight: "400",
                  marginRight: "10px",
                  textDecoration: "line-through",
                }}
              >
                ₹{product.prices["ComparedShowCase Price"]}
              </PriceText>
              ₹{product.prices.SellingPrice}
              <LinkBelowText style={{ marginLeft: "10px" }}>
                {Math.floor(
                  ((product.prices["ComparedShowCase Price"] -
                    product.prices.SellingPrice) /
                    product.prices["ComparedShowCase Price"]) *
                    100
                )}
                % OFF
              </LinkBelowText>
            </PriceText>
            {product.isInStock && (
              <RowDiv
                style={{
                  marginTop: isMobile ? "10px" : "20px",
                  flexDirection: isMobile && "column",
                  // width: isMobile ? "100%" : "auto",
                }}
              >
                <RatingsDiv style={{ fontSize: isMobile && "3.6vw" }}>
                  Quantity :
                </RatingsDiv>
                <RowDiv
                  style={{
                    marginLeft: !isMobile && "15px",
                    marginTop: isMobile && "15px",
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
                  }}
                >
                  <RowDiv>
                    <QuantityChangeDiv
                      onClick={() => {
                        if (quantity > 1) {
                          setQuantity(quantity - 1);
                        }
                      }}
                    >
                      <RemoveIcon />
                    </QuantityChangeDiv>
                    <QuantityValueDiv
                      value={quantity}
                      onChange={(e) => {
                        setQuantity(e.currentTarget.value);
                      }}
                    />
                    <QuantityChangeDiv
                      onClick={() => {
                        setQuantity(quantity + 1);
                      }}
                    >
                      <AddIcon />
                    </QuantityChangeDiv>
                  </RowDiv>
                </RowDiv>
              </RowDiv>
            )}

            {product.isInStock && (
              <RowDiv
                style={{
                  width: isMobile ? "100%" : "70%",
                  flexDirection: "column",
                }}
              >
                <BuyNowButton
                  isMobile={isMobile}
                  onClick={() => {
                    if (product.isInStock) {
                      if (isItemInCart) {
                        navigate("/cart");
                      } else {
                        addToCart();
                      }
                    }
                  }}
                  style={{
                    backgroundColor: product.isInStock
                      ? isItemInCart
                        ? "#037562"
                        : "#045df6"
                      : "#737373",
                  }}
                >
                  {isItemInCart ? "Added To Cart (VIEW)" : "ADD TO CART"}
                </BuyNowButton>

                <LinkBelowText
                  style={{
                    // backgroundColor: "#8d13f0",
                    backgroundColor: "transparent",
                    width: !isMobile && "85%",
                    padding: "1px 0px",
                    fontSize: !isMobile ? "0.65vw" : "2.6vw",
                    marginTop: "8px",
                    fontWeight: "400",
                  }}
                >
                  You'll SAVE{" "}
                  <span
                    style={{
                      fontSize: !isMobile ? "0.8vw" : "3.2vw",
                      fontWeight: "600",
                    }}
                  >
                    ₹
                    {product.prices["ComparedShowCase Price"] -
                      product.prices.SellingPrice}
                    .00
                  </span>{" "}
                  on this Order (LIMITED OFFER)
                </LinkBelowText>
              </RowDiv>
            )}
            <RowDiv
              style={{
                width: isMobile ? "100%" : "65%",
                justifyContent: "space-evenly",
                marginTop: "10px",
              }}
            >
              <ContinueColumn style={{ marginLeft: 0, alignItems: "center" }}>
                <WhatsAppIcon style={{ color: "green" }} />
                <ContinueText>WhatsApp Us</ContinueText>
              </ContinueColumn>
              <div
                style={{
                  width: "1px",
                  alignSelf: "stretch",
                  backgroundColor: "#bababa",
                  margin: "10px 0px",
                }}
              />
              <ContinueColumn
                style={{ marginLeft: "10px", alignItems: "center" }}
                onClick={() => {
                  toggleWishlist();
                }}
              >
                {isItemInWishlist ? (
                  <FavoriteIcon style={{ color: "red" }} />
                ) : (
                  <FavoriteBorderIcon style={{ color: "red" }} />
                )}

                <ContinueText>Wishlist</ContinueText>
              </ContinueColumn>
              <div
                style={{
                  width: "1px",
                  alignSelf: "stretch",
                  backgroundColor: "#bababa",
                  margin: "10px 0px",
                }}
              />
              <ContinueColumn
                style={{ marginLeft: "10px", alignItems: "center" }}
              >
                {" "}
                <ShareIcon style={{ color: "blue" }} />
                <ContinueText>Share</ContinueText>
              </ContinueColumn>
            </RowDiv>
            <Seprator style={{ margin: "15px 0px" }} />
          </AboutDivInner>

          <AboutDivInner style={{ zIndex: "auto" }}>
            <RatingsDiv
              style={{ fontSize: isMobile && "3.4vw", marginTop: "10px" }}
            >
              GUARANTEED SAFE CHECKOUT
            </RatingsDiv>
            <RowDiv>
              <img
                style={{
                  marginTop: "15px",
                  width: isMobile ? "100%" : "85%",
                  height: "auto",
                }}
                src="https://miro.medium.com/v2/resize:fit:1400/0*BIy_CblCTVoOl5Zg"
              />
            </RowDiv>

            {/* <RatingsDiv style={{ fontSize: "15px", marginTop: "30px" }}>
          Compare Price With :
        </RatingsDiv>

        <RowDiv
          style={{
            justifyContent: "space-between",
            width: isMobile ? "100%" : "80%",
            alignItems: "flex-start",
          }}
        >
          <div style={{ flex: 1 }}>
            <ComparePriceOnAmazonButton>Amazon</ComparePriceOnAmazonButton>
            <ProductDescription style={{ margin: "10px auto" }}>
              (₹599)
            </ProductDescription>
          </div>
          <div style={{ flex: 1, marginLeft: "20px" }}>
            <ComparePriceOnAmazonButton style={{ backgroundColor: "#e19f10" }}>
              FlipKart
            </ComparePriceOnAmazonButton>
            <ProductDescription style={{ margin: "10px auto" }}>
              (₹499)
            </ProductDescription>
          </div>

          <div style={{ flex: 1, marginLeft: "20px" }}>
            <ComparePriceOnAmazonButton style={{ backgroundColor: "#000" }}>
              Meesho
            </ComparePriceOnAmazonButton>
            <ProductDescription style={{ margin: "10px auto" }}>
              (Not Available)
            </ProductDescription>
          </div>
        </RowDiv> */}

            <Seprator style={{ margin: "20px 0px" }} />

            <RowDiv
              style={{
                alignItems: "center",
                justifyContent: "flex-start",
                flexWrap: "wrap",
                gap: "5px 0px",
              }}
            >
              <NameText
                style={{
                  fontSize: isMobile ? "3.6vw" : "0.8vw",
                  margin: 0,
                  marginRight: "10px",
                }}
              >
                Tags :
              </NameText>
              {product.tags.map((tag, index) => (
                <RatingsDiv
                  style={{
                    marginRight: "5px",
                    backgroundColor: "#757575",
                    color: "white",
                    padding: "5px",
                    fontSize: isMobile && "2.6vw",
                  }}
                  key={index}
                >
                  # {tag}
                </RatingsDiv>
              ))}
            </RowDiv>
            <Seprator style={{ margin: "20px 0px" }} />
            {/* <NameText style={{ fontSize: "15px" }}>Product Specification :</NameText> */}
            {isMobile && <ProductTable tableData={product.table} />}
            <NameText style={{ fontSize: "15px" }}>
              About the Product :
            </NameText>

            {/* <ProductDescription>
          {product.description["1textBlock"]}
          {product.description[`2imageBlock`] && (
            <>
              <br /> <br />
              <img
                style={{
                  width: isMobile ? "100%" : "90%",
                  height: "auto",
                  objectFit: "cover",
                }}
                src={product.description[`2imageBlock`]}
                alt=""
              />
            </>
          )}
          <br /> <br />
           Check it out on @the.prime.provisions
        </ProductDescription> */}
            <img
              style={{
                width: "100%",
                height: "auto",
                objectFit: "cover",
                margin: "10px 0px",
              }}
              src={product.imageUrls.OrganicImageURL}
              alt=""
            />

            <ProductDescription>
              Check it out on @the.prime.provisions
            </ProductDescription>
            <InstagramReelDiv />

            {/* <Seprator style={{ margin: "30px 0px" }} /> */}

            <FullDescription description={product.description} />

            {/* <RatingsDiv style={{ fontSize: "15px" }}>
          Detailed Information :
        </RatingsDiv>

        <InfoTable /> */}

            {/* <Seprator style={{ margin: "30px 0px" }} />

        <RatingsDiv style={{ marginTop: "20px", fontSize: "14px" }}>
          ♡ Add To Wishlist
        </RatingsDiv>
        <Seprator />

        <RatingsDiv style={{ marginTop: "10px", fontSize: "14px" }}>
          Category:{" "}
          <CategoryText style={{ margin: 0, marginLeft: "5px" }}>
            {category}
          </CategoryText>
        </RatingsDiv>
        <RatingsDiv style={{ marginTop: "10px", fontSize: "14px" }}>
          Share:{" "}
          <CategoryText style={{ margin: 0, marginLeft: "5px" }}>
            {category}
          </CategoryText>
        </RatingsDiv> */}

            {!isMobile && <ProductTable tableData={product.table} />}
            {/* {!isMobile && (
            <ContinueDiv>
              
              <ContinueColumn>
                <ContinueText
                  style={{
                    fontWeight: "500",
                    color: "grey",
                    fontSize: isMobile ? "3.4vw" : "13px",
                    textDecoration: "line-through",
                  }}
                >
                  MRP {product.prices["ComparedShowCase Price"]}
                </ContinueText>
                <ContinueText
                  style={{
                    fontWeight: "700",
                    fontSize: isMobile ? "5.2vw" : "19px",
                    marginTop: "5px",
                  }}
                >
                  ₹{product.prices.SellingPrice}
                </ContinueText>
                <ContinueText
                  style={{
                    fontWeight: "500",
                    fontSize: isMobile ? "3.2vw" : "12px",
                    color: "blue",
                    marginTop: "5px",
                  }}
                >
                  Limited Offer
                </ContinueText>
              </ContinueColumn>

              <ContinueButton
                onClick={() => {
                  window.scrollTo(0, 0);
                  addToCart();
                }}
              >
                BUY NOW
              </ContinueButton>
            </ContinueDiv>
          )} */}
          </AboutDivInner>
        </AboutDiv>

        {/* <BuyNowButton
        style={{
          position: "sticky",
          lineHeight: "20px",
          left: 0,
          bottom: 15,
          margin: "0px 5px",
          color: "#fff",
        }}
      >
        ADD TO CART (LIMITED STOCK)
        <br />
        20:59:13
      </BuyNowButton> */}
      </MainDiv>

      {isMobile && (
        <ContinueDiv className="change-zIndex">
          <ContinueColumn>
            <ContinueText
              style={{
                fontWeight: "500",
                color: "grey",
                fontSize: isMobile ? "3.2vw" : "13px",
                textDecoration: "line-through",
              }}
            >
              MRP {product.prices["ComparedShowCase Price"] * quantity}
            </ContinueText>
            <ContinueText
              style={{
                fontWeight: "700",
                fontSize: isMobile ? "5.5vw" : "19px",
                marginTop: "5px",
              }}
            >
              ₹{product.prices.SellingPrice * quantity}
            </ContinueText>
            <ContinueText
              style={{
                fontWeight: "500",
                fontSize: isMobile ? "3.2vw" : "12px",
                color: "blue",
                marginTop: "5px",
              }}
              onClick={addToCart}
            >
              View Details
              {/* {Math.floor(
                ((product.prices["ComparedShowCase Price"] -
                  product.prices.SellingPrice) /
                  product.prices["ComparedShowCase Price"]) *
                  100
              )}
              % OFF (Limited Offer) */}
            </ContinueText>
          </ContinueColumn>

          <ContinueColumn
            style={{
              width: isMobile && "45%",
              margin: isMobile && "0px 20px 0px 10px",
            }}
          >
            <ContinueButton
              style={{
                backgroundColor: product.isInStock ? "#fec200" : "#737373",
                color: product.isInStock ? "black" : "white",
              }}
              onClick={() => {
                if (product.isInStock) addToCart();
              }}
            >
              {product.isInStock ? "BUY NOW" : "Out Of Stock"}
            </ContinueButton>
            <ContinueText
              style={{
                fontWeight: "500",
                fontSize: isMobile ? "2.3vw" : "11px",
                color: "black",
                alignSelf: "center",
                marginTop: "5px",
              }}
              onClick={addToCart}
            >
              (Tax, Discounts & Coupons) in Cart.
              {/* {Math.floor(
                ((product.prices["ComparedShowCase Price"] -
                  product.prices.SellingPrice) /
                  product.prices["ComparedShowCase Price"]) *
                  100
              )}
              % OFF (Limited Offer) */}
            </ContinueText>
          </ContinueColumn>
        </ContinueDiv>
      )}
    </Container>
  );
}

// const CustomImageGallery = ({ items }) => {
//   const renderItem = ({ original, description }) => (
//     <div>
//       <CustomImage src={original} />
//       {description && <CustomDescription>{description}</CustomDescription>}
//     </div>
//   );

//   return (
//     <ImageGallery
//       items={items}
//       renderItem={renderItem}
//       showPlayButton={false}
//       showNav={false}
//     />
//   );
// };

export default AboutandImages;

const shakeAnimation = keyframes`
  0%,40%, 80%, 60%, 100% {
    transform: rotate(0deg);
  }
  10%, 30%, 70%{
    transform:  rotate(-0.6deg);
  }
  20%, 50%, 90% {
    transform: rotate(0.6deg);
  }
`;

const Container = styled.div`
  @media (max-width: 768px) {
    margin-top: 5px;
  }
`;

const MainDiv = styled.div`
  display: flex;
  background-color: white;
  justify-content: center;
`;

const RowDiv = styled.div`
  display: flex;
`;

const Stars = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.85vw;

  svg {
    color: #ffa41c;
    margin-right: 3px;
  }

  :last-child {
    margin-right: 10px;
  }

  @media (max-width: 768px) {
    font-size: 3.8vw;
  }
`;

const Seprator = styled.div`
  width: 100%;
  height: 0.5px;
  background-color: grey;
  margin-top: 10px;
`;

const ImageDiv = styled.div`
  flex: 0.4;
  overflow: hidden;
  z-index: 1000;

  @media (max-width: 767px) {
    flex: 0.5;
  }
`;

// const CustomImage = styled.img`
//   width: 90%;
//   cursor: pointer;

//   @media (max-width: 767px) {
//     width: 80%;
//   }
// `;

// const CustomDescription = styled.div`
//   font-size: 14px;
//   color: #333;
//   margin-top: 8px;
// `;

const AboutDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  flex: 0.4;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-color: white;
  padding: 30px 0px;
  @media (max-width: 767px) {
    margin: 0px 2px;
    margin-bottom: 20px;
    padding: 20px 0px;
  }
`;

const AboutDivInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  z-index: 1000;
  background-color: white;
  padding: 0px 30px;
  @media (max-width: 767px) {
    padding: 0px 20px;
  }
`;

const CategoryText = styled.p`
  margin: 0px;
  color: #717070;
  font-size: 0.75vw;
  font-weight: 400;

  @media (max-width: 767px) {
    font-size: 3vw;
  }
`;

const NameText = styled.h1`
  margin: 6px 0px 12px 0px;
  font-weight: 500;
  text-align: start;
  z-index: 1000;

  @media (max-width: 767px) {
    font-size: 5.8vw;
    margin: 6px 0px 10px 0px;
  }
`;

const RatingsDiv = styled.div`
  display: flex;
  font-size: 0.83vw;
  font-weight: 400;
  align-items: center;
  @media (max-width: 767px) {
    font-size: 3vw;
  }
`;

const PriceText = styled.h1`
  display: flex;
  margin: 0px;
  margin-top: 10px;
  font-weight: 600;
  text-align: start;
  align-items: center;
  font-size: 1.8vw;

  @media (max-width: 767px) {
    font-size: 7.2vw;
  }
`;

const LinkBelowText = styled.p`
  background-color: #ffd900;
  padding: 2px 10px;
  font-size: 14px;
  font-weight: 500;
`;

const QuantityChangeDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 12px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 2px;
  color: #969696;
  background-color: #f1f1f7;
  cursor: pointer;
  /* background-color: #f0f0f5; */
  /* transition: background-color 0.33s ease; */

  /* &:hover {
    background-color: black;
    color: white;
  } */

  @media (max-width: 768px) {
    padding: 8px 10px;
    font-size: 16px;
  }
`;

const QuantityValueDiv = styled.input`
  text-align: center;
  width: 30px;
  font-size: 1vw;
  font-weight: 700;
  border-radius: 2px;
  margin: 0px 5px;
  color: #464444;
  border: none;

  @media (max-width: 768px) {
    width: 35px;
    font-size: 4.5vw;
  }
`;

// const AddToCartButton = styled.button`
//   width: 60%;
//   border: none;
//   background-color: transparent;
//   border: 1px solid #9f9f9f;
//   color: black;
//   font-weight: 600;
//   padding: 15px 20px;
//   margin-top: 30px;
//   margin-left: 15px;
//   cursor: pointer;
//   animation-delay: 2s;
//   transition: background-color 0.9s ease;

//   @media (max-width: 767px) {
//     width: 100%;
//     padding: 15px 0px;
//     margin-left: 0px;
//     margin-top: 25px;
//   }
//   &:hover {
//     color: white;
//     background-color: #037562;
//   }
// `;

// const CustomBuyNowButton = styled(AwesomeButtonProgress)`
//   &&& {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     width: 100%;
//     border: none;
//     background-color: #0c5de7;
//     color: white;
//     font-weight: 600;
//     margin-top: 30px;
//     cursor: pointer;
//     width: 100%;
//     margin-top: 30px;
//     color: white;

//     @media (max-width: 767px) {
//       width: 100%;
//       margin-top: 25px;
//     }
//   }
// `;

const BuyNowButton = styled.button`
  width: 85%;
  border: none;
  background-color: #0c5de7;
  color: white;
  font-weight: 500;
  padding: 18px 0px;
  margin-top: 30px;
  border-radius: 40px;
  cursor: pointer;
  /* box-shadow: 0px 45px 12px rgba(0, 0, 0, 0.1); */
  animation: ${shakeAnimation} 1.5s ease-in infinite;
  /* animation: ${shakeAnimation} 0.5s ease-in-out infinite; */
  animation-delay: 2s;
  transition: background-color 0.9s ease;

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0px;
    margin-top: 25px;
    cursor: auto;
    font-size: 3.6vw;
    padding: 18px 0px;
    /* box-shadow: 0px 40px 12px rgba(0, 0, 0, 0.1); */
  }
  &:hover {
    background-color: #037562;
  }
`;

const ProductDescription = styled.p`
  font-weight: 400;
  font-size: 0.6vw;
  margin: 20px 0px 10px 0px;
  /* line-height: 23px; */
  text-align: start;

  @media (max-width: 767px) {
    font-size: 3vw;
  }
`;

const InstagramReelDiv = styled.div`
  height: 20vh;
  width: 100%;
  background-color: #c1c1c1;
`;

const ContinueDiv = styled.div`
  position: sticky;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid #d3d3d3;
  background-color: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  /* font-weight: 300; */
  font-size: 14.5px;
  letter-spacing: 0.25ch;
  color: white;
  margin: 15px auto;
  z-index: 1000;

  @media (max-width: 768px) {
    width: 100%;
    margin: 20px auto;
  }
`;

const bounce = keyframes`
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-5px);
  }
  25%, 75% {
    transform: translateX(5px);
  }
`;

const BouncingIcon = styled(NavigateNextIcon)`
  animation: ${bounce} 1.5s infinite;
  margin-left: 6px;
`;

const ContinueButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  /* background-color: #ff6633; */
  background-color: #fec200;
  color: black;
  padding: 15px 50px;
  font-size: 0.9vw;
  font-weight: 700;
  cursor: pointer;
  /* border-radius: 3px; */

  @media (max-width: 768px) {
    width: 100%;
    font-size: 4vw;
    padding: 15px 0px;
    cursor: auto;
  }
`;

const ContinueColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px 0px;
  margin-left: 20px;
`;

const ContinueText = styled.p`
  margin: 0px;
  color: black;
  letter-spacing: 0.09ch;
  margin-top: 3px;
  font-size: 0.8vw;

  @media (max-width: 768px) {
    font-size: 2.7vw;
  }
`;

import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Search from "../../header/components/Search";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import ProductCardSmaller from "../../utils/Product/ProductCards/ProductCardSmaller";
import { useNavigate } from "react-router-dom";

function SearchComponent({ isMobile, products }) {
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState(null);

  useEffect(() => {
    if (searchInput) {
      setFilteredProducts(
        products.filter((product) =>
          product.id.includes(searchInput.toLowerCase())
        )
      );
    } else {
      setFilteredProducts(null);
    }
  }, [searchInput]);

  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <Container>
      <Search
        inputRef={inputRef}
        isFromComponent={true}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
      />

      <InnerContainer>
        {filteredProducts && (
          <Row style={{ marginTop: "10px" }}>
            {filteredProducts.map((product, index) => (
              <ProductCardSmaller
                key={product.id}
                product={product}
                isFirst={index === 0}
              />
            ))}
          </Row>
        )}

        <Heading>
          <TrendingUpIcon style={{ marginRight: "5px", fontSize: "20px" }} />
          Trending Searches
        </Heading>

        <Row style={{ marginLeft: "15px" }}>
          <TrendingTags onClick={() => navigate("/collections/Home/all")}>
            Home Essentials
          </TrendingTags>
          <TrendingTags
            onClick={() =>
              navigate(
                "/products/bathroom-foldable-washing-machine-0eb4-071a-30d1-6fd9"
              )
            }
          >
            Foldable Washing Machine
          </TrendingTags>
          <TrendingTags onClick={() => navigate("/collections/Kitchen/all")}>
            Kitchen Hacks
          </TrendingTags>
          <TrendingTags onClick={() => navigate("/collections/Foldable/all")}>
            Foldable
          </TrendingTags>
          <TrendingTags
            onClick={() =>
              navigate(
                "/products/home-adhesive-hooks-for-wall-hanging-objects-without-drilling-transparent-6c24-dd52-3e6a-f897"
              )
            }
          >
            Adhresive Wall Hooks
          </TrendingTags>
          <TrendingTags onClick={() => navigate("/collections/Organizer/all")}>
            Organizing Hacks
          </TrendingTags>
          <TrendingTags onClick={() => navigate("/collections/Travel/all")}>
            Travel Essential Gadgets
          </TrendingTags>
          <TrendingTags onClick={() => navigate("/collections/Technology/all")}>
            Technology Finds
          </TrendingTags>
        </Row>

        <Heading>Check Out Our Most Selling Gadgets!</Heading>

        <ProductRow>
          {products
            .filter((product) => product.tags.includes("Best Sellers"))
            .map((product, index) => (
              <ProductCardSmaller
                key={product.id}
                product={product}
                isFirst={index === 0}
              />
            ))}
        </ProductRow>
      </InnerContainer>
    </Container>
  );
}

export default SearchComponent;

const Container = styled.div`
  min-height: 100vh;
  background-color: white;
  padding-top: 5px;
`;

const InnerContainer = styled.div`
  width: 60%;
  margin: 0px auto;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Heading = styled.p`
  display: flex;
  align-items: center;
  margin: 40px 0px 10px 0px;
  font-size: 0.85vw;
  font-weight: 500;
  letter-spacing: -0.02em;
  color: #000;
  text-align: start;
  @media (max-width: 768px) {
    font-size: 3.3vw;
    margin: 20px 0px 5px 15px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const ProductRow = styled.div`
  width: 100%;
  margin: 0px auto;
  display: flex;
  align-items: start;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 5px 0px;
  background-color: white;

  &::-webkit-scrollbar {
    display: none;
  }

  & {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

const TrendingTags = styled.p`
  margin-bottom: 5px;
  margin-left: 10px;
  font-size: 0.8vw;
  border-radius: 5px;
  padding: 4px 10px;
  font-weight: 400;
  letter-spacing: -0.01em;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  color: #000;
  cursor: pointer;
  user-select: none;
  @media (max-width: 768px) {
    font-size: 3vw;
    padding: 4px 6px;
    margin-left: 5px;
  }
`;

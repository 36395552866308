import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import JustBoughtNotification from "../JustBoughtNotification/JustBoughtNotification";
import { useCart } from "../../../Cart/components/CartContext";
import { renderStars } from "../../../routes/products/components/Reviews";
import { useMediaQuery } from "@mui/material";

function ProductCard({
  isFirst,
  isCategory,
  isCollection = false,
  product,
  imageSize,
  hideBoughtBy,
}) {
  const isMobile = useMediaQuery("(max-width:768px)");

  const navigate = useNavigate();

  const { dispatch } = useCart();

  const addToCart = () => {
    dispatch({
      type: "ADD_ITEM",
      payload: { productId: product.id && product.id },
    });
  };

  return (
    <MainDiv
      isCategory={isCategory}
      isCollection={isCollection}
      inside
      style={{
        marginTop: "0px",
        marginLeft: isFirst
          ? "0px"
          : isMobile
          ? "10px"
          : isCategory
          ? "30px"
          : "30px",
        // backgroundColor:"red",
        borderRadius: "20px",
        // padding: "5px 0px",
      }}
    >
      <MainDiv
        style={{
          width: "100%",
          border: "0.5px solid #eae8e8",
          borderRadius: "20px",
          overflow: "hidden",
          padding: isMobile ? "15px 0px" : "10px 0px 25px 0px",
        }}
      >
        <Row
          style={{
            flexDirection: "column",
            alignItems: "center",
          }}
          onClick={() => {
            navigate(`/products/${product.id}/`, {
              state: { product: product && product },
            });
          }}
        >
          <Image
            isCategory={isCategory}
            src={product.imageUrls.ShowCaseURL && product.imageUrls.ShowCaseURL}
            alt={product.name && product.name + " Image"}
          />
          <Column>
            <Name isCategory={isCategory}>{product.name && product.name}</Name>
            {!isCategory && <BestInText>Best In {product.category}</BestInText>}

            <Description style={{ marginTop: isCategory ? "5px" : "10px" }}>
              <Stars>{renderStars(product.rating ? product.rating : 4)}</Stars>{" "}
              {Math.floor(product.totalUnitsSold / product.reviews.length)}
            </Description>

            {/* {product.price && <Price>{product.price}</Price>} */}
          </Column>
        </Row>

        {isCategory && (
          <Price>
            ₹{product.prices.SellingPrice}
            <ShowCasePrice>
              {" "}
              ₹{product.prices["ComparedShowCase Price"]}{" "}
            </ShowCasePrice>
          </Price>
        )}

        {isCategory && product.tags.length > 1 && (
          <TagText>★ {product.tags[1]}</TagText>
        )}
        <ViewButton
          style={{ backgroundColor: product.isInStock ? "#045df6" : "#737373"}}
          onClick={(e) => {
            e.stopPropagation();
            if (product.isInStock) {
              addToCart();
            }
          }}
        >
          {product.isInStock ? "Add To Cart" : "Out Of Stock"}
        </ViewButton>
      </MainDiv>
      {hideBoughtBy ? null : <JustBoughtNotification addToCart={addToCart} />}
    </MainDiv>
  );
}

export default ProductCard;

const MainDiv = styled.div`
  position: relative;
  width: ${(props) => (props.isCategory ? "17vw" : "16vw")};
  display: flex;
  flex-direction: column;
  justify-content: start;
  flex-shrink: 0;
  align-items: center;
  background-color: white;
  /* padding: 10px; */
  padding-bottom: 2px;
  cursor: pointer;
  transition: all 0.5s ease;

  @media (max-width: 768px) {
    width: ${(props) =>
      props.isCollection ? "46vw" : props.isCategory ? "50vw" : "55vw"};
    align-items: start;
    padding: ${(props) => (props.isCollection ? "2px" : "5px")};
    cursor: auto;
  }
`;

const Row = styled.div`
  display: flex;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  align-self: start;
  justify-content: space-between;
`;

const Image = styled.img`
  width: ${(props) => (props.isCategory ? "90%" : "95%")};
  height: auto;
  object-fit: contain;
  aspect-ratio: 1;
  margin: 0 auto;
  src: url();
  /* margin-top: 3px; */

  @media (max-width: 768px) {
    width: 49vw;
  }
`;

const Name = styled.h1`
  width: ${(props) => (props.isCategory ? "15vw" : "14vw")};
  text-align: start;
  align-self: flex-start;
  font-size: 0.95vw;
  font-weight: 600;
  margin: 20px 10px 0px 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 768px) {
    width: ${(props) => (props.isCategory ? "46vw" : "49vw")};
    font-size: 4.3vw;
    margin: 20px 10px 0px 20px;
  }
`;

const Price = styled.h1`
  display: flex;
  align-items: center;
  align-self: flex-start;
  font-size: 1vw;
  font-weight: 600;
  margin: 8px 0px 5px 25px;

  @media (max-width: 768px) {
    font-size: 4.6vw;
    margin: 10px 10px 5px 20px;
  }
`;

const ShowCasePrice = styled.h1`
  font-size: 0.85vw;
  color: #858585;
  font-weight: 600;
  margin: 0;
  margin-left: 5px;
  text-decoration: line-through;
  letter-spacing: 0.07ch;

  @media (max-width: 768px) {
    font-size: 3.4vw;
  }
`;

const Description = styled.p`
  display: flex;
  align-self: flex-start;
  justify-content: center;
  text-align: start;
  max-width: 100%;
  font-size: 0.7vw;
  font-weight: 500;
  margin: 10px 10px 0px 30px;

  @media (max-width: 768px) {
    font-size: 3.1vw;
    margin: 8px 10px 0px 20px;
  }
`;

const BestInText = styled.p`
  background-color: #ffd900;
  text-align: center;
  padding: 2px 10px;
  font-size: 0.7vw;
  margin: 5px 10px 0px 28px;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 768px) {
    font-size: 2.8vw;
    font-weight: 500;
    margin: 5px 10px 0px 18px;
  }
`;

const TagText = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #8000ff;
  text-align: center;
  padding: 2px 15px 2px 10px;
  font-size: 0.7vw;
  font-weight: 500;
  color: white;
  border-bottom-right-radius: 40px;

  @media (max-width: 768px) {
    font-size: 2.8vw;
    font-weight: 500;
  }
`;

// const Price = styled.h1`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   font-size: 1vw;
//   font-weight: 600;
//   margin: 8px 0px 5px 30px;

//   @media (max-width: 768px) {
//     font-size: 4vw;
//     margin: 10px 10px 5px 20px;
//   }
// `;

const Stars = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.8vw;
  margin-right: 3px;

  svg {
    color: #ffa41c;
    margin-right: 3px;
  }

  @media (max-width: 768px) {
    font-size: 3.4vw;
  }
`;

const ViewButton = styled.button`
  width: 85%;
  border: none;
  border-radius: 50px;
  font-size: 0.9vw;
  margin: 15px auto 8px auto;
  padding: 12px 8px;
  /* background-color: #045df6; */
  background-color: #045df6;
  color: white;
  cursor: pointer;
  &:hover {
    background-color: #037562;
  }

  @media (max-width: 768px) {
    font-size: 3.6vw;
    width: 88%;
    cursor: auto;
    padding: 13px 6px;
    margin: 15px auto 8px auto;
  }
`;

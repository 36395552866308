import React from "react";
import styled, { keyframes } from "styled-components";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SendIcon from "@mui/icons-material/Send";
import { useNavigate } from "react-router-dom";

const ChatWindow = ({ isOpen, toggleChat, isMobile, toggleTawkTo }) => {
  const navigate = useNavigate();

  const openWhatsApp = (phoneNumber) => {
    const sanitizedPhoneNumber = phoneNumber.replace(/\D/g, "");

    const whatsappUrl = `https://wa.me/${sanitizedPhoneNumber}`;
    window.open(whatsappUrl, "_blank");
  };

  const openInstagram = (username) => {
    const instagramUrl = `https://ig.me/m/${username}`;
    // const instagramUrl = `https://www.instagram.com/${username}`;
    window.open(instagramUrl, "_blank");
  };

  // const openTawkToChat = () => {
  //   const url = `https://tawk.to/chat/66bcc9170cca4f8a7a75f733/1i58n31vc`;
  //   window.open(url, "_blank");
  // };

  return (
    <>
      {isOpen && (
        <ChatWindowContainer>
          <ChatHeader>
            <p
              style={{ fontSize: isMobile ? "4vw" : ".9vw", fontWeight: "600" }}
            >
              Chat with Us
            </p>
            <CloseOutlinedIcon
              style={{ fontSize: isMobile ? "25px" : "28px" }}
              onClick={toggleChat}
            />
          </ChatHeader>
          <ChatBody>
            <p
              style={{
                fontWeight: "500",
                fontSize: isMobile ? "3.8vw" : ".9vw",
                textAlign: "start",
              }}
            >
              Hey, how can we help you?
            </p>
            <OptionDiv
              style={{ backgroundColor: "#f4f5f7", fontWeight: "500" }}
              onClick={() => {
                toggleChat();
                navigate("/track-my-order");
              }}
            >
              Track My Order{" "}
              <ArrowForwardIcon
                style={{
                  fontSize: isMobile ? "6vw" : "1.2vw",
                  color: "black",
                }}
              />
            </OptionDiv>

            <p
              style={{
                fontWeight: "500",
                fontSize: isMobile ? "3.8vw" : ".9vw",
                marginTop: "30px",
                textAlign: "start",
              }}
            >
              Want to talk? Choose how..
            </p>
            <OptionDiv
              onClick={() => {
                toggleChat();
                toggleTawkTo();
                // navigate("/chat-support");
              }}
            >
              Chat with Support{" "}
              <SendIcon
                style={{
                  fontSize: isMobile ? "5vw" : "1.2vw",
                  color: "black",
                }}
              />
            </OptionDiv>
            <OptionDiv
              onClick={() => {
                toggleChat();
                openWhatsApp("9992048539");
              }}
            >
              Connect On WhatsApp{" "}
              <SocialIcon
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/2062095_application_chat_communication_logo_whatsapp_icon.svg/2048px-2062095_application_chat_communication_logo_whatsapp_icon.svg.png"
                alt="WhatsApp"
              />
            </OptionDiv>
            <OptionDiv
              onClick={() => {
                toggleChat();
                openInstagram("the.prime.provisions");
              }}
            >
              Connect On Instagram
              <SocialIcon
                src="https://e7.pngegg.com/pngimages/672/312/png-clipart-instagram-logo-icon-others-text-photography-thumbnail.png"
                alt="Instagram"
              />
            </OptionDiv>

            <OptionDiv
              onClick={() => {
                toggleChat();
                // openInstagram("the.prime.provisions");
              }}
            >
              Connect On Facebook
              <SocialIcon
                src="https://cdn.pixabay.com/photo/2021/06/15/12/51/facebook-6338507_1280.png"
                alt="Facebook"
              />
            </OptionDiv>
          </ChatBody>
        </ChatWindowContainer>
      )}
    </>
  );
};

export default ChatWindow;

const openAnimation = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const ChatWindowContainer = styled.div`
  position: fixed;
  bottom: 5px;
  right: 10px;
  width: 24%;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  animation: ${openAnimation} 0.5s ease forwards;
  z-index: 1003;
  padding-bottom: 50px;

  @media (max-width: 768px) {
    width: 100%;
    right: 0px;
  }
`;

const ChatHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  background-color: #007bff;
  color: #fff;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
`;

const SocialIcon = styled.img`
  width: 23px;
  height: 23px;
  border-radius: 50%;
`;

const ChatBody = styled.div`
  flex: 1;
  padding: 15px;
  overflow: hidden;
  overflow-y: auto;
`;

const OptionDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: 95%;
  border: 1px solid #c8c8c8;
  border-radius: 5px;
  font-size: 0.8vw;
  margin: 0 auto;
  margin-top: 20px;
  letter-spacing: 0.01ch;
  cursor: pointer;

  @media (max-width: 768px) {
    color: #6f6d6d;
    width: 95%;
    font-size: 3.4vw;
  }
`;

import React, { createContext, useReducer, useContext, useEffect } from "react";

const initialWishlistState = JSON.parse(localStorage.getItem("wishlist")) || {
  items: [],
};

const wishlistReducer = (state, action) => {
  switch (action.type) {
    case "ADD_ITEM":
      const existingItemIndex = state.items.findIndex(
        (item) => item.productId === action.payload.productId
      );
      if (existingItemIndex >= 0) {
        const updatedItems = state.items.map((item) =>
          item.productId === action.payload.productId
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
        return { ...state, items: updatedItems };
      } else {
        return {
          ...state,
          items: [...state.items, { ...action.payload, quantity: 1 }],
        };
      }
    case "REMOVE_ITEM":
      const remainingItems = state.items.filter(
        (item) => item.productId !== action.payload.productId
      );
      return { ...state, items: remainingItems };
    case "CLEAR_WISHLIST":
      return { items: [] };
    default:
      return state;
  }
};

const WishlistContext = createContext();

export const WishlistProvider = ({ children }) => {
  const [wishlistState, dispatch] = useReducer(
    wishlistReducer,
    initialWishlistState
  );

  useEffect(() => {
    localStorage.setItem("wishlist", JSON.stringify(wishlistState));
  }, [wishlistState]);

  return (
    <WishlistContext.Provider value={{ wishlistState, dispatch }}>
      {children}
    </WishlistContext.Provider>
  );
};

export const useWishlist = () => useContext(WishlistContext);

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { CartProvider } from "./Cart/components/CartContext";
import { ProductsProvider } from "./utils/ContextAPI/products/ProductsContext";
import { WishlistProvider } from "./wishlist/components/WishlistContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ProductsProvider>
      <CartProvider>
        <WishlistProvider>
          <App />
        </WishlistProvider>
      </CartProvider>
    </ProductsProvider>
  </React.StrictMode>
);

import React, { createContext, useReducer, useContext, useEffect } from "react";

const initialState = JSON.parse(localStorage.getItem("cart")) || { items: [] };

const cartReducer = (state, action) => {
  switch (action.type) {
    case "ADD_ITEM":
      const existingItemIndex = state.items.findIndex(
        (item) => item.productId === action.payload.productId
      );
      if (existingItemIndex >= 0) {
        const updatedItems = state.items.map((item) =>
          item.productId === action.payload.productId
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
        return { ...state, items: updatedItems };
      } else {
        return {
          ...state,
          items: [...state.items, { ...action.payload, quantity: 1 }],
        };
      }
    case "REMOVE_ITEM":
      const remainingItems = state.items.filter(
        (item) => item.productId !== action.payload.productId
      );
      return { ...state, items: remainingItems };
    case "INCREMENT_QUANTITY":
      return {
        ...state,
        items: state.items.map((item) =>
          item.productId === action.payload.productId
            ? { ...item, quantity: item.quantity + 1 }
            : item
        ),
      };
    case "DECREMENT_QUANTITY":
      return {
        ...state,
        items: state.items.map((item) =>
          item.productId === action.payload.productId
            ? { ...item, quantity: item.quantity - 1 }
            : item
        ),
      };
    case "CLEAR_CART":
      return { items: [] };
    default:
      return state;
  }
};

// const cartReducer = (state, action) => {
//   switch (action.type) {
//     case "ADD_ITEM":
//       const existingItemIndex = state.items.findIndex(
//         (item) => item.name === action.payload.name
//       );
//       if (existingItemIndex >= 0) {
//         const updatedItems = state.items.map((item) =>
//           item.name === action.payload.name
//             ? { ...item, quantity: item.quantity + 1 }
//             : item
//         );
//         return { ...state, items: updatedItems };
//       } else {
//         return {
//           ...state,
//           items: [...state.items, { ...action.payload, quantity: 1 }],
//         };
//       }
//     case "REMOVE_ITEM":
//       const remainingItems = state.items.filter(
//         (item) => item.name !== action.payload
//       );
//       return { ...state, items: remainingItems };
//     case "INCREMENT_QUANTITY":
//       return {
//         ...state,
//         items: state.items.map((item) =>
//           item.name === action.payload.name
//             ? { ...item, quantity: item.quantity + 1 }
//             : item
//         ),
//       };
//     case "DECREMENT_QUANTITY":
//       return {
//         ...state,
//         items: state.items.map((item) =>
//           item.name === action.payload.name
//             ? { ...item, quantity: item.quantity - 1 }
//             : item
//         ),
//       };
//     case "CLEAR_CART":
//       return { items: [] };
//     default:
//       return state;
//   }
// };

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, initialState);

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(state));
  }, [state]);

  return (
    <CartContext.Provider value={{ state, dispatch }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);

import { firestore } from "../../firebase.config";

export const storeToFirestore = async (isBatch, paths, data) => {
  try {
    if (isBatch) {
      const batch = firestore.batch();
      paths.forEach((path) => {
        const docRef = firestore.doc(path);
        batch.set(docRef, data);
      });
      await batch.commit();
      console.log("Batch write successful!");
    } else {
      const docRef = firestore.doc(paths);
      await docRef.set(data);
      console.log("Document write successful!");
    }
  } catch (error) {
    console.error("Error writing document(s):", error);
  }
};
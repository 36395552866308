import React from "react";

const PrivacyPolicy = () => {
  return (
    <div
      style={{
        padding: "50px 20px 100px 20px",
        maxWidth: "800px",
        margin: "auto",
        textAlign: "start",
      }}
    >
      {" "}
      <h1>Privacy Policy</h1>
      <p>Effective Date: 14/08/2024</p>
      <p>
        The Prime Provisions ("we," "us," or "our") is committed to protecting
        your privacy. This Privacy Policy explains how we collect, use,
        disclose, and safeguard your information when you visit our website{" "}
        <a href="https://theprimeprovisions.com">
          https://theprimeprovisions.com
        </a>{" "}
        (the "Site"). By using the Site, you agree to the collection and use of
        information in accordance with this policy.
      </p>
      <h2>1. Information We Collect</h2>
      <p>We collect several types of information, including:</p>
      <ul>
        <li>
          <strong>Personal Data:</strong> Identifiable information such as your
          name, billing and shipping address, email address, phone number, and
          payment details when you register on our site, place an order, or fill
          out a form.
        </li>
        <li>
          <strong>Order Information:</strong> Details about the products you
          purchase, your payment method, and shipping information.
        </li>
        <li>
          <strong>Cookies and Tracking Technologies:</strong> Information about
          your browsing behavior on our site, such as your IP address, browser
          type, and pages you visit.
        </li>
      </ul>
      <h2>2. How We Use Your Information</h2>
      <p>Your information is used to:</p>
      <ul>
        <li>Process your orders and manage your account.</li>
        <li>Improve our website, products, and customer service.</li>
        <li>
          Send you information related to your orders or other products and
          services.
        </li>
        <li>Analyze data to improve our marketing efforts.</li>
      </ul>
      <h2>3. Sharing Your Information</h2>
      <p>
        We do not sell, trade, or otherwise transfer your personally
        identifiable information to outside parties, except to:
      </p>
      <ul>
        <li>
          Third-party service providers who assist us in operating our website,
          conducting our business, or servicing you, as long as they agree to
          keep this information confidential.
        </li>
        <li>Law enforcement or regulatory agencies if required by law.</li>
      </ul>
      <h2>4. Security of Your Information</h2>
      <p>
        We implement a variety of security measures to maintain the safety of
        your personal information when you place an order or enter, submit, or
        access your personal information.
      </p>
      <h2>5. Your Rights</h2>
      <p>You have the right to:</p>
      <ul>
        <li>Access your personal data.</li>
        <li>Request the correction of inaccurate or incomplete data.</li>
        <li>
          Request the deletion of your data, subject to certain exceptions.
        </li>
      </ul>
      <h2>6. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page.
      </p>
      <h2>7. Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us
        at:
      </p>
      <ul>
        <li>
          <strong>Email:</strong>{" "}
          <a href="mailto:theprimeprovisions@gmail.com">
            theprimeprovisions@gmail.com
          </a>
        </li>
        <li>
          <strong>Phone:</strong> +919992048539
        </li>
      </ul>
    </div>
  );
};

export default PrivacyPolicy;

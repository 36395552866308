import React, { useState } from "react";
import styled from "styled-components";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
// import MarkUnreadChatAltOutlinedIcon from "@mui/icons-material/MarkUnreadChatAltOutlined";

const qaData = [
  {
    question: "What is your return policy?",
    answer:
      "We offer a 2-day return policy. You can return items within 2 days of purchase.",
  },
  {
    question: "Do you offer international shipping?",
    answer:
      "No, currently we ship only in India due to security reaons alothough we are working to be able to ship our products across many more countries so later on, we might be able to ship products worldwide!",
  },
  {
    question: "How can I track my order?",
    answer:
      "You can track your order through the tracking link provided in your confirmation email, or by going to https://www.the.prime.provisions.com/track-my-order and fill in your email and order id. There is also option in home page to navigate to the Order Tracker Page.",
  },
  {
    question: "What payment methods do you accept?",
    answer:
      "We accept all major credit cards, UPI, Wallets, and other local payment methods. Our Payments are powered in partership with PhonePe and Paytm",
  },
  {
    question: "How do I contact customer support?",
    answer:
      "You can reach us via email at theprimeprovisions@gmail.com, thought WhatsApp / Call at +919992048539, Our Instagram and Facebook at The Prime Provisions. All the details and links are mentioned in the Help Page.",
  },
  {
    question: "How long does it take to deliver a product",
    answer:
      "Delivery time depends on your location and availability of the product. Our shop is located in New Delhi, so if you are somewhat closer to it, you can expect delivery within 2 days. But it can sometimes take upto 5 days. So please be patient as we try our best to deliver the product to you as soon as possible. ",
  },
];

function ChatSupport() {
  const isMobile = useMediaQuery("(max-width:768px)");
  const navigate = useNavigate();
  const [chat, setChat] = useState([]);
  const [remainingQuestions, setRemainingQuestions] = useState(qaData);

  const handleQuestionClick = (index) => {
    const selectedQA = remainingQuestions[index];
    setChat([...chat, selectedQA]);
    setRemainingQuestions(remainingQuestions.filter((_, i) => i !== index));
  };

  return (
    <Container>
      <TopBar>
        <ArrowBackIosIcon
          style={{ cursor: "pointer", fontSize: isMobile ? "4.5vw" : "1.1vw" }}
          onClick={() => {
            navigate(-1);
          }}
        />
        Prime Provision Chat Support
        <div />
      </TopBar>
      {chat.length > 0 && (
        <>
          {chat.map((qa, index) => (
            <AnswerDiv key={index}>
              <Question
                style={{
                  alignSelf: "flex-end",
                  marginRight: isMobile ? "10px" : "20px",
                }}
              >
                <strong>Q:</strong> {qa.question}
              </Question>
              <Question
                style={{
                  textAlign: "start",
                  marginRight: isMobile ? "10px" : "20px",
                  borderEndEndRadius: "15px",
                  borderTopLeftRadius: "0px",
                  backgroundColor: "white",
                }}
              >
                {" "}
                <strong>A:</strong> {qa.answer}
              </Question>
            </AnswerDiv>
          ))}
        </>
      )}
      <QuestionsDiv>
        <Heading>
          {remainingQuestions.length === 6
            ? "Hi, How can we help you?"
            : remainingQuestions.length === 0
            ? "Still facing issues?"
            : "Need More Help?"}
        </Heading>

        {remainingQuestions.map((qa, index) => (
          <Question key={index} onClick={() => handleQuestionClick(index)}>
            {qa.question}
          </Question>
        ))}
        <CreateTicket
          style={{ marginTop: remainingQuestions.length === 0 && "-5px" }}
        >
          Custom Chat (Raise Ticket)
          {/* <MarkUnreadChatAltOutlinedIcon style={{marginLeft:"8px",fontSize: isMobile ? "4.3vw" : "1vw"}}/> */}
        </CreateTicket>
      </QuestionsDiv>
    </Container>
  );
}

export default ChatSupport;

const Container = styled.div`
  min-height: 100vh;
  width: 55%;
  margin: 0px auto;
  background-color: white;
  z-index: 1003;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    width: 100%;
    box-shadow: auto;
  }
`;

const TopBar = styled.div`
  padding: 10px 16px;
  background-color: #0c5de7;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  letter-spacing: 0.13ch;
  font-weight: 400;
  color: white;
  font-size: 0.8vw;
  @media (max-width: 768px) {
    font-size: 3.2vw;
  }
`;

const Heading = styled.div`
  text-align: center;
  font-weight: 500;
  color: black;
  font-size: 0.8vw;
  margin: 20px 0px 10px 0px;
  margin-right: 10px;
  @media (max-width: 768px) {
    font-size: 3.3vw;
  }
`;

const QuestionsDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-end;
  margin-right: 20px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-right: 10px;
  }
`;

const AnswerDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: 20px;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 10px;
  margin-top: 20px;

  @media (max-width: 768px) {
    margin-left: 10px;
  }
`;

const Question = styled.div`
  border: 1px solid #dfdddd;
  padding: 7px 15px;
  border-radius: 15px;
  border-end-end-radius: 0px;
  text-align: end;
  font-size: 0.8vw;
  background-color: #f9fafb;
  margin-bottom: 5px;
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 3.4vw;
  }
`;

const CreateTicket = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 0.75vw;
  padding: 9px 15px;
  background-color: #0c5de7;
  margin-top: 5px;
  margin-bottom: 100px;
  color: white;
  border-radius: 20px;
  border-end-end-radius: 0px;

  @media (max-width: 768px) {
    font-size: 3vw;
  }
`;

import React from "react";

const TermsAndConditions = () => {

  return (
    <div
      style={{
        padding: "50px 20px 100px 20px",
        maxWidth: "800px",
        margin: "auto",
        textAlign: "start",
      }}
    >
      <h1>Terms and Conditions</h1>
      <p>
        Welcome to The Prime Provisions! These terms and conditions outline the
        rules and regulations for the use of our website. For the purpose of
        these Terms and Conditions, The term "we", "us", "our" used anywhere on
        this page shall mean The Prime Provisions. "you", “your”, "user",
        “visitor” shall mean any natural or legal person who is visiting our
        website and/or agreed to purchase from us.
      </p>

      <h2>1. Introduction</h2>
      <p>
        By accessing this website, you agree to comply with these terms and
        conditions. If you do not agree with any part of these terms, you should
        not use our website.
      </p>

      <h2>2. Account Registration</h2>
      <p>
        To access certain features of the website, you may need to create an
        account. You are responsible for maintaining the confidentiality of your
        account details and for all activities that occur under your account.
      </p>

      <h2>3. Product Information</h2>
      <p>
        We strive to provide accurate product descriptions and pricing
        information. However, we do not warrant that product descriptions or
        other content on the site are accurate, complete, reliable, current, or
        error-free.
      </p>

      <h2>4. Orders and Payments</h2>
      <p>
        By placing an order, you agree to purchase a product under these terms.
        All orders are subject to availability and confirmation of the order
        price. Payment can be made through various methods offered on the site.
      </p>

      <h2>5. Shipping and Delivery</h2>
      <p>
        Shipping times and costs will vary depending on the destination and
        selected shipping method. We are not responsible for any delays caused
        by the shipping carrier.
      </p>

      <h2>6. Returns and Refunds</h2>
      <p>
        Please refer to our Cancellation and Refund Policy for details on
        returns and refunds.
      </p>

      <h2>7. Liability</h2>
      <p>
        The Prime Provisions will not be liable for any damages arising out of
        or in connection with the use of this website. This includes, without
        limitation, direct, indirect, incidental, and consequential damages.
      </p>

      <h2>8. Changes to These Terms</h2>
      <p>
        We may update these terms and conditions from time to time. You are
        encouraged to review this page periodically for any changes.
      </p>

      <h2>10. Contact Us</h2>
      <p>
        If you have any questions about these Terms and Conditions, please
        contact us at:
      </p>
      <ul>
        <li>
          <strong>Email:</strong>{" "}
          <a href="mailto:theprimeprovisions@gmail.com">
            theprimeprovisions@gmail.com
          </a>
        </li>
        <li>
          <strong>Phone:</strong> +919992048539
        </li>
      </ul>
    </div>
  );
};

export default TermsAndConditions;

import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

function Login() {
  const navigate = useNavigate();
  return (
    <Container>
      <Heading>Login</Heading>

      <SignInWithGoogle>
        <img
          src="https://user-images.githubusercontent.com/194400/70987158-4069c900-20b7-11ea-892e-8a2e1166b6b7.png"
          alt=""
          style={{ width: "28px", height: "28px", marginRight: "15px" }}
        />
        Sign In With Google
      </SignInWithGoogle>

      <p style={{ fontSize: "15px" }}>OR</p>
      <p style={{ fontSize: "14px" }}>Enter your e-mail and password</p>

      <TextInput placeholder="Email Address" />
      <TextInput style={{ marginTop: "20px" }} placeholder="Password" />

      <ContinueButton>Login</ContinueButton>

      <p
        style={{ fontSize: "14px", marginBottom: "0", cursor: "pointer" }}
        onClick={() => navigate("/account/forgot-password")}
      >
        Forgot Password? <span style={{ fontWeight: "bold" }}>Click Here</span>
      </p>
      <p
        style={{
          fontSize: "14px",
          marginBottom: "70px",
          marginTop: "10px",
          cursor: "pointer",
        }}
        onClick={() => navigate("/account/register")}
      >
        Don't have account?{" "}
        <span style={{ fontWeight: "bold" }}>Create One</span>
      </p>
    </Container>
  );
}

export default Login;

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const Heading = styled.h1`
  margin-top: 100px;
  letter-spacing: 0.03ch;
  /* border-bottom: 1px solid black; */

  @media (max-width: 768px) {
    font-size: 5.5vw;
    margin-top: 60px;
  }
`;

const SignInWithGoogle = styled.button`
  border: 1px solid #d8d7d7;
  width: 23vw;
  padding: 10px 0px;
  font-size: 17px;
  font-weight: 600;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px 10px 0px;
  cursor: pointer;

  &:hover {
    background-color: #f2f2f2;
    border-color: #f2f2f2;
    color: black;
  }

  img {
    width: 32px;
    height: 32px;
    margin-right: 15px;
  }

  p {
    font-size: 17px;
  }

  @media (max-width: 768px) {
    width: 80vw;
    font-size: 3.7vw;
  }
`;

const TextInput = styled.input`
  width: 23vw;
  height: auto;
  padding: 14px 0px;
  font-size: 14px;
  color: #1c1c1c;
  border: 1px solid #d8d7d7;
  padding-left: 10px;

  &:focus{
    border: 1px solid #0095f6;
    outline: none;
  }


  @media (max-width: 768px) {
    width: 80vw;
    font-size: 3.3vw;
  }
`;

const ContinueButton = styled.button`
  border: none;
  width: 23.5vw;
  padding: 16px 0px;
  font-size: 19px;
  background-color: black;
  color: white;
  margin: 20px 0px 10px 0px;
  cursor: pointer;

  &:hover {
    background-color: #507d3c;
  }

  @media (max-width: 768px) {
    width: 83vw;
    font-size: 3.6vw;
  }
`;

import React from "react";
import styled, { keyframes } from "styled-components";

const SkeletonLoaderProduct = ({ isMobile }) => {
  return (
    <SkeletonContainer>
      <SkeletonImage />
      <SkeletonText>
        <SkeletonLine width="70%" />
        <SkeletonLine width="10%" />
        <SkeletonLine width="40%" />
        <SkeletonLine
          width="60%"
          style={{ height: "50px", marginTop: "20px" }}
        />
        <SkeletonLine
          width="40%"
          style={{ height: "40px", marginTop: "20px" }}
        />

        <Row>
          <SkeletonLine
            width={isMobile ? "48%":"45%"}
            style={{ height: isMobile ? "20vh" : "31vh" }}
          />
          <SkeletonLine
            width={isMobile ? "48%":"45%"}
            style={{ height: isMobile ? "20vh" : "31vh", marginLeft: "10px" }}
          />
        </Row>
      </SkeletonText>
    </SkeletonContainer>
  );
};

export default SkeletonLoaderProduct;

const shimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

const SkeletonContainer = styled.div`
  width: 80%;
  align-self: center;
  display: flex;
  flex-direction: row;
  margin: 20px 0;
  padding: 10px;
  background: #fff;
  border: 0.5px solid #eae8e8;
  border-radius: 20px;

  @media (max-width: 768px) {
    width: 90%;
    flex-direction: column;
    margin: 10px 0;
  }
`;

const Row = styled.div`
  display: flex;
  margin-top: 40px;
  @media (max-width: 768px) {
    margin-top: 30px;
  }
`;

const SkeletonImage = styled.div`
  width: 35vw;
  height: 30vw;
  background: #e0e0e0;
  border-radius: 10px;
  animation: ${shimmer} 1.2s infinite linear;
  background: linear-gradient(to right, #e0e0e0 8%, #f0f0f0 18%, #e0e0e0 33%);
  background-size: 800px 104px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    width: 90vw;
    height: 80vw;
  }
`;

const SkeletonText = styled.div`
  flex: 1;
  margin-left: 10px;
`;

const SkeletonLine = styled.div`
  height: 30px;
  background: #e0e0e0;
  border-radius: 10px;
  margin-bottom: 10px;
  width: ${({ width }) => width || "100%"};
  animation: ${shimmer} 1.2s infinite linear;
  background: linear-gradient(to right, #e0e0e0 8%, #f0f0f0 18%, #e0e0e0 33%);
  background-size: 800px 104px;

  @media (max-width: 768px) {
    height: 20px;
  }
`;

import React from "react";
import styled, { keyframes } from "styled-components";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useMediaQuery } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const Summary = ({
  shippingInfo,
  paymentInfo,
  loadedProducts,
  cartItems,
  handleNextStep,
}) => {
  const isMobile = useMediaQuery("(max-width:768px)");

  const calculateTotalPrice = () => {
    let totalPrice = 0;
    cartItems.forEach((item) => {
      const product = loadedProducts[item.productId];
      totalPrice += product.prices.SellingPrice * item.quantity;
    });
    return totalPrice;
  };

  const calculateShowCasePrice = () => {
    let showCaseTotal = 0;
    cartItems.forEach((item) => {
      const product = loadedProducts[item.productId];
      showCaseTotal += product.prices["ComparedShowCase Price"] * item.quantity;
    });
    return showCaseTotal;
  };

  return (
    <div>
      <SummaryContainer>
        <Heading>
          <ArrowBackIosNewIcon
            style={{ fontSize: "4.8vw", marginRight: "10px" }}
          />
          Order Summary
        </Heading>

        <Section>
          <DetailRow>
            <SectionHeading>Deliver to :</SectionHeading>

            <ChangeAddressButton>Change</ChangeAddressButton>
          </DetailRow>

          <Label style={{ marginTop: "13px" }}>{shippingInfo.fullname}</Label>

          <Response>
            {shippingInfo.flatnumber}, {shippingInfo.area}, near{" "}
            {shippingInfo.landmark}, {shippingInfo.city}, {shippingInfo.state},{" "}
            {shippingInfo.pincode}.
          </Response>
          <Response style={{ marginTop: "10px" }}>
            {shippingInfo.mobileNumber}
          </Response>
          <Response style={{ marginTop: "10px" }}>
            {shippingInfo.email}
          </Response>

          {/* 
          <DetailRow>
            <Label>Flat Number:</Label>
            <Response>{shippingInfo.fullname}</Response>
          </DetailRow>
          <DetailRow>
            <Label>Area:</Label>
            <Response>{shippingInfo.area}</Response>
          </DetailRow>
          <DetailRow>
            <Label>Landmark:</Label>
            <Response>{shippingInfo.landmark}</Response>
          </DetailRow>
          <DetailRow>
            <Label>City:</Label>
            <Response>{shippingInfo.city}</Response>
          </DetailRow>
          <DetailRow>
            <Label>State:</Label>
            <Response>{shippingInfo.state}</Response>
          </DetailRow>
          <DetailRow>
            <Label>Pin Code:</Label>
            <Response>{shippingInfo.pincode}</Response>
          </DetailRow> */}
        </Section>

        {/* <Section>
        <SectionHeading>Payment Information</SectionHeading>
        <DetailRow>
          <Label>Card Number:</Label>
          <Response>{paymentInfo.cardNumber}</Response>
        </DetailRow>
        <DetailRow>
          <Label>Expiry Date:</Label>
          <Response>{paymentInfo.expiry}</Response>
        </DetailRow>
        <DetailRow>
          <Label>CVV:</Label>
          <Response>{paymentInfo.cvv}</Response>
        </DetailRow>
      </Section> */}
        {cartItems.map((item, index) => {
          const product = loadedProducts[item.productId];
          return (
            <>
              <Section>
                <Label style={{ marginBottom: "20px" }}>
                  Product Information :
                </Label>

                <div style={{ marginBottom: "10px" }}>
                  <DetailRow key={index} style={{ marginBottom: "15px" }}>
                    <Response>
                      {index + 1}. {product.name}
                    </Response>
                    <Label style={{ color: "#2c9b37", margin: 0 }}>
                      {/* {item.quantity} x ${item.price.toFixed(2)} */}
                      {item.quantity} x ₹{product.prices.SellingPrice}
                    </Label>
                  </DetailRow>
                  <ProductImage src={product.imageUrls.ShowCaseURL} />
                </div>
              </Section>
              <Section style={{ paddingBottom: "10px" }}>
                <Label>Price Details:</Label>
                <DetailRow style={{ marginTop: "15px" }}>
                  <Response>Subtotal :</Response>
                  <Response>
                    <span
                      style={{
                        fontSize: "13px",
                        marginRight: "4px",
                        textDecoration: "line-through",
                        color: "grey",
                        fontWeight: "600",
                      }}
                    >
                      ₹{product.prices["ComparedShowCase Price"]}{" "}
                    </span>
                    ₹{calculateTotalPrice()}.00
                  </Response>
                </DetailRow>
                <DetailRow style={{ marginTop: "10px" }}>
                  <Response>Shipping / Delivery :</Response>
                  <Response>
                    ₹0 (<span style={{ fontWeight: "500" }}>FREE Delivery</span>
                    )
                  </Response>
                </DetailRow>
                <DetailRow style={{ marginTop: "10px" }}>
                  <Response>Tax / GST :</Response>
                  <Response style={{ color: "#d77f14" }}>+ ₹35.00</Response>
                </DetailRow>
                <DetailRow style={{ marginTop: "10px" }}>
                  <Response>Discount :</Response>
                  <Response style={{ color: "green" }}>— ₹35.00</Response>
                </DetailRow>
                <DetailRow style={{ marginTop: "10px" }}>
                  <SectionHeading>Total Price:</SectionHeading>
                  <SectionHeading>₹{calculateTotalPrice()}.00</SectionHeading>
                </DetailRow>
              </Section>
              <Section style={{ paddingBottom: "12px" }}>
                <DetailRow>
                  <SectionHeading style={{ margin: 0 }}>
                    Expect Delivery by :
                  </SectionHeading>
                  <Response>5 Auguest, 2024</Response>
                </DetailRow>
              </Section>
            </>
          );
        })}
      </SummaryContainer>

      <ContinueDiv>
        <ContinueColumn>
          <ContinueText
            style={{
              fontWeight: "500",
              color: "grey",
              fontSize: isMobile ? "3.4vw" : "13px",
              textDecoration: "line-through",
            }}
          >
            MRP {calculateShowCasePrice()}
          </ContinueText>
          <ContinueText
            style={{
              fontWeight: "700",
              fontSize: isMobile ? "5.2vw" : "19px",
              marginTop: "5px",
            }}
          >
            ₹{calculateTotalPrice()}
          </ContinueText>
          <ContinueText
            style={{
              fontWeight: "500",
              fontSize: isMobile ? "3.2vw" : "12px",
              color: "blue",
              marginTop: "5px",
            }}
          >
            100% Security
          </ContinueText>
        </ContinueColumn>

        <ContinueButton
          onClick={() => {
            window.scrollTo(0, 0);
            handleNextStep();
          }}
        >
          Continue <BouncingIcon />
        </ContinueButton>
      </ContinueDiv>
    </div>
  );
};

export default Summary;

// Styled components
const SummaryContainer = styled.div`
  margin: 5px 10px;
  background-color: white;
`;

const Heading = styled.h2`
  font-size: 1.2vw;
  font-weight: 500;
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    font-size: 3.6vw;
  }
`;

const Section = styled.div`
  margin-bottom: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 15px 10px 20px 10px;
  border-radius: 5px;
`;

const SectionHeading = styled.h3`
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 10px;
  text-align: start;
  font-size: 1vw;
  letter-spacing: 0.02ch;

  @media (max-width: 768px) {
    font-size: 3.7vw;
  }
`;

const DetailRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;

const Label = styled.p`
  font-weight: 500;
  margin: 3px 0px 12px 0px;
  font-size: 1vw;
  text-align: start;
  letter-spacing: 0.02ch;
  @media (max-width: 768px) {
    font-size: 4.1vw;
  }
`;

const Response = styled.p`
  font-weight: 400;
  font-size: 1vw;
  margin: 0px;
  text-align: start;
  letter-spacing: 0.03ch;
  @media (max-width: 768px) {
    font-size: 3.7vw;
  }
`;

const ChangeAddressButton = styled.button`
  box-shadow: 0px 0px 10px rgba(49, 49, 49, 0.3);
  border: none;
  border-radius: 6px;
  padding: 5px 20px;
  color: #0c5de7;
  background-color: transparent;
  font-weight: 500;
  letter-spacing: 0.06ch;
`;

const ProductImage = styled.img`
  width: 100%;
  height: auto;
  margin: 5px auto 10px auto;
`;

const ContinueDiv = styled.div`
  position: sticky;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 93%;
  border-top: 1px solid #d3d3d3;
  background-color: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  /* font-weight: 300; */
  font-size: 14.5px;
  letter-spacing: 0.25ch;
  color: white;
  margin: 15px auto;

  @media (max-width: 768px) {
    width: 100%;
    margin: 35px auto;
  }
`;

const bounce = keyframes`
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-5px);
  }
  25%, 75% {
    transform: translateX(5px);
  }
`;

const BouncingIcon = styled(NavigateNextIcon)`
  animation: ${bounce} 1.5s infinite;
  margin-left: 6px;
`;

const ContinueButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  /* background-color: #ff6633; */
  background-color: #fec200;
  color: black;
  padding: 14px 30px;
  margin-right: 20px;
  font-size: 4.1vw;
  font-weight: 700;
`;

const ContinueColumn = styled.p`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;
`;

const ContinueText = styled.p`
  margin: 0px;
  color: black;
  letter-spacing: 0.08ch;
`;

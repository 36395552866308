import React, { useRef, useEffect, useState } from "react";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

const TawkToWidget = ({ tawkMessengerRef }) => {
  useEffect(() => {
    if (tawkMessengerRef.current && window && window.Tawk_API) {
      const Tawk_API = window.Tawk_API;

      // Apply custom styles
      Tawk_API.customStyle = {
        visibility: {
          desktop: {
            position: "br", // bottom-right
            xOffset: 50, // 50px away from right
            yOffset: 140, // 100px up from bottom
          },
          mobile: {
            position: "br", // bottom-right
            xOffset: 10,
            yOffset: 90, // 100px up from bottom
          },
          bubble: {
            rotate: "0deg",
            xOffset: -20,
            yOffset: 0,
          },
        },
        zIndex: 999,
      };
      Tawk_API.onChatMaximized = function () {
        const elements = document.getElementsByClassName("change-zIndex");
        for (let element of elements) {
          element.style.zIndex = "998";
        }
      };
      Tawk_API.onChatMinimized = function () {
        const elements = document.getElementsByClassName("change-zIndex");
        for (let element of elements) {
          element.style.zIndex = "1001";
        }
      };
    }
  }, []);

  return (
    <TawkMessengerReact
      propertyId="66bcc9170cca4f8a7a75f733"
      widgetId="1i58n31vc"
      ref={tawkMessengerRef}
    />
  );
};

export default TawkToWidget;

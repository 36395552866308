import React from "react";
import styled from "styled-components";

function SlidingBanners({ isMobile }) {
  return (
    <WaveBorderDiv
      isMobile={isMobile}
      style={{ marginTop: isMobile ? "10px" : "20px" }}
    >
      {/* <Image src={theprimeprovisionslogo} alt="" /> */}
      <Image
        isMobile={isMobile}
        src="https://5core.com/cdn/shop/collections/home_appliances_360X260_22d72e8f-494d-43e7-9fe7-c31527a82390.jpg?v=1680048368"
      />
      {/* <Image src="https://t4.ftcdn.net/jpg/04/14/22/55/360_F_414225555_k4FE9n2fjaBen0QlOW1kSjaQBWfjfCjF.jpg" />
      <Image src="https://t3.ftcdn.net/jpg/03/14/28/96/360_F_314289607_ADADbnGr64dpGnddyhZPidCoc6jgKiHK.jpg" />
      <Image src="https://t4.ftcdn.net/jpg/03/59/65/61/360_F_359656113_Li94Bswq5yQvUv3Nzta8RdUA4o4qBodN.jpg" />
      <Image src="https://blog.abenson.com/wp-content/uploads/2022/01/Home-Gadget-Upgrade-Blog-Banner-Image.jpg" /> */}
    </WaveBorderDiv>
  );
}

export default SlidingBanners;

const WaveBorderDiv = styled.div`
  position: relative;
  width: ${(props) => (props.isMobile ? "100vw" : "80vw")};
  height:${(props) => (props.isMobile ? "35vh" : "60vh")};
  background-color: #fff;
  /* padding-bottom: 50px; */

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1.5rem;
    width: 100%;
    background: hsla(0, 0%, 100%, 0.5);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 500 60' preserveAspectRatio='none'%3E%3Crect x='0' y='0' width='500' height='60' style='stroke: none; fill: %23f5f5f5;' /%3E%3Cpath d='M0,30 Q25,0 50,30 T100,30 T150,30 T200,30 T250,30 T300,30 T350,30 T400,30 T450,30 T500,30 L500,0 L0,0 Z' style='stroke: none; fill: %23fff; transform: scaleY(-1); transform-origin: center;'/%3E%3C/svg%3E");
    background-size: 100% 100%;
  }
`;

const Image = styled.div`
  width: 100%;
  height: 100%;
  margin: 0px auto;
  background-color: #f5f5f5;
  object-fit: cover;
`;

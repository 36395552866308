import React from 'react';
import styled, { keyframes } from 'styled-components';

const SkeletonLoader = ({ howmany }) => {
    const skeletons = Array.from({ length: howmany });
  
    return (
      <>
        {skeletons.map((_, index) => (
          <SkeletonContainer key={index}>
            <SkeletonImage />
            <SkeletonText>
              <SkeletonLine width="60%" />
              <SkeletonLine width="80%" />
              <SkeletonLine width="40%" />
            </SkeletonText>
          </SkeletonContainer>
        ))}
      </>
    );
  };

export default SkeletonLoader;

const shimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

const SkeletonContainer = styled.div`
width: 100%;
  display: flex;
  margin: 20px 0;
  padding: 20px;
  background: #fff;
  border-bottom: 1px solid #e7e2e2;
  border-radius: 10px;
`;

const SkeletonImage = styled.div`
  width: 100px;
  height: 100px;
  background: #e0e0e0;
  border-radius: 10px;
  animation: ${shimmer} 1.2s infinite linear;
  background: linear-gradient(to right, #e0e0e0 8%, #f0f0f0 18%, #e0e0e0 33%);
  background-size: 800px 104px;
`;

const SkeletonText = styled.div`
  flex: 1;
  margin-left: 20px;
`;

const SkeletonLine = styled.div`
  height: 20px;
  background: #e0e0e0;
  border-radius: 10px;
  margin-bottom: 10px;
  width: ${({ width }) => width || '100%'};
  animation: ${shimmer} 1.2s infinite linear;
  background: linear-gradient(to right, #e0e0e0 8%, #f0f0f0 18%, #e0e0e0 33%);
  background-size: 800px 104px;
`;

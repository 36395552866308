import { useEffect, useState } from "react";

const TypingEffect = (texts, typingSpeed = 100, backspaceSpeed = 30, delay = 1500) => {
  const [displayText, setDisplayText] = useState("");
  const [textIndex, setTextIndex] = useState(0);
  const [typing, setTyping] = useState(true);

  useEffect(() => {
    let timeout;

    if (typing) {
      if (displayText.length < texts[textIndex].length) {
        timeout = setTimeout(() => {
          setDisplayText(texts[textIndex].slice(0, displayText.length + 1));
        }, typingSpeed);
      } else {
        timeout = setTimeout(() => {
          setTyping(false);
        }, delay);
      }
    } else {
      if (displayText.length > 0) {
        timeout = setTimeout(() => {
          setDisplayText(displayText.slice(0, -1));
        }, backspaceSpeed);
      } else {
        setTyping(true);
        setTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
      }
    }

    return () => clearTimeout(timeout);
  }, [displayText, typing, texts, textIndex, typingSpeed, backspaceSpeed, delay]);

  return displayText;
};

export default TypingEffect;

import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import OpacityIcon from "@mui/icons-material/Opacity";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useMediaQuery } from "@mui/material";
import GppGoodIcon from "@mui/icons-material/GppGood";
import FAQ from "./components/FAQ";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Summary from "./components/Summary";
import PersonalInformation from "./components/PersonalInformation";
import Payment from "./components/Payment";
import { useLocation } from "react-router-dom";

const Checkout = () => {
  const location = useLocation();
  const { products, loadedProducts } = location.state || {};
  const isMobile = useMediaQuery("(max-width:768px)");
  const [currentStep, setCurrentStep] = useState(2);
  const [shippingInfo, setShippingInfo] = useState({
    fullname: "",
    mobileNumber: "",
    email: "",
    flatnumber: "",
    area: "",
    landmark: "",
    city: "",
    state: "",
    pincode: "",
  });
  const [paymentInfo, setPaymentInfo] = useState({
    cardNumber: "yoo",
    expiry: "yooo",
    cvv: "yoooyoyoo",
  });

  // const [cartItems, setCartItems] = useState([
  //   { name: "Product A", quantity: 1, price: 20.0 },
  //   { name: "Product B", quantity: 2, price: 15.0 },
  // ]);

  const handleNextStep = () => {
    if (currentStep < 4) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleChange = (e, setState) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <Container>
      <TopBar isMobile={isMobile}>
        <Row>
          <ArrowBackIcon style={{ fontSize: "6.2vw", marginRight: "10px" }} />{" "}
          Checkout
        </Row>
        <Row>
          <GppGoodIcon style={{ fontSize: "4vw", marginRight: "3px" }} />
          <CheckpointText
            style={{ color: "black", fontSize: isMobile ? "2.8vw" : "0.6vw" }}
          >
            Secured by Norton
          </CheckpointText>
        </Row>
      </TopBar>

      <MidBar isMobile={isMobile}>1348+ people bought this month</MidBar>
      <Row
        style={{
          width: "90%",
          margin: "10px auto 10px auto",
          alignItems: "flex-start",
        }}
      >
        <Column
          active={currentStep >= 1}
          style={{ flex: 1, alignItems: "center", scale: "0.9" }}
        >
          <CheckCircleIcon
            style={{
              color: "#48b653",
              fontSize: "5.5vw",
              width: "6.4vw",
              height: "6.4vw",
            }}
          />
          <CheckpointText>Cart</CheckpointText>
        </Column>
        <ProgressLine active />
        <Column
          active={currentStep >= 2}
          style={{
            flex: 1,
            alignItems: "center",
            scale: currentStep < 2 ? "0.9" : "1",
          }}
        >
          {currentStep > 2 ? (
            <CheckCircleIcon
              style={{
                color: "#48b653",
                fontSize: "5.5vw",
                width: "6.4vw",
                height: "6.4vw",
              }}
            />
          ) : (
            <CheckpointCount filled={currentStep == 2}>2</CheckpointCount>
          )}
          <CheckpointText>Address</CheckpointText>
        </Column>
        <ProgressLine active={currentStep === 3} />

        <Column
          active={currentStep >= 3}
          style={{
            flex: 1,
            alignItems: "center",
            opacity: currentStep < 3 ? "0.4" : "1",
            scale: currentStep < 3 ? "0.9" : "1",
          }}
        >
          {currentStep > 3 ? (
            <CheckCircleIcon
              style={{
                color: "#48b653",
                fontSize: "5.5vw",
                width: "6.4vw",
                height: "6.4vw",
              }}
            />
          ) : (
            <CheckpointCount filled={currentStep == 3}>3</CheckpointCount>
          )}
          <CheckpointText>Summary</CheckpointText>
        </Column>
        <ProgressLine active={currentStep === 3} />
        <Column
          active={currentStep === 4}
          style={{
            flex: 1,
            alignItems: "center",
            opacity: currentStep < 4 ? "0.4" : "1",
            scale: currentStep < 4 ? "0.9" : "1",
          }}
        >
          <CheckpointCount filled={currentStep == 4}>4</CheckpointCount>
          <CheckpointText>Payment</CheckpointText>
        </Column>
      </Row>

      <Seprator style={{ margin: "10px 0px", height: "7px" }} />

      <FormContainer>
        {currentStep === 2 && (
          <PersonalInformation
            handleNextStep={handleNextStep}
            shippingInfo={shippingInfo}
            setShippingInfo={setShippingInfo}
            handleChange={handleChange}
          />
        )}

        {currentStep === 3 && (
          <Summary
            shippingInfo={shippingInfo}
            paymentInfo={paymentInfo}
            cartItems={products}
            loadedProducts={loadedProducts}
            handleNextStep={handleNextStep}
          />
        )}

        {currentStep === 4 && (
          <Payment
            paymentInfo={paymentInfo}
            shippingInfo={shippingInfo}
            cartItems={products}
            setPaymentInfo={setPaymentInfo}
            handleChange={handleChange}
          />
        )}
      </FormContainer>

      {/* <Button
        onClick={() => {
          window.scrollTo(0, 0);
          handleNextStep();
        }}
        style={{ backgroundColor: currentStep < 3 ? "#0c5de7" : "#0c5de7" }}
      >
        {currentStep < 3 ? "Confirm Address" : "Complete Payment"}
      </Button> */}
      {/* {currentStep !== 4 && <BouncingIcon />} */}

      <Seprator />
      <FAQ />
    </Container>
  );
};

export default Checkout;

const Container = styled.div`
  min-height: 100vh;
  position: relative;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
  background-color: white;
  /* background-color: white; */
  color: black;
  font-size: ${(props) => (props.isMobile ? "4vw" : "0.85vw")};
  text-align: center;
  letter-spacing: 0.06ch;
`;

const MidBar = styled.div`
  padding: 10px 15px;
  /* background-color: #2a2828; */
  background-color: #0c5de7;
  color: white;
  font-size: ${(props) => (props.isMobile ? "2.7vw" : "0.75vw")};
  margin-bottom: 15px;
  text-align: center;
  letter-spacing: 0.08ch;
`;

const ProgressLine = styled.div`
  flex: 1;
  height: 2px;
  margin-top: 10px;
  background-color: ${({ active }) => (active ? "green" : "#ddd")};
`;

const CheckpointText = styled.span`
  color: black;
  font-weight: 400;
  margin: 0;
  margin-top: 4px;
  font-size: 1vw;

  @media (max-width: 768px) {
    font-size: 3.3vw;
  }
`;

const CheckpointCount = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.filled ? "white" : "black")};
  font-weight: 400;
  width: ${(props) => (props.filled ? "2vw" : "2vw")};
  height: ${(props) => (props.filled ? "2vw" : "2vw")};
  background-color: ${(props) => (props.filled ? "#48b653" : "#fff")};
  border-radius: 50%;
  border: ${(props) => (props.filled ? "none" : "1px solid black")};
  font-size: 1vw;

  @media (max-width: 768px) {
    width: ${(props) => (props.filled ? "6.2vw" : "6vw")};
    height: ${(props) => (props.filled ? "6.2vw" : "6vw")};
    font-size: 3.3vw;
  }
`;

const bounce = keyframes`
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-5px);
  }
  25%, 75% {
    transform: translateX(5px);
  }
`;

const BouncingIcon = styled(NavigateNextIcon)`
  animation: ${bounce} 1.5s infinite;
  margin-left: 5px;
`;

const Seprator = styled.div`
  width: 100%;
  height: 1px;
  background-color: #f1f2f4;
  margin: 50px 0px 40px 0px;
`;

const FormContainer = styled.div`
  margin: 20px 0px;
`;

const Button = styled.button`
  position: sticky;
  bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 93%;
  border-radius: 40px;
  border: 0px;
  background-color: #0c5de7;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  /* font-weight: 300; */
  font-size: 14.5px;
  letter-spacing: 0.25ch;
  padding: 18px 0px;
  color: white;
  margin: 15px auto;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 95%;
    margin: 35px auto;
  }
`;

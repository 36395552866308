import React from "react";
import styled from "styled-components";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { storeToFirestore } from "../../../utils/Database/StoreDataToDatabase";
import { get24CurrentFormattedDateTime } from "../../../utils/Math/Get24CurrentDataandTime";
import {
  generate16DigitUUID,
  generateOrderId,
} from "../../../utils/Math/generateUUID";

function Payment({
  cartItems,
  shippingInfo,
  paymentInfo,
  setPaymentInfo,
  handleChange,
}) {
  const calculateTotalPrice = () => {
    let totalPrice = 0;
    cartItems.forEach((item) => {
      totalPrice += (item.price ? item.price : 500) * item.quantity;
    });
    return totalPrice;
  };

  const CompleteOrder = () => {
    const currentDate = get24CurrentFormattedDateTime();
    const docId = generate16DigitUUID();
    const genorderId = generateOrderId();
    const orderData = {
      userInfo: {
        fullname: shippingInfo.fullname,
        mobileNumber: shippingInfo.mobileNumber,
        email: shippingInfo.email,
      },
      shippingInfo: {
        flatnumber: shippingInfo.flatnumber,
        area: shippingInfo.area,
        landmark: shippingInfo.landmark,
        city: shippingInfo.city,
        state: shippingInfo.state,
        pincode: shippingInfo.pincode,
      },
      paymentInfo: {
        cardNumber: paymentInfo.cardNumber,
        expiry: paymentInfo.expiry,
        cvv: paymentInfo.cvv,
      },
      id: docId,
      orderId: genorderId,
      orderDate: currentDate,
      totalPrice: calculateTotalPrice(),
      items: cartItems.map((item) => ({
        // productId: item.id,
        name: item.name,
        quantity: item.quantity,
        // price: item.price,
      })),
    };

    try {
      storeToFirestore(false, `orders/${docId}`, orderData);
    } catch (error) {
      console.log("Error storing order to Firestore: ", error);
    }
  };

  return (
    <Container>
      <PaymentContainer>
        <Heading>
          <ArrowBackIosNewIcon
            style={{ fontSize: "4.8vw", marginRight: "10px" }}
          />
          Complete Payment
        </Heading>

        <Input
          type="text"
          placeholder="Card Number"
          name="cardNumber"
          value={paymentInfo.cardNumber}
          onChange={(e) => handleChange(e, setPaymentInfo)}
        />
        <Input
          type="text"
          placeholder="Expiry Date (MM/YY)"
          name="expiry"
          value={paymentInfo.expiry}
          onChange={(e) => handleChange(e, setPaymentInfo)}
        />
        <Input
          type="text"
          placeholder="CVV"
          name="cvv"
          value={paymentInfo.cvv}
          onChange={(e) => handleChange(e, setPaymentInfo)}
        />

        <Button onClick={CompleteOrder}>Order Now</Button>
      </PaymentContainer>
    </Container>
  );
}

export default Payment;

const Container = styled.div`
  position: absolute;
  height: 93vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: rgb(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  /* margin: 0px 15px; */
`;

const PaymentContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0px;
  right: 0px;
  z-index: 1000;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  /* margin: 0px 15px; */
`;

const Heading = styled.h2`
  font-size: 1.2vw;
  font-weight: 700;
  margin-bottom: 25px;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    font-size: 3.5vw;
  }
`;

const Input = styled.input`
  padding: 10px;
  width: 80%;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 300;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 93%;
  border-radius: 40px;
  border: 0px;
  background-color: #0c5de7;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  /* font-weight: 300; */
  font-size: 14.5px;
  letter-spacing: 0.25ch;
  padding: 18px 0px;
  color: white;
  margin: 15px auto;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 100%;
    margin: 35px auto 10px auto;
  }
`;
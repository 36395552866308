import React, { useEffect, useRef } from "react";
import styled from "styled-components";
// import CoverProductHoverDiv from "./sub-component/CoverProductHoverDiv";
import { useNavigate } from "react-router-dom";

function Categories({ isMobile, setIsCategoryTextVisible }) {
  const navigate = useNavigate();
  const target = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsCategoryTextVisible(false);
          } else {
            setIsCategoryTextVisible(true);
          }
        });
      },
      {
        root: null,
        threshold: 0.5,
      }
    );

    if (target.current) {
      observer.observe(target.current);
    }

    return () => {
      if (target.current) {
        observer.unobserve(target.current);
      }
    };
  }, []);

  return (
    <Container isMobile={isMobile} ref={target}>
      <CoverDiv
        style={{ marginLeft: "10px" }}
        isMobile={isMobile}
        onClick={() => {
          navigate("/collections/Trending/all");
        }}
      >
        <CoverImage
          isMobile={isMobile}
          src="https://don16obqbay2c.cloudfront.net/wp-content/uploads/How-To-Find-Trending-Products-To-Sell-Online-in-2021@2x-8-1-1622616913.png"
          alt=""
        />
        <Text isMobile={isMobile}>Trending</Text>
      </CoverDiv>
      <CoverDiv
        isMobile={isMobile}
        onClick={() => {
          navigate("/collections/Best Sellers/all");
        }}
      >
        <CoverImage
          isMobile={isMobile}
          src="https://cdn-icons-png.flaticon.com/256/10939/10939134.png"
          // src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9vXNl6KYQOl4onZSVGzN_reDJDkCdjKA2dw&s"
          alt=""
        />
        <Text isMobile={isMobile}>Best Seller</Text>
      </CoverDiv>

      <CoverDiv
        isMobile={isMobile}
        onClick={() => {
          navigate("/collections/Home/all");
        }}
      >
        <CoverImage
          isMobile={isMobile}
          src="https://png.pngtree.com/png-clipart/20230915/original/pngtree-electronic-gadgets-cartoon-icon-set-vector-png-image_12168303.png"
          alt=""
        />
        <Text isMobile={isMobile}>Home</Text>
      </CoverDiv>

      <CoverDiv
        isMobile={isMobile}
        onClick={() => {
          navigate("/collections/Kitchen/all");
        }}
      >
        <CoverImage
          isMobile={isMobile}
          src="https://www.jiomart.com/images/product/original/rvuylio58p/new-era-kitchen-tools-for-home-kitchen-items-900-ml-color-black-product-images-orvuylio58p-p602626148-0-202306241812.jpg?im=Resize=(420,420)"
          alt=""
        />
        <Text isMobile={isMobile}>Kitchen</Text>
      </CoverDiv>

      <CoverDiv
        isMobile={isMobile}
        onClick={() => {
          navigate("/collections/Bathroom/all");
        }}
      >
        <CoverImage
          isMobile={isMobile}
          src="https://thegreytechnologies.in/cdn/shop/products/portable-washing-machine-3_2000x.jpg?v=1688381901"
          alt=""
        />
        <Text isMobile={isMobile}>Bathroom</Text>
      </CoverDiv>

      <CoverDiv
        isMobile={isMobile}
        onClick={() => {
          navigate("/collections/Instagram/all");
        }}
      >
        <CoverImage
          isMobile={isMobile}
          src="https://img.freepik.com/free-psd/3d-circle-with-instagram-symbol-isolated-transparent-background_125540-3727.jpg"
          // src="https://images-cdn.ubuy.co.in/657531b82270b43c0a75e7b1-phomemo-m110-label-makers-barcode.jpg"
          alt=""
        />
        <Text isMobile={isMobile}>Instagram</Text>
      </CoverDiv>

      <CoverDiv
        isMobile={isMobile}
        onClick={() => {
          navigate("/collections/Technology/all");
        }}
      >
        <CoverImage
          isMobile={isMobile}
          src="https://img.joomcdn.net/94fe28469c5e82bd6e48fe1fdcb0df82e1b63892_original.jpeg"
          alt=""
        />
        <Text isMobile={isMobile}>TechGadgets</Text>
      </CoverDiv>

      <CoverDiv
        isMobile={isMobile}
        onClick={() => {
          navigate("/collections/Security/all");
        }}
      >
        <CoverImage
          isMobile={isMobile}
          src="https://rukminim2.flixcart.com/image/850/1000/kwcfngw0/home-security-camera/k/i/i/1-ccduyr76-indoor-outdoor-security-camera-jronj-original-imag9fmze86d5tfx.jpeg?q=90&crop=false"
          alt=""
        />
        <Text isMobile={isMobile}>Security</Text>
      </CoverDiv>

      <CoverDiv
        isMobile={isMobile}
        onClick={() => {
          navigate("/collections/HomeDecor/all");
        }}
      >
        <CoverImage
          isMobile={isMobile}
          src="https://m.media-amazon.com/images/I/71l+JbGKK9L._SL1329_.jpg"
          alt=""
        />
        <Text isMobile={isMobile}>HomeDecor</Text>
      </CoverDiv>
    </Container>
  );
}

export default Categories;

const Container = styled.div`
  width: ${(props) => (props.isMobile ? "100%" : "100%")};
  overflow: auto;
  display: flex;
  background-color: white;
  margin-top: ${(props) => (props.isMobile ? "0px" : "4px")};
  align-items: flex-start;
  justify-content: ${(props) => (props.isMobile ? "auto" : "center")};
  gap: ${(props) => (props.isMobile ? "0px" : "20px")};

  &::-webkit-scrollbar {
    display: none;
  }

  & {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

const Text = styled.p`
  margin: 3px 0px 5px 0px;
  font-weight: 500;
  font-size: ${(props) => (props.isMobile ? "2.9vw" : " 0.7vw")};
  color: #000000;
  cursor: pointer;
`;

const CoverDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  position: relative;
  margin-right: ${(props) => (props.isMobile ? "17px" : "10px")};

  &:hover > div {
    display: block;
  }
`;

const CoverImage = styled.img`
  width: ${(props) => (props.isMobile ? "15vw" : "3.5vw")};
  height: ${(props) => (props.isMobile ? "15vw" : "3.5vw")};
  border-radius: 50%;
  border: none;
  object-fit: cover;
  padding: 2px;
  transition: all 0.5s ease;

  &:hover {
    transform: ${(props) => (props.isMobile ? "scale(0.85)" : "scale(1.1)")};
    border: ${(props) => (props.isMobile ? "none" : "1px solid #8ec949")};
  }

  @media (max-width: 768px) {
    border: 1px solid #e1f4ff;
    padding: 0px;
  }
`;

const CoverProductsDiv = styled.div`
  position: absolute;
  top: 100%;
  left: 0px;
  width: 50vw;
  transform: ${(props) =>
    props.translateLeft
      ? `translate(-${props.translateLeft}%)`
      : "translate(-10%)"};
  background-color: white;
  color: black;
  text-align: center;
  padding: 5px 20px;
  z-index: 9999;
  display: flex;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  display: none;
  visibility: hidden;
  transition: visibility 1s ease;

  /* Show the CoverProductsDiv on hover */
  ${CoverDiv}:hover & {
    display: flex;
    visibility: visible;
  }
`;

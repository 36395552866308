import React from "react";
import styled from "styled-components";
import ProductCard from "../../../../../utils/Product/ProductCards/ProductCardVertical";
import { Parallax } from "react-scroll-parallax";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";

SwiperCore.use([Navigation, Pagination, Autoplay]);

const SwiperContainer = styled(Swiper)`
  .swiper-pagination {
    bottom: 10px;
  }
  margin: 0 35px;

  @media (max-width: 768px) {
    margin: 0;
  }
`;

const CustomNavButtonForward = styled(ArrowForwardIosOutlinedIcon)`
  position: relative;
  padding: 10px 12px;
  background-color: white;
  color: black;
  box-shadow: 0px 0px 10px rgb(0, 0, 0, 0.3);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 0.8vw;
  z-index: 10;
  margin-left: 5px;
`;

const CustomNavButtonBack = styled(ArrowBackIosOutlinedIcon)`
  position: relative;
  padding: 10px 12px;
  background-color: white;
  box-shadow: 0px 0px 10px rgb(0, 0, 0, 0.3);
  border-radius: 10px;
  display: flex;
  color: black;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  font-size: 0.8vw;
  margin-right: 5px;
`;

function BestSellers({ isMobile, products }) {
  return (
    products &&
    products.length > 0 && (
      <MainDiv isMobile={isMobile}>
        <Parallax
          translateY={isMobile ? ["90%", "-35%"] : ["10%", "-50%"]}
          scale={["0%", "100%"]}
          speed={0.5}
          easing={"ease"}
          style={{ position: "absolute", width: "100%" }}
        >
          <Heading>Best Sellers</Heading>
        </Parallax>

        <Parallax
          translateY={isMobile ? ["-30%", "22%"] : ["0%", "15%"]}
          speed={0.5}
          easing={"ease"}
        >
          <Column style={{ marginTop: "20px" }}>
            <Row>
              {!isMobile && (
                <CustomNavButtonBack className="swiper-button-prev" style={{fontSize:"1vw"}}/>
              )}
              <SwiperContainer
                slidesPerView={"auto"}
                centeredSlides={false}
                pagination={false}
                spaceBetween={5}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                }}
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
                grabCursor={true}
                mousewheel={true}
                touchEventsTarget="wrapper"
                loop={true}
              >
                {products.map((product, index) => (
                  <SwiperSlide
                    key={index}
                    style={{ width: "auto" }}
                  >
                    <ProductCard
                      key={index}
                      product={product}
                      // isFirst={index === 0}
                    />
                  </SwiperSlide>
                ))}
              </SwiperContainer>
              {!isMobile && (
                <CustomNavButtonForward className="swiper-button-next" style={{fontSize:"1vw"}}/>
              )}
            </Row>
          </Column>
        </Parallax>
      </MainDiv>
    )
  );
}

{
  /* 
  
  // Old Products
  
  <ProductCard
  // There should be gifs playing instead of photos
  imageSrc="https://images-cdn.ubuy.co.in/657531b82270b43c0a75e7b1-phomemo-m110-label-makers-barcode.jpg"
  productId={"home-shoe-wipes-6058-e26b-f0fe"}
  name={"Phomemo Printer"}
  cover1="https://images-cdn.ubuy.co.in/657531b82270b43c0a75e7b1-phomemo-m110-label-makers-barcode.jpg"
  isColumn
  category={"Useful Tech, Printers"}
  comparedPrice={"999"}
/>
<ProductCard
  imageSrc="https://rukminim2.flixcart.com/image/850/1000/xif0q/portable-projector/q/w/q/led-android-portable-mini-projector-for-home-4k-1080p-hd-wifi-original-imagsfg8twwfgdds.jpeg?q=90&crop=false"
  cover1="https://rukminim2.flixcart.com/image/850/1000/xif0q/portable-projector/q/w/q/led-android-portable-mini-projector-for-home-4k-1080p-hd-wifi-original-imagsfg8twwfgdds.jpeg?q=90&crop=false"
  isColumn
  name={"Mini Android Projector"}
  category={"Useful Tech, Projectors"}
  comparedPrice={"999"}
/>

<ProductCard
  imageSrc="https://crowcrowcrow.com/cdn/shop/files/51Tq-43n7xL._AC_SX466_b6308325-fb43-4904-813a-12cfce9e1351.jpg?v=1692004144"
  cover1="https://crowcrowcrow.com/cdn/shop/files/51Tq-43n7xL._AC_SX466_b6308325-fb43-4904-813a-12cfce9e1351.jpg?v=1692004144"
  isColumn
  name={"400X Zoom Cover"}
  category={"Useful Tech, Covers"}
  comparedPrice={"999"}
/>
<ProductCard
  imageSrc="https://m.media-amazon.com/images/I/71l+JbGKK9L._SL1329_.jpg"
  cover1="https://m.media-amazon.com/images/I/71l+JbGKK9L._SL1329_.jpg"
  isColumn
  name={"Astronaut Projector"}
  category={"Useful Tech, Projectors"}
  comparedPrice={"999"}
/>

<ProductCard
  imageSrc="https://thegreytechnologies.in/cdn/shop/products/portable-washing-machine-3_2000x.jpg?v=1688381901"
  cover1="https://thegreytechnologies.in/cdn/shop/products/portable-washing-machine-3_2000x.jpg?v=1688381901"
  isColumn
  name={"Washing Machine"}
  category={"Home, Laundary"}
  comparedPrice={"999"}
/>
<ProductCard
  imageSrc="https://img.joomcdn.net/94fe28469c5e82bd6e48fe1fdcb0df82e1b63892_original.jpeg"
  cover1="https://img.joomcdn.net/94fe28469c5e82bd6e48fe1fdcb0df82e1b63892_original.jpeg"
  isColumn
  name={"Foldable Keyboard"}
  category={"Useful Tech"}
  comparedPrice={"999"}
/>
<ProductCard
  imageSrc="https://rukminim2.flixcart.com/image/850/1000/kwcfngw0/home-security-camera/k/i/i/1-ccduyr76-indoor-outdoor-security-camera-jronj-original-imag9fmze86d5tfx.jpeg?q=90&crop=false"
  cover1="https://rukminim2.flixcart.com/image/850/1000/kwcfngw0/home-security-camera/k/i/i/1-ccduyr76-indoor-outdoor-security-camera-jronj-original-imag9fmze86d5tfx.jpeg?q=90&crop=false"
  isColumn
  name={"Mini Security Camera"}
  category={"Useful Tech"}
  comparedPrice={"999"}
/> */
}

export default BestSellers;

const MainDiv = styled.div`
  /* width: 98%; */
  margin: ${(props) =>
    props.isMobile ? "5vh 0px 6vh 0px" : "0px 0px 8vh 0px"};
  display: flex;
  flex-direction: column;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  overflow: auto;
  z-index: 1;
`;

const Row = styled.div`
  width: 95%;
  margin: 0px auto;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 5px 0px;
  background-color: white;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Heading = styled.h1`
  margin-left: 15px;
  font-size: 3vw;
  font-weight: 900;
  /* letter-spacing: 0.02ch; */

  @media (max-width: 768px) {
    font-size: 13vw;
    margin-left: 0px;
    font-weight: 600;
    font-family: cursive;
    text-align: center;
  }
`;

const TagDiv = styled.div`
  display: flex;
  overflow-x: auto;
  margin: 5px 0px 10px 15px;
`;

const Tag = styled.button`
  margin: 0px;
  margin-left: 10px;
  border-radius: 5px;
  border: 0.5px solid #7d7c7c;
  font-weight: 400;
  font-size: 13px;
  padding: 4px 10px;
  cursor: pointer;

  &:hover {
    background-color: #045df6;
    color: white;
  }
`;

import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";

// Dummy data for the notifications
const notifications = [
  {
    id: 1,
    name: "Ittie",
    residence: "Nahan",
    profilePic:
      "https://www.hotelbooqi.com/wp-content/uploads/2021/12/128-1280406_view-user-icon-png-user-circle-icon-png.png",
  },
  {
    id: 2,
    name: "Rohit",
    residence: "Hogwarts",
    profilePic:
      "https://www.hotelbooqi.com/wp-content/uploads/2021/12/128-1280406_view-user-icon-png-user-circle-icon-png.png",
  },
  {
    id: 3,
    name: "Momi",
    residence: "Papa",
    profilePic:
      "https://www.hotelbooqi.com/wp-content/uploads/2021/12/128-1280406_view-user-icon-png-user-circle-icon-png.png",
  },
  // Add more entries as needed
];

const JustBoughtNotification = ({ isAbsolute, customRandom, addToCart }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const showNotification = () => {
      setIsVisible(true);
      setTimeout(() => {
        setIsVisible(false);
      }, 5000);
    };

    const interval = setInterval(() => {
      const nextIndex = (currentIndex + 1) % notifications.length;
      setCurrentIndex(nextIndex);
      showNotification();
    }, 15000 + Math.random() * 15000);

    return () => clearInterval(interval);
  }, [currentIndex]);

  return (
    <NotificationContainer
      isVisible={isVisible}
      isAbsolute={isAbsolute}
      onClick={() => {
        if (addToCart) {
          addToCart(notifications[currentIndex].id);
        }
      }}
    >
      <ProfilePic src={notifications[currentIndex].profilePic} alt="Profile" />
      <NotificationText>
        {notifications[currentIndex].name} from{" "}
        {notifications[currentIndex].residence} just bought
      </NotificationText>
    </NotificationContainer>
  );
};

export default JustBoughtNotification;

// CSS styles
const slideIn = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideOut = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    opacity: 0;
  }
`;

const NotificationContainer = styled.div`
  /* display: ${({ isVisible }) => (isVisible ? "flex" : "none")}; */
  display: flex;
  position: ${(props) => (props.isAbsolute ? "sticky" : "auto")};
  bottom: ${(props) => (props.isAbsolute ? "0px" : "auto")};
  background-color: ${(props) => (props.isAbsolute ? "#eff4fe" : "auto")};
  justify-content: ${(props) => (props.isAbsolute ? "center" : "auto")};
  align-items: center;
  padding: 10px 20px;
  border-radius: 10px;
  animation: ${({ isVisible }) => (isVisible ? slideIn : slideOut)} 0.5s ease
    forwards;
  transition: opacity 0.5s;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  z-index: 10001;
`;

const ProfilePic = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
`;

const NotificationText = styled.span`
  font-size: 0.7vw;
  color: #333;
  text-align: start;

  @media (max-width: 768px) {
    font-size: 11px;
  }
`;

import React, { useEffect, useState } from "react";
import { useCart } from "./components/CartContext";
import styled, { keyframes } from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";
import { firestore as db } from "../firebase.config";
import { collection, getDocs, query, where } from "firebase/firestore";
import SkeletonLoader from "../Global/Loading_Skeleton/SkeletonLoaderCart";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ProductCardSmaller from "../utils/Product/ProductCards/ProductCardSmaller";

const Cart = ({ isCartOpen, toggleCart, isMobile, products }) => {
  const { state, dispatch } = useCart();
  const [loadProducts, setLoadProducts] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProductDetails = async () => {
      const productIds = state.items.map((item) => item.productId);
      if (productIds.length > 0) {
        const q = query(
          collection(db, "products"),
          where("id", "in", productIds)
        );
        const querySnapshot = await getDocs(q);
        const products = {};
        querySnapshot.forEach((doc) => {
          products[doc.id] = doc.data();
        });
        setLoadProducts(products);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        setLoading(false);
      }
    };

    fetchProductDetails();
  }, [state.items]);

  const removeFromCart = (productId) => {
    dispatch({ type: "REMOVE_ITEM", payload: { productId } });
  };

  const handleQuantityIncrement = (product) => {
    dispatch({
      type: "INCREMENT_QUANTITY",
      payload: { productId: product.productId },
    });
  };

  const handleQuantityDecrement = (product) => {
    dispatch({
      type: "DECREMENT_QUANTITY",
      payload: { productId: product.productId },
    });
  };

  const calculateTotalPrice = () => {
    let totalPrice = 0;
    state.items.forEach((item) => {
      const product = loadProducts[item.productId];
      totalPrice +=
        (product ? product.prices.SellingPrice : 500) * item.quantity;
    });
    return totalPrice;
  };

  // Progress handler
  // const [currentStep, setCurrentStep] = useState(2);

  // const handleNextStep = () => {
  //   if (currentStep < 3) {
  //     setCurrentStep(currentStep + 1);
  //   }
  // };

  // const handlePrevStep = () => {
  //   if (currentStep > 1) {
  //     setCurrentStep(currentStep - 1);
  //   }
  // };

  // const handleChange = (e, setState) => {
  //   const { name, value } = e.target;
  //   setState((prev) => ({ ...prev, [name]: value }));
  // };

  return (
    <CartContainer isOpen={isCartOpen} isMobile={isMobile}>
      {/* Secured By Norton, Backed by PhonePe! */}
      <Row
        style={{
          width: "85%",
          margin: "10px auto 10px auto",
          padding: "2px 0px",
          alignItems: "flex-start",
        }}
      >
        <Column
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CheckpointCount filled>1</CheckpointCount>
          <CheckpointText>Cart</CheckpointText>
        </Column>
        <ProgressLine active />
        <Column
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CheckpointCount>2</CheckpointCount>
          {/* <OpacityIcon style={{ color: "green", fontSize: "4vw" }} /> */}

          <CheckpointText>Address</CheckpointText>
        </Column>
        <ProgressLine />
        <Column style={{ alignItems: "center", justifyContent: "center" }}>
          <CheckpointCount>3</CheckpointCount>
          <CheckpointText>Summary</CheckpointText>
        </Column>
        <ProgressLine />
        <Column style={{ alignItems: "center", justifyContent: "center" }}>
          <CheckpointCount>4</CheckpointCount>
          <CheckpointText>Payment</CheckpointText>
        </Column>
      </Row>

      <TopBar isMobile={isMobile}>
        {/* <ArrowBackIcon style={{ fontSize: "6.2vw", marginRight: "10px" }} /> */}
        Shopping Cart
        <CloseIcon
          onClick={() => {
            if (isMobile) {
              navigate(-1);
            } else {
              toggleCart();
            }
          }}
          style={{ cursor: "pointer" }}
        />
      </TopBar>

      <ProductContainer>
        <MidBar isMobile={isMobile}>{state.items.length} (Items) ▼</MidBar>
        {loading ? (
          // Show Skeleton Loaders while loading
          Array.from({ length: state.items.length }).map((_, index) => (
            <SkeletonLoader key={index} howmany={3} />
          ))
        ) : state.items.length > 0 ? (
          state.items.map((item) => {
            const product = loadProducts[item.productId];
            return (
              <ProductCard
                key={item.productId}
                onClick={() => {
                  navigate(`/products/${product.id}/`, {
                    state: { product: product },
                  });
                }}
              >
                <ProductImage
                  alt="product_image"
                  src={
                    product
                      ? product.imageUrls.ShowCaseURL
                      : "https://via.placeholder.com/150"
                  }
                />
                <Column
                  style={{
                    marginLeft: "25px",
                    width: "60%",
                    marginTop: "10px",
                  }}
                >
                  <Text
                    style={{
                      textAlign: "start",
                      fontSize: isMobile ? "4.7vw" : "1.05vw",
                      letterSpacing: "0.05ch",
                      fontWeight: "500",
                      width: "90%",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {product ? product.name : "Loading..."}
                  </Text>
                  <Text
                    style={{
                      textAlign: "start",
                      marginTop: "3px",
                      fontSize: isMobile ? "2.6vw" : "0.7vw",
                      color: "#555555",
                    }}
                  >
                    {product && product.category} | {product && product.tags[0]}
                  </Text>
                  <Row
                    style={{
                      margin: 0,
                      marginTop: "12px",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: isMobile ? "4.5vw" : "1.2vw",
                        marginTop: "1px",
                        alignSelf: "flex-start",
                        color: "green",
                      }}
                    >
                      ₹
                    </Text>
                    <Text
                      style={{
                        fontSize: isMobile ? "5.2vw" : "1.3vw",
                        marginLeft: isMobile ? "2px" : "3px",
                        fontWeight: "600",
                        color: "green",
                      }}
                    >
                      {product ? product.prices.SellingPrice : "Loading..."}
                    </Text>

                    <Text
                      style={{
                        fontSize: isMobile ? "3.7vw" : "1vw",
                        marginLeft: "4px",
                        marginTop: "3px",
                        textDecoration: "line-through",
                        color: "grey",
                        fontWeight: "600",
                      }}
                    >
                      {product
                        ? ` ${product.prices["ComparedShowCase Price"]} `
                        : "Loading..."}
                    </Text>
                  </Row>

                  <Row
                    style={{
                      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
                      padding: "0px",
                      borderRadius: "5px",
                      margin: 0,
                      marginTop: isMobile ? "15px" : "20px",
                    }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <RemoveIcon
                      style={{
                        color: "#969696",
                        fontSize: isMobile ? "23px" : "24px",
                        padding: "3.5px",
                        cursor: "pointer",
                        backgroundColor: "#f5f5f8",
                      }}
                      onClick={() => handleQuantityDecrement(item)}
                    />
                    <Text
                      style={{
                        margin: "0px 15px",
                        padding: "3px 0px",
                        fontSize: isMobile ? "3.8vw" : "1vw",
                        fontWeight: "500",
                      }}
                    >
                      {item.quantity}
                    </Text>
                    <AddIcon
                      style={{
                        color: "#969696",
                        cursor: "pointer",
                        fontSize: isMobile ? "23px" : "24px",
                        padding: "3.5px",
                        backgroundColor: "#f5f5f8",
                      }}
                      onClick={() => handleQuantityIncrement(item)}
                    />
                  </Row>
                  <DeleteForeverIcon
                    style={{ position: "absolute", bottom: 10, right: 10 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      removeFromCart(item.productId);
                    }}
                  />
                </Column>
              </ProductCard>
            );
          })
        ) : (
          <div>
            <img
              style={{ width: "75%", marginTop: "20px" }}
              src="https://cdni.iconscout.com/illustration/premium/thumb/empty-cart-2130356-1800917.png"
              // src="https://cdni.iconscout.com/illustration/premium/thumb/empty-cart-7359557-6024626.png?f=webp"
              alt=""
            />
            <Text style={{ marginTop: "15px", fontSize: "14px" }}>
              Your cart is empty
            </Text>
          </div>
        )}

        {products && products.length > 0 && (
          <>
            <MidBar
              isMobile={isMobile}
              style={{
                marginTop: state.items.length > 0 ? "80px" : "50px",
                display: "flex",
                justifyContent: "center",
                padding: "10px 0",
              }}
            >
              <div
                style={{
                  alignSelf: "center",
                  height: "1px",
                  flex: 0.15,
                  marginRight: "5px",
                  background: "#c5c1c1",
                }}
              />
              Our Most Selling Gadgets
              <div
                style={{
                  alignSelf: "center",
                  height: "1px",
                  flex: 1,
                  marginLeft: "5px",
                  background: "#c5c1c1",
                }}
              />
            </MidBar>

            <ProductRow>
              {products
                .filter((product) => product.tags.includes("Best Sellers"))
                .map((product, index) => (
                  <ProductCardSmaller
                    key={product.id}
                    product={product}
                    isFirst={index === 0}
                  />
                ))}
            </ProductRow>
          </>
        )}
      </ProductContainer>

      {/* <Text
        style={{
          textAlign: "start",
          margin:
            state.items.length > 0
              ? "60px 0px 30px 30px"
              : "120px 0px 30px 30px",
          fontSize: isMobile ? "3.6vw" : "0.9vw",
        }}
      >
        {state.items.length > 0
          ? "Product Loved Together -"
          : "Explore Best Selling Products"}
      </Text> */}

      {!loading && state.items.length > 0 && (
        <CheckOutDiv>
          {/* <Seprator /> */}

          <RemoveButton
            style={{
              marginLeft: "20px",
              fontSize: isMobile ? "14px" : "15px",
              fontWeight: "600",
              marginTop: "12px",
              marginBottom: "12px",
              alignSelf: "flex-start",
            }}
          >
            Price Details
          </RemoveButton>
          <Row
            style={{
              justifyContent: "space-between",
              margin: isMobile ? "6px auto" : "10px auto",
              width: "100%",
            }}
          >
            <Text
              style={{
                marginLeft: "20px",
                fontSize: isMobile ? "14px" : "15px",
                fontWeight: "400",
              }}
            >
              Price ({state.items.length} item)
            </Text>
            <Text
              style={{
                marginRight: "20px",
                fontSize: isMobile ? "14px" : "16px",
                fontWeight: "400",
              }}
            >
              ₹{calculateTotalPrice()}.00
            </Text>
          </Row>

          <Row
            style={{
              justifyContent: "space-between",
              margin: isMobile ? "6px auto" : "10px auto",
              width: "100%",
            }}
          >
            <Text
              style={{
                marginLeft: "20px",
                fontSize: isMobile ? "14px" : "15px",
                fontWeight: "400",
              }}
            >
              Tax / GST
            </Text>
            <Text
              style={{
                marginRight: "20px",
                fontSize: isMobile ? "14px" : "16px",
                fontWeight: "400",
                color: "#d77f14",
              }}
            >
              + ₹35
            </Text>
          </Row>

          <Row
            style={{
              justifyContent: "space-between",
              margin: isMobile ? "6px auto" : "10px auto",
              width: "100%",
            }}
          >
            <Text
              style={{
                marginLeft: "20px",
                fontSize: isMobile ? "14px" : "15px",
                fontWeight: "400",
              }}
            >
              Discount
            </Text>
            <Text
              style={{
                marginRight: "20px",
                fontSize: isMobile ? "14px" : "16px",
                fontWeight: "400",
                color: "green",
              }}
            >
              — ₹35
            </Text>
          </Row>
          <Row
            style={{
              justifyContent: "space-between",
              margin: isMobile ? "6px auto" : "10px auto",
              width: "100%",
            }}
          >
            <Text
              style={{
                marginLeft: "20px",
                fontSize: isMobile ? "14px" : "15px",
                fontWeight: "400",
              }}
            >
              Delivery Charges
            </Text>

            <Row style={{ justifyContent: "flex-end", margin: 0 }}>
              <Text
                style={{
                  marginRight: "6px",
                  fontSize: isMobile ? "15px" : "16px",
                  fontWeight: "400",
                  color: "grey",
                  textDecoration: "line-through",
                }}
              >
                ₹40
              </Text>
              <Text
                style={{
                  marginRight: "20px",
                  fontSize: isMobile ? "14px" : "16px",
                  fontWeight: "400",
                  color: "green",
                }}
              >
                FREE Delivery
              </Text>
            </Row>
          </Row>

          <Seprator
            style={{
              margin: isMobile ? "6px auto" : "10px auto",
              width: "90%",
            }}
          />

          <Row
            style={{
              justifyContent: "space-between",
              margin: "5px 0px",
              width: "100%",
            }}
          >
            <Text
              style={{
                marginLeft: "20px",
                fontSize: isMobile ? "15px" : "17px",
                fontWeight: "600",
              }}
            >
              Total
            </Text>
            <Text
              style={{
                marginRight: "20px",
                fontSize: isMobile ? "15px" : "18px",
                fontWeight: "600",
                color: "#198311",
              }}
            >
              ₹{calculateTotalPrice()}.00
            </Text>
          </Row>

          <CheckOutButton
            onClick={() => {
              if (!isMobile) {
                toggleCart();
              }
              navigate("/checkout", {
                state: { products: state.items, loadedProducts: loadProducts },
              });
            }}
          >
            CHECKOUT{" "}
            <img
              src="https://cdn.gokwik.co/v4/images/upi-icons.svg"
              style={{ margin: "0px 7px" }}
            />{" "}
            <BouncingIcon />
          </CheckOutButton>
        </CheckOutDiv>
      )}
    </CartContainer>
  );
};

export default Cart;

const CartContainer = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  right: ${(props) => (props.isOpen ? "0" : props.isMobile ? "-105%" : "-28%")};
  width: ${(props) => (props.isMobile ? "100%" : "25%")};
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transition: right 0.5s ease-in;
  z-index: 1004;
  overflow: hidden;
`;

const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  background-color: #d3e3fe;
  font-weight: 500;
  color: black;
  font-size: ${(props) => (props.isMobile ? "3.6vw" : "0.85vw")};
  /* margin-bottom: 15px; */
  text-align: center;
  letter-spacing: 0.06ch;
`;

const MidBar = styled.div`
  padding: 10px 18px;
  /* background-color: #393535; */
  color: black;
  font-size: ${(props) => (props.isMobile ? "3.3vw" : "0.75vw")};
  /* margin-bottom: 5px; */
  text-align: start;
  letter-spacing: 0.08ch;
`;

const Seprator = styled.div`
  width: 100%;
  height: 1px;
  background-color: #d7dad5;
  margin: 15px 0px 20px 0px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin: 0px 25px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  font-size: 1vw;

  @media (max-width: 768px) {
    font-size: 3.2vw;
  }
`;

const Text = styled.p`
  color: black;
  font-weight: 400;
  margin: 0;
  font-size: 15px;

  @media (max-width: 768px) {
    font-size: 4.2vw;
  }
`;

const ProgressLine = styled.div`
  flex: 1;
  height: 2px;
  margin-top: 10px;
  background-color: ${({ active }) => (active ? "green" : "#ddd")};
`;

const CheckpointCount = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.filled ? "white" : "black")};
  font-weight: 400;
  width: ${(props) => (props.filled ? "1.3vw" : "1.1vw")};
  height: ${(props) => (props.filled ? "1.3vw" : "1.1vw")};
  background-color: ${(props) => (props.filled ? "#48b653" : "#fff")};
  border-radius: 50%;
  border: ${(props) => (props.filled ? "none" : "1px solid black")};
  font-size: 0.8vw;

  @media (max-width: 768px) {
    width: ${(props) => (props.filled ? "5.6vw" : "5.4vw")};
    height: ${(props) => (props.filled ? "5.6vw" : "5.4vw")};
    font-size: 3.3vw;
  }
`;

const CheckpointText = styled.span`
  color: black;
  font-weight: 400;
  margin: 0;
  margin-top: 4px;
  font-size: 0.75vw;

  @media (max-width: 768px) {
    font-size: 3.1vw;
  }
`;

const ProductContainer = styled.div`
  overflow-y: auto;
  background-color: #fdfcfc;
  &::-webkit-scrollbar {
    display: none; /* Hide the scrollbar for WebKit browsers */
  }

  & {
    -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
    scrollbar-width: none; /* Hide scrollbar for Firefox */
  }
`;

const ProductRow = styled.div`
  width: 100%;
  margin: 0px auto;
  display: flex;
  align-items: start;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 5px 0px;
  background-color: white;
  margin-bottom: 50px;
  margin-top: 5px;

  &::-webkit-scrollbar {
    display: none;
  }

  & {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

const ProductCard = styled.div`
  position: relative;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.04);
  /* border-bottom: 1px solid #efe9e9; */
  padding: 10px 10px;
  border-radius: 3px;
  background-color: white;
  display: flex;
  margin: 0px;
  align-items: flex-start;
  margin-bottom: 15px;
`;

const ProductImage = styled.img`
  width: 36%;
  height: auto;
  object-fit: contain;
  aspect-ratio: 1;
`;

const RemoveButton = styled.p`
  font-size: 0.65vw;
  font-weight: 600;
  border-bottom: 1px solid black;
  padding-bottom: 5px;
  margin-bottom: 23px;
  margin-right: 10px;
  align-self: flex-end;
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 2.6vw;
    margin-bottom: 5px;
    margin-top: -5px;
  }
`;

const CheckOutDiv = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  width: 97%;
  margin: 0px auto;
  padding-top: 5px;
  padding-bottom: 2px;
  /* background-color: #f8f9fc; */
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.15);
  align-items: start;
  justify-content: start;
`;

const CheckOutButton = styled.button`
  /* display: flex;
  position: absolute;
  bottom: 2px;
  left: 10px;
  right: 10px; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 93%;
  border: 0px;
  background-color: #0a5ff2;
  /* font-weight: 300; */
  border-radius: 40px;
  font-size: 13.5px;
  letter-spacing: 0.25ch;
  padding: 14px 0px;
  color: white;
  margin: 15px auto;
  font-weight: 500;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 95%;
    margin: 15px auto 7px auto;
  }
`;

const bounce = keyframes`
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-5px);
  }
  25%, 75% {
    transform: translateX(5px);
  }
`;

const BouncingIcon = styled(NavigateNextIcon)`
  animation: ${bounce} 1.5s infinite;
`;

import React from "react";
import styled, { keyframes } from "styled-components";

const SkeletonLoaderProducts = ({ howmany }) => {
  const skeletons = Array.from({ length: howmany });

  return (
    <Container>
      {skeletons.map((_, index) => (
        <SkeletonContainer key={index}>
          <SkeletonImage />
          <SkeletonText>
            <SkeletonLine width="90%" />
            <SkeletonLine width="40%" />
            <SkeletonLine width="60%" />
            <SkeletonLine width="90%" style={{height:"45px", marginTop:"15px"}}/>
          </SkeletonText>
        </SkeletonContainer>
      ))}
    </Container>
  );
};

export default SkeletonLoaderProducts;

const shimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-self: center;
  gap: 0px 15px;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none; /* Hide the scrollbar for WebKit browsers */
  }

  & {
    -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
    scrollbar-width: none; /* Hide scrollbar for Firefox */
  }

  @media (max-width: 768px) {
    align-self: auto;
    margin-left: 10px;
  }
`;

const SkeletonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  padding: 10px;
  background: #fff;
  border: 0.5px solid #eae8e8;
  border-radius: 20px;
`;

const SkeletonImage = styled.div`
  width: 13vw;
  height: 13vw;
  background: #e0e0e0;
  border-radius: 10px;
  animation: ${shimmer} 1.2s infinite linear;
  background: linear-gradient(to right, #e0e0e0 8%, #f0f0f0 18%, #e0e0e0 33%);
  background-size: 800px 104px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    width: 45vw;
    height: 45vw;
  }
`;

const SkeletonText = styled.div`
  flex: 1;
  margin-left: 10px;
`;

const SkeletonLine = styled.div`
  height: 20px;
  background: #e0e0e0;
  border-radius: 10px;
  margin-bottom: 10px;
  width: ${({ width }) => width || "100%"};
  animation: ${shimmer} 1.2s infinite linear;
  background: linear-gradient(to right, #e0e0e0 8%, #f0f0f0 18%, #e0e0e0 33%);
  background-size: 800px 104px;
`;

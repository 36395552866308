import React from "react";
import styled from "styled-components";
import { renderStars } from "../../../../products/components/Reviews";
import Person3OutlinedIcon from "@mui/icons-material/Person3Outlined";

function ReviewCard({ isMobile, review }) {
  return (
    <ReviewContainer>
      <Container>
        <Row>
          <ProfileContainer>
            <ProfileImage src={review.profileSrc} alt="user" />
            <ProfileCover />
          </ProfileContainer>
          <div>
            <Heading style={{ fontSize: isMobile ? "3.3vw" : "1.2vw" }}>
              {review.name}
            </Heading>
            <Paragraph
              style={{
                fontSize: isMobile ? "2.8vw" : "0.7vw",
                marginTop: "2px",
                color: "grey",
              }}
            >
              Lives In {review.location}
            </Paragraph>
          </div>
        </Row>
        <Paragraph
          style={{
            fontSize: isMobile ? "3vw" : "0.8vw",
            margin: isMobile ? "10px" : "20px",
            fontWeight: "500",
            display: "flex",
            alignItems: "center",
          }}
        >
          {/* ❝❞ */}
          <Stars>{renderStars(review.rating)}</Stars> {review.rating}
          {" out of 5"}
        </Paragraph>
        <Paragraph
          style={{
            fontSize: isMobile ? "3.5vw" : "1vw",
            margin: isMobile ? "10px" : "20px",
          }}
        >
          {/* ❝❞ */}
          {review.review}
        </Paragraph>

        <Paragraph
          style={{
            fontSize: isMobile ? "2.8vw" : "0.7vw",
            margin: isMobile ? "10px" : "20px",
            color: "grey",
          }}
        >
          {/* ❝❞ */}
          Reviewed on : {review.date}
        </Paragraph>
      </Container>
    </ReviewContainer>
  );
}

export default ReviewCard;

const ReviewContainer = styled.div`
  width: 100vw;
  padding: 10px 0px;
  flex-shrink: 0;
`;

const Container = styled.div`
  width: 40%;
  margin: 0 auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-top-left-radius: 40px;
  border-bottom-right-radius: 40px;
  padding: 70px;
  background-color: #f1f4f6;
  /* border: 1px solid #dcdcdc; */
  @media (max-width: 768px) {
    width: 90%;
    padding: 20px 10px 30px 10px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  text-align: start;
`;

const ProfileContainer = styled.div`
  width: 90px;
  height: 90px;
  border-radius: 50px;
  margin-right: 10px;

  @media (max-width: 768px) {
    width: 60px;
    height: 60px;
    margin-right: 8px;
  }
`;

const ProfileCover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const ProfileImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50px;
  object-fit: contain;
`;

const Heading = styled.h1`
  margin: 0;
`;

const Paragraph = styled.p`
  margin: 0;
  text-align: start;

  @media (max-width: 768px) {
    line-height: 18px;
    letter-spacing: 0.015ch;
  }
`;

const Stars = styled.div`
  display: flex;
  align-items: center;
  margin-right: 5px;

  svg {
    color: #ffa41c;
    margin-right: 3px;
  }
`;

import React, { useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import { css, useMediaQuery } from "@mui/material";
import { Parallax } from "react-scroll-parallax";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import ProductCardSmaller from "../ProductCards/ProductCardVertical";
import { ReviewsCardDiv } from "../../../routes/products/components/Reviews";
import { useNavigate } from "react-router-dom";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";

SwiperCore.use([Navigation, Pagination, Autoplay]);

const SwiperContainer = styled(Swiper)`
  .swiper-pagination {
    bottom: 10px;
  }
  margin: 0 35px;

  @media (max-width: 768px) {
    margin: 0;
  }
`;

const CustomNavButtonForward = styled(ArrowForwardIosOutlinedIcon)`
  position: relative;
  padding: 10px 12px;
  background-color: white;
  color: black;
  box-shadow: 0px 0px 10px rgb(0, 0, 0, 0.3);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 0.8vw;
  z-index: 10;
  margin-left: 5px;
`;

const moveLeftRight = keyframes`
  0% { transform: translateX(3px); }
  50% { transform: translateX(-3px); }
  100% { transform: translateX(3px); }
`;

const AnimatedIcon = styled(ArrowForwardIosOutlinedIcon)`
  font-size: 4vw;
  animation: ${moveLeftRight} 0.8s infinite;
`;

const CustomNavButtonForwardAnimated = styled(AnimatedIcon)`
  position: relative;
  padding: 10px 12px;
  background-color: white;
  color: black;
  box-shadow: 0px 0px 10px rgb(0, 0, 0, 0.3);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 0.8vw;
  z-index: 10;
  margin-left: 5px;
`;

const CustomNavButtonBack = styled(ArrowBackIosOutlinedIcon)`
  position: relative;
  padding: 10px 12px;
  background-color: white;
  box-shadow: 0px 0px 10px rgb(0, 0, 0, 0.3);
  border-radius: 10px;
  display: flex;
  color: black;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  font-size: 0.8vw;
  margin-right: 5px;
`;

function ProductListDiv({ Title, About, isFirst, backgroundColor, products }) {
  const isMobile = useMediaQuery("(max-width:768px)");
  const [reviewsList, setReviewsList] = useState([]);
  const navigate = useNavigate();

  const handleReviewClick = (productName) => {
    navigate(`/products/${products[productName].id}`, {
      state: { product: products[productName] },
    });
  };
  // const reviews = isMobile
  //   ? [
  //       {
  //         name: "Rohit Singh",
  //         date: "18/03/2024",
  //         title: "WOW",
  //         rating: 4.5,
  //         review:
  //           "This product is amazing! I've seen a huge difference in my hair texture.",
  //       },
  //     ]
  //   : [
  //       {
  //         name: "Rohit Singh",
  //         date: "18/03/2024",
  //         title: "WOW",
  //         rating: 4.5,
  //         review:
  //           "This product is amazing! I've seen a huge difference in my hair texture.",
  //       },
  //       {
  //         name: "User1",
  //         date: "18/03/2024",
  //         title: "Loved It",
  //         rating: 5,
  //         review: "Absolutely love it! Highly recommend.",
  //       },
  //       {
  //         name: "User2",
  //         date: "18/03/2024",
  //         title: "Best Product",
  //         rating: 4,
  //         review: "Great product, but a bit pricey.",
  //       },
  //     ];

  return (
    products &&
    products.length > 0 && (
      <Container isMobile={isMobile} isFirst={isFirst}>
        <Parallax
          translateY={
            isMobile ? ["-30%", "-0%", "easeInOut"] : ["-5%", "3%", "easeInOut"]
          }
          opacity={[0, 1.5]}
          speed={0.5}
          easing={"ease"}
          style={{ width: "100%" }}
        >
          <Heading>{Title}</Heading>

          <Row
            style={{
              marginTop: "5px",
              justifyContent: isMobile ? "flex-start" : "center",
            }}
            isMobile={isMobile}
          >
            <CategoryCoverDiv isMobile={isMobile}>
              <CategoryCoverImage
                isMobile={isMobile}
                src="https://nathabit.in/_nat/images/Collection_Image_Upload_9f2edb8585.jpg?format=auto&width=640&quality=75"
                alt="category"
              />
              <Text isMobile={isMobile}>Sub Category Here</Text>
            </CategoryCoverDiv>
            <CategoryCoverDiv isMobile={isMobile}>
              <CategoryCoverImage
                isMobile={isMobile}
                src="https://nathabit.in/_nat/images/Daily_Ubtan_collection_4e89e4b396.jpg?format=auto&width=384&quality=75"
                alt="category"
              />
              <Text isMobile={isMobile}>Sub Category Here</Text>
            </CategoryCoverDiv>
            <CategoryCoverDiv isMobile={isMobile}>
              <CategoryCoverImage
                isMobile={isMobile}
                src="https://nathabit.in/_nat/images/mukhalaya_collection_1_ec2b6a126e.jpg?format=auto&width=640&quality=75"
                alt="category"
              />
              <Text isMobile={isMobile}>Sub Category Here</Text>
            </CategoryCoverDiv>
            <CategoryCoverDiv isMobile={isMobile}>
              <CategoryCoverImage
                isMobile={isMobile}
                src="https://nathabit.in/_nat/images/flora_jal_collection_image_ed5415fc71.jpg?format=auto&width=384&quality=75"
                alt="category"
              />
              <Text isMobile={isMobile}>Sub Category Here</Text>
            </CategoryCoverDiv>
            <CategoryCoverDiv isMobile={isMobile}>
              <CategoryCoverImage
                isMobile={isMobile}
                src="https://nathabit.in/_nat/images/35_yrs_collection_image_03_9a71cf2daf.jpg?format=auto&width=640&quality=75"
                alt="category"
              />
              <Text isMobile={isMobile}>Sub Category Here</Text>
            </CategoryCoverDiv>
            <CategoryCoverDiv isMobile={isMobile}>
              <CategoryCoverImage
                isMobile={isMobile}
                src="https://nathabit.in/_nat/images/Active_gel_collection_9714498f20.jpg?format=auto&width=640&quality=75"
                alt="category"
              />
              <Text isMobile={isMobile}>Sub Category Here</Text>
            </CategoryCoverDiv>
            <CategoryCoverDiv isMobile={isMobile}>
              <CategoryCoverImage
                isMobile={isMobile}
                src="https://nathabit.in/_nat/images/Mura_50d305b08a.jpg?format=auto&width=384&quality=75"
                alt="category"
              />
              <Text isMobile={isMobile}>Sub Category Here</Text>
            </CategoryCoverDiv>
          </Row>
        </Parallax>

        <div
          style={{
            width: "100%",
            backgroundColor: backgroundColor,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Parallax
            // translateX={isMobile && ["-6%", "-0%", "ease"]}
            opacity={[0.5, 1.5]}
            speed={0.5}
            easing={"ease"}
            style={{ width: "100%" }}
          >
            <Row
              isMobile={isMobile}
              style={{ backgroundColor: backgroundColor }}
            >
              {!isMobile && (
                <CustomNavButtonBack
                  className="swiper-button-prev"
                  style={{ fontSize: "1vw" }}
                />
              )}

              <SwiperContainer
                slidesPerView={"auto"}
                centeredSlides={false}
                spaceBetween={5}
                pagination={false}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                }}
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
                grabCursor={true}
                mousewheel={true}
                touchEventsTarget="wrapper"
                loop={true}
              >
                {products.map((product, index) => {
                  if (reviewsList.length < 3) {
                    reviewsList.push({
                      productName: product.name,
                      productId: index,
                      productReview: product.reviews[0],
                    });
                  }
                  return (
                    <SwiperSlide key={index} style={{ width: "auto" }}>
                      <ProductCardSmaller
                        key={index}
                        isCategory
                        // isFirst={index === 0}
                        product={product}
                        hideBoughtBy
                        isMobile={isMobile}
                      />
                    </SwiperSlide>
                  );
                })}
              </SwiperContainer>
              {!isMobile && (
                <CustomNavButtonForward
                  className="swiper-button-next"
                  style={{ fontSize: "1vw" }}
                />
              )}
            </Row>

            {isMobile && (
              <Row
                style={{
                  width: "95%",
                  justifyContent: "space-between",
                  padding: "5px 0px",
                  margin: "0px auto 0 auto",
                }}
              >
                <CustomNavButtonBack
                  className="swiper-button-prev"
                  style={{ fontSize: "4vw", cursor: "auto", marginTop: "0px" }}
                />

                <CustomNavButtonForwardAnimated
                  className="swiper-button-next"
                  style={{ fontSize: "4vw", cursor: "auto", marginTop: "0px" }}
                />
              </Row>
            )}
          </Parallax>

          <ReviewList>
            {reviewsList.length == 3 &&
              reviewsList.map((review, index) => (
                <ReviewsCardDiv
                  onClick={() => handleReviewClick(review.productId)}
                  includeProductName={true}
                  key={index}
                  stars={review.productReview.rating}
                  imageSrc={review.productReview.imageUrl}
                  name={review.productReview.name}
                  productName={review.productName}
                  location={review.productReview.location}
                  date={review.productReview.createAt}
                  rating={review.productReview.rating}
                  review={review.productReview.review}
                />
              ))}
          </ReviewList>

          <ExplorAllText>
            Explore {Title}{" "}
            <ArrowForwardOutlinedIcon
              style={{
                fontSize: isMobile ? "4.5vw" : "1.1vw",
                marginLeft: "7px",
              }}
            />
          </ExplorAllText>
        </div>
      </Container>
    )
  );
}

export default ProductListDiv;

{
  /* 
  
Old Products

  <ProductCardSmaller
       isFirst
       hideBoughtBy
       imageSize={"90%"}
       imageSrc={
         "https://nathabit.in/_nat/images/updated_henna_collection_390f1ccad2.jpg?format=auto&width=640&quality=75"
       }
       rating={"4.53"}
       reviews={"400"}
       name={"Beetroot Lip Balm"}
       featureText={"Hydrate | Smile | Repeat"}
       price={"350.00"}
     />
     <ProductCardSmaller
       imageSrc={
         "https://nathabit.in/_nat/images/Nutri_Mask_4_8bacf48830.jpg?format=auto&width=640&quality=75"
       }
       hideBoughtBy
       rating={"4.74"}
       imageSize={"90%"}
       reviews={"400"}
       name={"Charcoal Bath Bar"}
       featureText={"Hydrate | Smile | Repeat"}
       price={"410.00"}
     />
     <ProductCardSmaller
       imageSrc={
         "https://nathabit.in/_nat/images/Collection_Image_Upload_9f2edb8585.jpg?format=auto&width=640&quality=75"
       }
       hideBoughtBy
       rating={"4.8"}
       imageSize={"90%"}
       reviews={"400"}
       name={"Aloe Vera Gel"}
       featureText={"Hydrate | Smile | Repeat"}
       price={"390.00"}
     />
     <ProductCardSmaller
       imageSrc={
         "https://nathabit.in/_nat/images/hair_oil_collection_2f63f7d825.jpg?format=auto&width=640&quality=75"
       }
       hideBoughtBy
       rating={"4.8"}
       reviews={"400"}
       imageSize={"90%"}
       name={"Aloe Vera Gel"}
       featureText={"Hydrate | Smile | Repeat"}
       price={"390.00"}
     />
     <ProductCardSmaller
       imageSrc={
         "https://nathabit.in/_nat/images/Nutri_Mask_4_8bacf48830.jpg?format=auto&width=640&quality=75"
       }
       hideBoughtBy
       rating={"4.74"}
       imageSize={"90%"}
       reviews={"400"}
       name={"Charcoal Bath Bar"}
       featureText={"Hydrate | Smile | Repeat"}
       price={"410.00"}
     />
     <ProductCardSmaller
       imageSrc={
         "https://nathabit.in/_nat/images/hair_oil_collection_2f63f7d825.jpg?format=auto&width=640&quality=75"
       }
       hideBoughtBy
       rating={"4.8"}
       imageSize={"90%"}
       reviews={"400"}
       name={"Aloe Vera Gel"}
       featureText={"Hydrate | Smile | Repeat"}
       price={"390.00"}
     /> */
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => (props.isFirst ? "40px" : "30px")};
  align-items: ${(props) => (props.isMobile ? "auto" : "center")};
  justify-content: ${(props) => (props.isMobile ? "auto" : "center")};
  /* background-color: #f7fffc; */

  @media (max-width: 768px) {
    margin-top: ${(props) => (props.isFirst ? "-10px" : "60px")};
  }
`;

const Heading = styled.h1`
  font-size: 1.5vw;
  font-weight: 600;

  @media (max-width: 768px) {
    /* margin-left: 7.5vw; */
    font-size: 5.3vw;
    /* text-align: start; */
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: flex-start;
  margin-top: 10px;
  padding: ${(props) => (props.isMobile ? "10px 10px" : " 30px 30px")};
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    display: none; /* Hide the scrollbar for WebKit browsers */
  }

  & {
    -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
    scrollbar-width: none; /* Hide scrollbar for Firefox */
  }

  @media (max-width: 768px) {
    flex-wrap: nowrap;
  }
  /* background-color: #F5F6FF; */
`;

const Text = styled.p`
  margin: 5px 0px 5px 0px;
  font-weight: 400;
  font-size: ${(props) => (props.isMobile ? "2.9vw" : " 13px")};
  color: #474747;
  cursor: pointer;
`;

const CategoryCoverDiv = styled.div`
  width: ${(props) => (props.isMobile ? "20vw" : "3.6vw")};
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  position: relative;
  margin-right: ${(props) => (props.isMobile ? "15px" : "40px")};
  flex-shrink: 0;

  &:hover > div {
    display: block;
  }
`;

const CategoryCoverImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 50%;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

const ReviewList = styled.div`
  display: grid;
  width: 85%;
  margin: 0px auto;
  grid-template-columns: repeat(3, 1fr);
  gap: 35px;
  margin-top: 20px;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    width: 95%;
  }
`;

const ExplorAllText = styled.div`
  margin: 7vh auto;
  font-weight: 600;
  font-size: 0.9vw;
  display: flex;
  align-items: center;
  color: #3c0d0d;

  @media (max-width: 768px) {
    font-size: 3.5vw;
    margin: 5vh auto;
  }
`;

import React, { useState } from "react";
import styled from "styled-components";
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from "@mui/icons-material";

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAnswer = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "How can I track my order?",
      answer:
        "You can track your order using the tracking number provided in your confirmation email. Simply enter it on our tracking page to get the latest status of your shipment.",
    },
    {
      question: "What payment methods do you accept?",
      answer:
        "We accept various payment methods including credit/debit cards, PayPal, and bank transfers. You can select your preferred method at checkout.",
    },
    {
      question: "Can I modify or cancel my order after placing it?",
      answer:
        "Once an order is placed, it cannot be modified or canceled. However, you can contact our customer support team immediately if you need assistance with your order.",
    },
    {
      question: "What is your return policy?",
      answer:
        "We offer a 30-day return policy for most products. Items must be returned in their original condition and packaging. Please refer to our returns page for more details.",
    },
    {
      question: "How do I apply a discount code?",
      answer:
        "During checkout, you will find a field labeled 'Discount Code.' Enter your code there and click 'Apply' to see the discount applied to your total amount.",
    },
    {
      question: "How long does delivery take?",
      answer:
        "Delivery times vary based on your location and the shipping method selected. Typically, orders are delivered within 5-7 business days.",
    },
    {
      question: "Can I change the shipping address after placing my order?",
      answer:
        "If your order has not yet been processed, you may be able to change the shipping address by contacting our support team. Once processed, changes cannot be made.",
    },
    {
      question: "What should I do if I receive a damaged item?",
      answer:
        "If you receive a damaged item, please contact our customer support team within 48 hours of delivery with photos of the damage. We will arrange for a replacement or refund.",
    },
    {
      question: "Do you ship internationally?",
      answer:
        "Yes, we ship to many countries around the world. Shipping costs and delivery times will be calculated based on your location at checkout.",
    },
    {
      question: "How can I contact customer support?",
      answer:
        "You can reach our customer support team via email, phone, or live chat. Our contact details are available on our 'Contact Us' page.",
    },
  ];

  return (
    <FAQContainer>
      <FAQTitle>Frequently Asked Questions</FAQTitle>
      {faqs.map((faq, index) => (
        <FAQItem key={index}>
          <Question onClick={() => toggleAnswer(index)}>
            {faq.question}
            {openIndex === index ? (
              <ExpandLessIconStyled />
            ) : (
              <ExpandMoreIconStyled />
            )}
          </Question>
          <Answer isOpen={openIndex === index}>{faq.answer}</Answer>
        </FAQItem>
      ))}
    </FAQContainer>
  );
};

export default FAQ;

const FAQContainer = styled.div`
  max-width: 800px;
  margin: 0 auto 70px auto;
  padding: 20px;
`;

const FAQTitle = styled.h2`
  text-align: center;
  margin-bottom: 20px;

  font-size: 0.9vw;
  @media (max-width: 768px) {
    font-size: 4vw;
  }
`;

const FAQItem = styled.div`
  border-bottom: 1px solid #ddd;
  padding: 15px 0;
`;

const Question = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 0.8vw;
  text-align: start;

  @media (max-width: 768px) {
    font-size: 3.6vw;
  }
`;

const Answer = styled.div`
  max-height: ${(props) => (props.isOpen ? "200px" : "0")};
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  padding: ${(props) => (props.isOpen ? "10px 0" : "0")};
  color: #555;
  font-weight: bold;
  text-align: start;
  font-size: 0.8vw;

  @media (max-width: 768px) {
    font-size: 3.8vw;
  }
`;

const ExpandMoreIconStyled = styled(ExpandMoreIcon)`
  transition: transform 0.3s ease;
`;

const ExpandLessIconStyled = styled(ExpandLessIcon)`
  transition: transform 0.3s ease;
  transform: rotate(180deg);
`;

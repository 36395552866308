import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyCi3SWKxCYJcEjo2weeSLguiKCUookeOhk",
  authDomain: "the-prime-provisions.firebaseapp.com",
  projectId: "the-prime-provisions",
  storageBucket: "the-prime-provisions.appspot.com",
  messagingSenderId: "724553608278",
  appId: "1:724553608278:web:42aa8d37302ca626ff9ff7",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore(app);

export { firestore };
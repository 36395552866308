export function get24CurrentFormattedDateTime() {
  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is zero-based
  const day = String(currentDate.getDate()).padStart(2, "0");

  const hours = String(currentDate.getHours()).padStart(2, "0");
  const minutes = String(currentDate.getMinutes()).padStart(2, "0");
  const seconds = String(currentDate.getSeconds()).padStart(2, "0");

  const period = hours >= 12 ? "PM" : "AM";
  // const formattedHours = hours > 12 ? hours - 12 : hours;
  const formattedTime = `${hours}:${minutes}:${seconds} ${period}`;

  return `D-T: ${year}-${month}-${day} ${formattedTime}`;
}

import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { useCart } from "../../../Cart/components/CartContext";
import { renderStars } from "../../../routes/products/components/Reviews";
import { useMediaQuery } from "@mui/material";

function ProductCardHorizontal({ isFirst, product }) {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:768px)");

  const { dispatch } = useCart();

  const addToCart = () => {
    dispatch({
      type: "ADD_ITEM",
      payload: { productId: product.id && product.id },
    });
  };

  return (
    <MainDiv
      style={{
        marginTop: isMobile && isFirst ? "0px" : "5px",
        marginLeft: "0px",
        padding: isMobile ? "15px 5px 7px 5px" : "15px 10px 7px 10px",
      }}
      onClick={() => {
        navigate(`/products/${product.id}/`, {
          state: { product: product && product },
        });
      }}
    >
      {/* {title ? <Title>❝ {title} ❞</Title> : null} */}

      <Row
        style={{
          padding: "0px 5px",
          alignItems: "start",
        }}
      >
        <Column style={{ width: isMobile ? "44%" : "38%" }}>
          <Image
            width={"100%"}
            src={product.imageUrls.ShowCaseURL && product.imageUrls.ShowCaseURL}
            alt={product.name + " Image"}
          />
          <Row
            style={{
              margin: isMobile ? "15px 0px 10px 0px" : "20px 0px 10px 0px",
              width: "90%",
              alignSelf: "center",
              alignItems: "center",
            }}
          >
            <FavoriteBorderOutlinedIcon
              style={{
                fontSize: isMobile ? "5.6vw" : "1.8vw",
                color: "#ff4646",
              }}
            />
            <p
              style={{
                margin: 0,
                marginLeft: "2px",
                fontSize: isMobile ? "3.1vw" : "0.8vw",
              }}
            >
              {product.totalUnitsSold && product.totalUnitsSold}
            </p>
            <ChatBubbleOutlineOutlinedIcon
              style={{
                fontSize: isMobile ? "4.6vw" : "1.6vw",
                marginLeft: isMobile ? "15px" : "30px",
              }}
            />{" "}
            <p
              style={{
                margin: 0,
                marginLeft: "3px",
                fontSize: isMobile ? "3.1vw" : "0.8vw",
              }}
            >
              {product.reviews && product.reviews.length}
            </p>
            {/* <ShareOutlinedIcon style={{ fontSize: "4.7vw", marginLeft:"10px" }} /> */}
          </Row>
        </Column>

        <Column
          style={{
            width: isMobile ? "55%" : "62%",
            marginLeft: !isMobile && "20px",
          }}
        >
          <Name>{product.name && product.name}</Name>
          <BestInText>#1 in {product.category && product.category}</BestInText>
          <Description
            style={{ display: "flex", marginTop: "10px", fontWeight: "600" }}
          >
            <Stars>{renderStars(product.rating ? product.rating : 4)}</Stars>{" "}
            {product.totalUnitsSold / product.reviews.length}
          </Description>

          <Price>
            <Price
              style={{
                margin: 0,
                marginRight: "5px",
                fontSize: isMobile ? "3.5vw" : "0.9vw",
                fontWeight: "400",
                textDecoration: "line-through",
              }}
            >
              {" "}
              ₹{product.prices["ComparedShowCase Price"]}{" "}
            </Price>
            ₹{product.prices.SellingPrice}
          </Price>

          <Description>{product.description["1textBlock"]}</Description>

          {/* <Description>
            ★★★★☆ , 20,101 <br />
            <br />
            {description}
          </Description> */}

          <ViewButton
            style={{
              backgroundColor: product.isInStock ? "#045df6" : "#737373",
              boxShadow: !product.isInStock && "none",
            }}
            onClick={(e) => {
              e.stopPropagation();
              if (product.isInStock) {
                addToCart();
              }
            }}
          >
            {product.isInStock ? "Add To Cart" : "Out Of Stock"}
          </ViewButton>
        </Column>
      </Row>
    </MainDiv>
  );
}

export default ProductCardHorizontal;

const MainDiv = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  background-color: white;
  border: 0.5px solid #eae8e8;
  border-radius: 5px;
  padding-bottom: 2px;
  cursor: pointer;
  transition: box-shadow 0.5s ease;

  @media (max-width: 768px) {
    width: 97%;
    cursor: auto;
  }
  /* &:hover {
    box-shadow: 0px 4px 30px 0px #4881ba;
  } */
`;

const Row = styled.div`
  display: flex;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
`;

const Image = styled.img`
  /* width: 35%; */
  height: auto;
  object-fit: cover;
  aspect-ratio: 1;
`;

const Name = styled.h1`
  width: ${(props) => (props.isCategory ? "12.5vw" : "15vw")};
  text-align: start;
  align-self: flex-start;
  font-size: 1vw;
  font-weight: 600;
  margin: 5px 10px 0px 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 768px) {
    width: 50vw;
    font-size: 4.8vw;
  }
`;

const Description = styled.p`
  align-self: flex-start;
  text-align: start;
  max-width: 100%;
  font-size: 0.78vw;
  font-weight: 400;
  margin: 5px 10px 0px 20px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 2.9vw;
  }
`;

const BestInText = styled.p`
  background-color: #b83089;
  text-align: start;
  padding: 3px 10px 5px 10px;
  color: white;
  font-size: 0.65vw;
  margin: 5px 10px 0px 20px;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 768px) {
    font-size: 2.6vw;
  }
`;

const Price = styled.h1`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1vw;
  font-weight: 600;
  margin: 0px 10px 0px 20px;

  @media (max-width: 768px) {
    font-size: 4.7vw;
  }
`;

const ComparePrice = styled.span`
  position: relative;
  color: #999; /* Adjust color as needed */
  text-decoration: none;
  margin-right: 5px; /* Adjust spacing */
`;

const Stars = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.9vw;
  margin-right: 10px;

  svg {
    color: #ffa41c;
    margin-right: 3px;
  }

  @media (max-width: 768px) {
    font-size: 3.4vw;
    margin-right: 5px;
  }
`;

const ViewButton = styled.button`
  width: 69%;
  border: none;
  border-radius: 50px;
  font-size: 0.9vw;
  padding: 16px 8px;
  margin: 12px 10px;
  /* background-color: #045df6; */
  background-color: #045df6;
  color: white;
  box-shadow: 0px 4px 20px 0px rgb(142, 183, 253, 0.6);
  cursor: pointer;
  &:hover {
    background-color: #037562;
  }

  @media (max-width: 768px) {
    font-size: 3.6vw;
    width: 88%;
    cursor: auto;
    padding: 12px 8px;
    margin: 12px 10px;
  }
`;

import React from "react";
import styled from "styled-components";

function ProductTable({ tableData }) {
  return (
    <Container>
      {/* <NameText> Product Specification :</NameText> */}
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>Feature</TableHeader>
            <TableHeader>Details</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.column1}</TableCell>
              <TableCell>{row.column2}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
}

export default ProductTable;

const Container = styled.div`
  margin: 40px auto 30px auto;
  width: 100%;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0px auto 30px auto;
  }
`;

const NameText = styled.h1`
  margin: 20px 0px 12px 0px;
  font-weight: 500;
  text-align: start;
  font-size: .85vw;
  font-weight: 700;

  @media (max-width: 767px) {
    text-align: center;
    font-size: 5vw;
    margin: 0px 0px 10px 0px;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
  font-size: 1em;
  font-family: Arial, sans-serif;
  border: 1px solid #ddd;
`;

const TableHead = styled.thead`
  background-color: #f2f2f2;
`;

const TableBody = styled.tbody`
  & tr:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const TableRow = styled.tr`
  border-bottom: 1px solid #ddd;
  text-align: left;
`;

const TableHeader = styled.th`
  padding: 10px;
  text-align: left;
`;

const TableCell = styled.td`
  padding: 10px;
  font-size: 0.8vw;

  @media (max-width: 767px) {
    font-size: 3.65vw;
  }
`;

import React, { useState } from "react";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Collapse } from "@mui/material";
import { useNavigate } from "react-router-dom";

function HeaderNavigation({
  isHeaderNavigationOpen,
  toggleHeaderNavigation,
  toggleCart,
  products,
}) {
  const [activeIndex, setActiveIndex] = useState(null);
  const navigate = useNavigate();

  const toggleSubcategories = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <HeaderContainer isOpen={isHeaderNavigationOpen}>
      <Row style={{ justifyContent: "space-between" }}>
        <Text>
          Welcome
          <br />
          <span
            style={{
              fontWeight: "400",
              fontSize: "14px",
              textDecoration: "underline",
              marginTop: "5px",
            }}
            onClick={() => {
              toggleHeaderNavigation();
              navigate("/account/login");
            }}
          >
            Login
          </span>
          <span
            style={{
              fontWeight: "400",
              marginLeft: "5px",
              fontSize: "14px",
              marginTop: "5px",
              textDecoration: "auto",
            }}
          >
            or
          </span>
          <span
            style={{
              fontWeight: "400",
              marginLeft: "5px",
              fontSize: "14px",
              textDecoration: "underline",
              marginTop: "5px",
            }}
            onClick={() => {
              toggleHeaderNavigation();

              navigate("/account/register");
            }}
          >
            Create Account
          </span>
        </Text>
        <CloseIcon
          onClick={toggleHeaderNavigation}
          style={{ cursor: "pointer" }}
        />
      </Row>{" "}
      <NavContainer>
        <NavItem
          onClick={() => {
            toggleHeaderNavigation();
            navigate("/collections/Best Sellers/all");
          }}
        >
          <NavText>Best Sellers</NavText>
        </NavItem>
        <NavItem
          onClick={() => {
            toggleHeaderNavigation();
            navigate("/collections/New/all");
          }}
        >
          <NavText>New Launches</NavText>
        </NavItem>
        <NavItem onClick={() => toggleSubcategories(0)}>
          <NavText>Home Utilities</NavText>
          <IconContainer isExpanded={activeIndex === 0}>
            <IconWrapper isExpanded={activeIndex === 0}>
              {activeIndex === 0 ? <RemoveIcon /> : <AddIcon />}
            </IconWrapper>
          </IconContainer>
        </NavItem>
        <Collapse in={activeIndex === 0} timeout="auto" unmountOnExit>
          <Subcategories>
            <SubcategoryItem
              onClick={() => {
                toggleHeaderNavigation();
                navigate(
                  `/products/home-automatic-door-closer-punch-free-closer-with-steel-drawstring-d49f-2d90-15f6-de77/`,
                  {
                    state: {
                      product:
                        products[
                          "home-automatic-door-closer-punch-free-closer-with-steel-drawstring-d49f-2d90-15f6-de77"
                        ],
                    },
                  }
                );
              }}
            >
              Automatic Door Closer
            </SubcategoryItem>
            <SubcategoryItem
              onClick={() => {
                toggleHeaderNavigation();
                navigate(
                  `/products/home-adhesive-hooks-for-wall-hanging-objects-without-drilling-transparent-6c24-dd52-3e6a-f897/`,
                  {
                    state: {
                      product:
                        products[
                          "home-adhesive-hooks-for-wall-hanging-objects-without-drilling-transparent-6c24-dd52-3e6a-f897"
                        ],
                    },
                  }
                );
              }}
            >
              Wall Hooks
            </SubcategoryItem>
            <SubcategoryItem
              onClick={() => {
                toggleHeaderNavigation();
                navigate(
                  `/products/home-heavy-furniture-roller-lifter-and-mover-tool-set-e124-f41f-c648-dcb9/`,
                  {
                    state: {
                      product:
                        products[
                          "home-heavy-furniture-roller-lifter-and-mover-tool-set-e124-f41f-c648-dcb9"
                        ],
                    },
                  }
                );
              }}
            >
              Heavy Furniture Roller
            </SubcategoryItem>
            <SubcategoryItem
              onClick={() => {
                toggleHeaderNavigation();
                navigate(
                  `/products/home-motion-sensor-light-wireless-12-inch-magnet-aluminum-rechargeable-cabinet-light-with-stepless-dimming-b98d-4e90-45c7-0a1f`,
                  {
                    state: {
                      product:
                        products[
                          "home-motion-sensor-light-wireless-12-inch-magnet-aluminum-rechargeable-cabinet-light-with-stepless-dimming-b98d-4e90-45c7-0a1f"
                        ],
                    },
                  }
                );
              }}
            >
              Motion Sensor Lights
            </SubcategoryItem>
            <SubcategoryItem
              onClick={() => {
                toggleHeaderNavigation();
                navigate(
                  `/products/home-wd-40-rust-remover-multipurpose-spray-lubricant-cleans-bike-chains-squeak-noise-remover-stain-remover-and-cleaning-agent-2ae1-2a89-2e36-f71d`,
                  {
                    state: {
                      product:
                        products[
                          "home-wd-40-rust-remover-multipurpose-spray-lubricant-cleans-bike-chains-squeak-noise-remover-stain-remover-and-cleaning-agent-2ae1-2a89-2e36-f71d"
                        ],
                    },
                  }
                );
              }}
            >
              Rust Remover Spray
            </SubcategoryItem>
            <SubcategoryItem
              onClick={() => {
                toggleHeaderNavigation();
                navigate(
                  `/products/home-6-layer-foldable-shoe-rack-flexible-and-durable-shoe-shelf-space-saving-organizer-for-closet-hallway-bedroom-and-washroom-c01a-b7aa-0a4c-dd64`,
                  {
                    state: {
                      product:
                        products[
                          "home-6-layer-foldable-shoe-rack-flexible-and-durable-shoe-shelf-space-saving-organizer-for-closet-hallway-bedroom-and-washroom-c01a-b7aa-0a4c-dd64"
                        ],
                    },
                  }
                );
              }}
            >
              Foldable Racks For Shoes
            </SubcategoryItem>
            <SubcategoryItem
              onClick={() => {
                toggleHeaderNavigation();
                navigate(
                  `/products/technology-wireless-door-bell-for-home-operating-at-1000ft300m-ip44-weatherproof-button-mute-mode60-melodies5-volumes-with-led-indicator-wireless-calling-bell-for-home-office-shops-d699-6485-c73d-b3cd/`,
                  {
                    state: {
                      product:
                        products[
                          "technology-wireless-door-bell-for-home-operating-at-1000ft300m-ip44-weatherproof-button-mute-mode60-melodies5-volumes-with-led-indicator-wireless-calling-bell-for-home-office-shops-d699-6485-c73d-b3cd"
                        ],
                    },
                  }
                );
              }}
            >
              Wireless Doorbell
            </SubcategoryItem>
          </Subcategories>
        </Collapse>
        <NavItem onClick={() => toggleSubcategories(1)}>
          <NavText>Kitchen Accessories</NavText>
          <IconContainer isExpanded={activeIndex === 1}>
            <IconWrapper isExpanded={activeIndex === 1}>
              {activeIndex === 1 ? <RemoveIcon /> : <AddIcon />}
            </IconWrapper>
          </IconContainer>
        </NavItem>
        <Collapse in={activeIndex === 1} timeout="auto" unmountOnExit>
          <Subcategories>
            <SubcategoryItem
              onClick={() => {
                toggleHeaderNavigation();
                navigate(
                  `/products/kitchen-360-rotating-organizer-tray-kitchen-organizer-multi-function-rotating-tray-cosmetics-organizer-e65e-42b8-6d8d-ee85`,
                  {
                    state: {
                      product:
                        products[
                          "kitchen-360-rotating-organizer-tray-kitchen-organizer-multi-function-rotating-tray-cosmetics-organizer-e65e-42b8-6d8d-ee85"
                        ],
                    },
                  }
                );
              }}
            >
              Utensil Organizer Tray
            </SubcategoryItem>
          </Subcategories>
        </Collapse>
        <NavItem onClick={() => toggleSubcategories(2)}>
          <NavText>Bathroom</NavText>
          <IconContainer isExpanded={activeIndex === 2}>
            <IconWrapper isExpanded={activeIndex === 2}>
              {activeIndex === 2 ? <RemoveIcon /> : <AddIcon />}
            </IconWrapper>
          </IconContainer>
        </NavItem>
        <Collapse in={activeIndex === 2} timeout="auto" unmountOnExit>
          <Subcategories>
            <SubcategoryItem
              onClick={() => {
                toggleHeaderNavigation();
                navigate(
                  `/products/bathroom-foldable-washing-machine-0eb4-071a-30d1-6fd9`,
                  {
                    state: {
                      product:
                        products[
                          "bathroom-foldable-washing-machine-0eb4-071a-30d1-6fd9"
                        ],
                    },
                  }
                );
              }}
            >
              Foldable Washing Machine
            </SubcategoryItem>
            <SubcategoryItem
              onClick={() => {
                toggleHeaderNavigation();
                navigate(
                  `/products/bathroom-1080-degree-rotatable-faucet-aerator-multifunctional-robotic-arm-water-nozzle-d575-76eb-1f1c-5d14`,
                  {
                    state: {
                      product:
                        products[
                          "bathroom-1080-degree-rotatable-faucet-aerator-multifunctional-robotic-arm-water-nozzle-d575-76eb-1f1c-5d14"
                        ],
                    },
                  }
                );
              }}
            >
              1080 Faucet Water Diverter
            </SubcategoryItem>
            <SubcategoryItem
              onClick={() => {
                toggleHeaderNavigation();
                navigate(
                  `/products/home-unbreakable-oval-shape-foldable-adhesive-sticky-mirror-fd7e-6751-4055-0506`,
                  {
                    state: {
                      product:
                        products[
                          "home-unbreakable-oval-shape-foldable-adhesive-sticky-mirror-fd7e-6751-4055-0506"
                        ],
                    },
                  }
                );
              }}
            >
              Sticky Oval Adhresive Mirror
            </SubcategoryItem>
          </Subcategories>
        </Collapse>
        <NavItem onClick={() => toggleSubcategories(3)}>
          <NavText>Tech Stuff</NavText>
          <IconContainer isExpanded={activeIndex === 3}>
            <IconWrapper isExpanded={activeIndex === 3}>
              {activeIndex === 3 ? <RemoveIcon /> : <AddIcon />}
            </IconWrapper>
          </IconContainer>
        </NavItem>
        <Collapse in={activeIndex === 3} timeout="auto" unmountOnExit>
          <Subcategories>
            <SubcategoryItem
              onClick={() => {
                toggleHeaderNavigation();
                navigate(
                  `/products/technology-keychain-led-light-ultra-bright-usb-rechargeable-keychain-with-bottle-opener-and-folding-bracket-64c1-8f19-c944-85eb`,
                  {
                    state: {
                      product:
                        products[
                          "technology-keychain-led-light-ultra-bright-usb-rechargeable-keychain-with-bottle-opener-and-folding-bracket-64c1-8f19-c944-85eb"
                        ],
                    },
                  }
                );
              }}
            >
              Key Chain LED Light
            </SubcategoryItem>
            <SubcategoryItem
              onClick={() => {
                toggleHeaderNavigation();
                navigate(
                  `/products/home-foldable-converter-3-in-1-rotatable-outlet-extender-b672-2413-e22f-f22d`,
                  {
                    state: {
                      product:
                        products[
                          "home-foldable-converter-3-in-1-rotatable-outlet-extender-b672-2413-e22f-f22d"
                        ],
                    },
                  }
                );
              }}
            >
              3 in 1 Outlet Extender
            </SubcategoryItem>
            <SubcategoryItem
              onClick={() => {
                toggleHeaderNavigation();
                navigate(
                  `/products/home-motion-sensor-light-wireless-12-inch-magnet-aluminum-rechargeable-cabinet-light-with-stepless-dimming-b98d-4e90-45c7-0a1f`,
                  {
                    state: {
                      product:
                        products[
                          "home-motion-sensor-light-wireless-12-inch-magnet-aluminum-rechargeable-cabinet-light-with-stepless-dimming-b98d-4e90-45c7-0a1f"
                        ],
                    },
                  }
                );
              }}
            >
              Motion Sensor Lights
            </SubcategoryItem>
            <SubcategoryItem
              onClick={() => {
                toggleHeaderNavigation();
                navigate(
                  `/products/technology-nova-original-lint-remover-for-clothes-fabric-shaver-tint-and-dust-remover-cdf0-8b95-3fc7-0b96`,
                  {
                    state: {
                      product:
                        products[
                          "technology-nova-original-lint-remover-for-clothes-fabric-shaver-tint-and-dust-remover-cdf0-8b95-3fc7-0b96"
                        ],
                    },
                  }
                );
              }}
            >
              Nova Lint Remover
            </SubcategoryItem>
            <SubcategoryItem
              onClick={() => {
                toggleHeaderNavigation();
                navigate(
                  `/products/technology-wireless-door-bell-for-home-operating-at-1000ft300m-ip44-weatherproof-button-mute-mode60-melodies5-volumes-with-led-indicator-wireless-calling-bell-for-home-office-shops-d699-6485-c73d-b3cd/`,
                  {
                    state: {
                      product:
                        products[
                          "technology-wireless-door-bell-for-home-operating-at-1000ft300m-ip44-weatherproof-button-mute-mode60-melodies5-volumes-with-led-indicator-wireless-calling-bell-for-home-office-shops-d699-6485-c73d-b3cd"
                        ],
                    },
                  }
                );
              }}
            >
              Wireless Doorbell
            </SubcategoryItem>
          </Subcategories>
        </Collapse>
        <NavItem onClick={() => toggleSubcategories(4)}>
          <NavText>Shop By Concern</NavText>
          <IconContainer isExpanded={activeIndex === 4}>
            <IconWrapper isExpanded={activeIndex === 4}>
              {activeIndex === 4 ? <RemoveIcon /> : <AddIcon />}
            </IconWrapper>
          </IconContainer>
        </NavItem>
        <Collapse in={activeIndex === 4} timeout="auto" unmountOnExit>
          <Subcategories>
            <SubcategoryItem
              onClick={() => {
                toggleHeaderNavigation();
                navigate("/collections/Home Decor/all");
              }}
            >
              Home Decor
            </SubcategoryItem>
            <SubcategoryItem
              onClick={() => {
                toggleHeaderNavigation();
                navigate("/collections/Organizer/all");
              }}
            >
              Organizers
            </SubcategoryItem>
            <SubcategoryItem
              onClick={() => {
                toggleHeaderNavigation();
                navigate("/collections/Space Saving/all");
              }}
            >
              Space Savers
            </SubcategoryItem>
            <SubcategoryItem
              onClick={() => {
                toggleHeaderNavigation();
                navigate("/collections/Foldable/all");
              }}
            >
              Foldable Hacks
            </SubcategoryItem>
          </Subcategories>
        </Collapse>
        <OtherCatergoryText
          style={{ marginTop: "25px" }}
          onClick={() => {
            toggleHeaderNavigation();
            navigate("/track-my-order");
          }}
        >
          Track Order
        </OtherCatergoryText>
        <OtherCatergoryText
          onClick={() => {
            toggleHeaderNavigation();
            navigate("/cart");
          }}
        >
          My Cart
        </OtherCatergoryText>
        {/* <OtherCatergoryText
          onClick={() => {
            toggleHeaderNavigation();
            navigate("/account/track-order");
          }}
        >
          About Us
        </OtherCatergoryText> */}
        <NavItem
          onClick={() => toggleSubcategories(5)}
          style={{ padding: 0, borderBottom: "none" }}
        >
          <OtherCatergoryText>Tech Stuff</OtherCatergoryText>
          <IconContainer isExpanded={activeIndex === 5}>
            <IconWrapper isExpanded={activeIndex === 5}>
              {activeIndex === 5 ? <RemoveIcon /> : <AddIcon />}
            </IconWrapper>
          </IconContainer>
        </NavItem>
        <Collapse in={activeIndex === 5} timeout="auto" unmountOnExit>
          <Subcategories>
            <SubcategoryItem
              onClick={() => {
                toggleHeaderNavigation();
                navigate("/policy/terms");
              }}
            >
              Terms and Conditions
            </SubcategoryItem>
            <SubcategoryItem
              onClick={() => {
                toggleHeaderNavigation();
                navigate("/policy/privacy");
              }}
            >
              Privacy Policy
            </SubcategoryItem>
            <SubcategoryItem
              onClick={() => {
                toggleHeaderNavigation();
                navigate("/policy/refund");
              }}
            >
              Refund and Cancellation
            </SubcategoryItem>
            <SubcategoryItem
              onClick={() => {
                toggleHeaderNavigation();
                navigate("/policy/shipping");
              }}
            >
              Shipping and Delivery
            </SubcategoryItem>
            <SubcategoryItem
              onClick={() => {
                toggleHeaderNavigation();
                navigate("/policy/contact_us");
              }}
            >
              Contact Us
            </SubcategoryItem>
          </Subcategories>
        </Collapse>
       
        <OtherCatergoryText
          onClick={() => {
            toggleHeaderNavigation();
            navigate("/search");
          }}
        >
          Search
        </OtherCatergoryText>
      </NavContainer>
      {/* <CategoriesContainer>
        <CategoriesText>Home</CategoriesText>
        <Seprator />
        <CategoriesText>Best Sellers</CategoriesText>
        <Seprator />
        <CategoriesText>Shop</CategoriesText>
        <Seprator />
        <CategoriesText>About</CategoriesText>
        <Seprator />
        <CategoriesText>Contact</CategoriesText>
        <Seprator />
        <CategoriesText>Blog</CategoriesText>
        <Seprator />
        <CategoriesText>Ingredient</CategoriesText>
        <Seprator />
        <CategoriesText>Rewards</CategoriesText>
      </CategoriesContainer> */}
    </HeaderContainer>
  );
}

export default HeaderNavigation;

const HeaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: ${(props) => (props.isOpen ? "0" : "-100%")};
  width: 90%;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transition: left 0.4s ease-in;
  z-index: 1003;
  overflow-y: auto;
  padding: 15px 0px;

  /* &::-webkit-scrollbar {
    display: none;
  }

  & {
    -ms-overflow-style: none;
    scrollbar-width: none;
  } */
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin: 0px 25px;

  &::-webkit-scrollbar {
    display: none; /* Hide the scrollbar for WebKit browsers */
  }

  & {
    -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
    scrollbar-width: none; /* Hide scrollbar for Firefox */
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const Seprator = styled.div`
  width: 100%;
  height: 1px;
  background-color: #d7dad5;
  margin: 20px 0px;
`;

const Text = styled.p`
  color: black;
  font-weight: 600;
  margin: 0;
  font-size: 15px;
  text-align: start;
`;

const CategoriesContainer = styled.div`
  width: 90%;
  margin: 40px auto;
`;

const CategoriesText = styled.p`
  color: black;
  font-weight: 500;
  margin: 0;
  font-size: 17px;
  text-align: start;
`;

const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  padding: 0px 10px;
  margin-bottom: 100px;
`;

const NavItem = styled.div`
  position: relative;
  padding: 18px 10px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: color 0.5s ease;

  &:hover {
    color: #024102;
  }
`;

const NavText = styled.div`
  font-weight: 600;
  font-size: 5vw;
`;

const OtherCatergoryText = styled.p`
  font-weight: 400;
  font-size: 4vw;
  text-align: start;
  margin-left: 10px;
  margin-top: 18px;
  cursor: pointer;

  &:hover {
    color: #024102;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  transition: transform 0.3s ease;
  transform: ${(props) =>
    props.isExpanded ? "rotate(0deg)" : "rotate(-180deg)"};
`;

const Subcategories = styled.div`
  text-align: start;
  padding-left: 20px;
  margin-top: 5px;
  /* padding: 20px 10px; */
`;

const SubcategoryItem = styled.div`
  padding: 10px;
  cursor: pointer;
  text-align: start;
  transition: color 0.5s ease;
  &:hover {
    color: #056005;
  }
`;

import React from "react";
import styled from "styled-components";

function TrackOrder() {
  return (
    <Container>
      <Heading>Track Your Order</Heading>

      <TextInput placeholder="Enter Email Address" />

      <TextInput style={{ marginTop: "20px" }} placeholder="Enter Order ID" />

      <ContinueButton
        onClick={() => {
          alert("No Gateway Detected");
        }}
      >
        SUBMIT
      </ContinueButton>
    </Container>
  );
}

export default TrackOrder;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Heading = styled.h1`
  margin-top: 100px;
  letter-spacing: 0.03ch;
  margin-bottom: 30px;
  border-bottom: 1px solid black;

  @media (max-width: 768px) {
    font-size: 5.5vw;
    margin-top: 80px;
  }
`;

const TextInput = styled.input`
  width: 23vw;
  height: auto;
  padding: 14px 0px;
  font-size: 14px;
  color: #1c1c1c;
  border: 1px solid #d8d7d7;
  padding-left: 10px;

  &:focus{
    border: 1px solid #0095f6;
    outline: none;
  }

  @media (max-width: 768px) {
    width: 80vw;
    font-size: 3.3vw;
  }
`;

const ContinueButton = styled.button`
  border: none;
  width: 23.5vw;
  padding: 15px 0px;
  font-size: 19px;
  background-color: black;
  color: white;
  margin: 20px 0px 10px 0px;
  cursor: pointer;

  &:hover {
    background-color: #507d3c;
  }

  @media (max-width: 768px) {
    width: 83vw;
    font-size: 3.3vw;
  }
`;

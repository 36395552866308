import React from "react";
import styled from "styled-components";
import ReviewCard from "./components/ReviewCard";
// import Reviews from "../../../products/components/Reviews";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";

SwiperCore.use([Navigation, Pagination, Autoplay]);

const SwiperContainer = styled(Swiper)`
  .swiper-pagination {
    bottom: 10px;
  }
`;

// const CustomNextButton = styled(ArrowForwardIosIcon)`
//   position: absolute;
//   top: 45%;
//   right: 50px;
//   background-color: rgb(255, 255, 255, 0.5);
//   transform: translateY(-50%);
//   color: #005eff;
//   text-align: center;
//   cursor: pointer;
//   padding: 10px;
//   transition: background-color 1s ease-in-out;

//   &:hover {
//     background-color: rgb(255, 255, 255, 0.8);
//   }

//   @media (max-width: 768px) {
//     top: 50%;
//     right: 10px;
//     padding: 4px;
//   }
// `;

// const CustomPrevButton = styled(ArrowBackIosIcon)`
//   background-color: rgb(255, 255, 255, 0.5);
//   position: absolute;
//   top: 45%;
//   left: 50px;
//   transform: translateY(-50%);
//   color: #005eff;
//   text-align: center;
//   cursor: pointer;
//   padding: 10px;

//   transition: background-color 1s ease-in-out;

//   &:hover {
//     background-color: rgb(255, 255, 255, 0.8);
//   }

//   @media (max-width: 768px) {
//     top: 50%;
//     left: 10px;
//     padding: 4px;
//   }
// `;

const reviews = [
  {
    name: "Rohit Krishnamurti",
    date: "18/03/2024",
    title: "WOW",
    location: "Mumbai",
    rating: 4.5,
    profileSrc:
      "https://media.istockphoto.com/id/1128678986/photo/portrait-of-a-handsome-indian-man.jpg?s=170667a&w=0&k=20&c=gVXr82iRMRcDPIXxgIH4GDH3EB0pXhZ8W81g61gojtw=",
    review: `Recently, I ordered their foldable washing machine and was hesitent
          about the quality. But when it came, it was in really good condition
          and arrived in 2 days. I'm really happy with the machine and i
          recommened this store to everyone!`,
  },

  {
    name: "Pooja Khadiyan",
    date: "02/06/2024",
    location: "Haryana",
    rating: 4,
    profileSrc:
      "https://i.pinimg.com/originals/a4/56/e4/a456e4b332a5f4b93711013e2b9d02b5.jpg",
    review:
      "I saw the advertisement on Instagram and ordered the foldable racks for organizing my shoes. Really good stuff and customer support is great as well. One thing i would recommend is delivery which was a bit slow. My racks took 6 days to delivery which was a bit late. Overall Amazing Store Prime Provions!",
  },
  {
    name: "Rita",
    date: "14/03/2024",
    location: "Punjab",
    rating: 5,
    profileSrc:
      "https://img.freepik.com/premium-photo/portrait-smiling-young-woman_1048944-23606719.jpg?size=626&ext=jpg&ga=GA1.1.2008272138.1722816000&semt=ais_hybrid",
    review: "Muje Products bohut ache lagge sir thank you very much",
  },
  {
    name: "Shiv Prem Singh Yadav",
    date: "10/08/2024",
    location: "Bihar",
    rating: 4,
    profileSrc:
      "https://images.pexels.com/photos/14653174/pexels-photo-14653174.jpeg",
    review:
      "I don't give reviews but if you're asking me so your store is overall nice but i would suggest to add cash on delivery otherwise good and my door closer is working fine till now.",
  },
  {
    name: "Ayushi Rana",
    date: "12/08/2024",
    location: "Himachal Pradesh",
    rating: 5,
    profileSrc:
      "https://cdn.storyasset.link/ewdS185XcMc97D28ATtZiJ5xUqi1/Untitled%20design%20-25-ms-uwvtumoozd.jpg",
    review:
      "Bought Foldable Washing Machine, Wasll Hooks and Stick Mirror. All were great and I'm satisfied. If anything new comes please do let me know",
  },

  {
    name: "Devesh Kumar",
    date: "20/08/2024",
    location: "Bhopal",
    profileSrc:
      "https://i.pinimg.com/736x/42/d3/eb/42d3eb3086588182207dde3a3c89b9d5.jpg",
    rating: 5,
    review:
      "Just Ordered Heavy Rollers and very excited to see how it looks like.",
  },
  {
    name: "Ghitankshu Sharma",
    date: "25/06/2024",
    location: "Chandigarh",
    rating: 5,
    profileSrc:
      "https://www.mbacrystalball.com/wp-content/uploads/2021/03/Improve-profile-for-MBA-India-USA.jpg",
    review:
      "Kam price me acha deal hai it is much better than Amazon which over prices everything. Its a cool store with cool products and the head is also respectful and trust worthy.",
  },
  {
    name: "Moshin Khan",
    date: "27/12/2023",
    location: "Uttar Pradesh",
    rating: 5,
    profileSrc:
      "https://i.pinimg.com/236x/b3/04/7b/b3047b71b97190a617f97c75ecd11b14.jpg",
    review: "I'm a big fan of your page....",
  },
  {
    name: "Shopping Lover ..❤️",
    date: "26/07/2024",
    location: "Haryana",
    rating: 3.5,
    profileSrc:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqxuXwSwYL28LYsS25IzoUlbm14zg6zdgJ6m3bgKmWMIT3aSUyI8zseaSvPqJLRJfexIo&usqp=CAU",
    review: "Great product, but a bit pricey.",
  },
];

function CustomerReviews({ isMobile }) {
  return (
    <Column>
      {/* <img
        width={"85%"}
        src="https://static.semrush.com/blog/uploads/media/cb/c4/cbc47b4b68532586683e5c9d81a82940/ajIe1HOztCcFk5fzr_H119lxyKcirHTWp9kp2PN6a0CrISr_Ey2AnbBI7gT8zkMKm_MyCvgPZh9AADvibOp_2kAMdnhtJWYfSdTHAOP5YJZh8t5zbj_if02nHFiem7Al-Vgj5St5BbJ9CdE1MLK75eQ.png"
        alt=""
      /> */}
      <SwiperContainer
        spaceBetween={10}
        pagination={{ clickable: true }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        loop={true}
      >
        {reviews.map((review, index) => (
          <SwiperSlide key={index}>
            <ReviewCard review={review} isMobile={isMobile} />
          </SwiperSlide>
        ))}
      </SwiperContainer>

      {/* <Reviews
        isMobile={isMobile}
        includeProductName={true}
        reviews={reviews}
      /> */}
      {/* <Heading>View all 346 Reviews</Heading> */}
    </Column>
  );
}

export default CustomerReviews;

const Column = styled.div`
  width: 100%;
  background-color: white;
  margin-top: 0px;
  margin-bottom: 50px;
  padding: 20px 0px;
`;

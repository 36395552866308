import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import MenuIcon from "@mui/icons-material/Menu";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import Search from "./components/Search";
import theprimeprovisionslogo from "../assets/logo/theprimeprovisionslogo.jpg";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { useCart } from "../Cart/components/CartContext";
import CategoriesTextRow from "./components/CategoriesTextRow";
import { useNavigate } from "react-router-dom";
import { useWishlist } from "../wishlist/components/WishlistContext";

const useIsMount = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};

function Header({
  belowIndexes,
  isMobile,
  isVisible,
  isCategoryTextVisible,
  toggleHeaderNavigation,
  isCartOpen,
  toggleCart,
  toggleWishlist,
}) {
  const isMount = useIsMount();
  const navigate = useNavigate();
  const { state } = useCart();
  const { wishlistState } = useWishlist();
  const totalItems = state.items.length;
  const totalWishListItems = wishlistState.items.length;

  useEffect(() => {
    if (isMount) {
      return;
    }

    if (!isCartOpen) {
      if (isMobile) {
        navigate("/cart");
      } else {
        toggleCart();
      }
    }
  }, [state]);

  return (
    <Column className="change-zIndex" isVisible={isVisible}>
      <Row>
        <MenuIcon
          style={{ fontSize: isMobile ? "7.5vw" : "1.7vw" }}
          onClick={() => {
            toggleHeaderNavigation(true);
          }}
        />
        {/* <h1 style={{ fontSize: "3.1vw", fontWeight: "500", color: "#3c3c3c" }}>
          Welcome To Our Store
        </h1> */}
        <img
          width={isMobile ? "60vw" : "80vw"}
          height={"auto"}
          src={theprimeprovisionslogo}
          alt=""
          style={{ marginLeft: isMobile ? "30px" : "0px" }}
        />

        <Row style={{ justifyContent: "center", alignItems: "flex-start" }}>
          <PermIdentityOutlinedIcon
            style={{
              fontSize: isMobile ? "6.5vw" : "1.55vw",
              cursor: "pointer",
            }}
            onClick={() => navigate("/account/login")}
          />
          <CartIconContainer
            // onClick={toggleCart}
            onClick={() => {
              if (isMobile) {
                navigate("/wishlist");
              } else {
                toggleWishlist();
              }
            }}
            style={{ marginLeft: "13px", marginRight: "5px" }}
          >
            <FavoriteBorderIcon
              style={{
                fontSize: isMobile ? "6.3vw" : "1.4vw",
              }}
            />
            <Badge style={{ backgroundColor: "#fd5e5e" }}>
              {totalWishListItems}
            </Badge>
          </CartIconContainer>
          <CartIconContainer
            // onClick={toggleCart}
            onClick={() => {
              if (isMobile) {
                navigate("/cart");
              } else {
                toggleCart();
              }
            }}
            style={{ marginLeft: "17px", marginRight: "5px" }}
          >
            <ShoppingCartOutlinedIcon
              style={{ fontSize: isMobile ? "6.4vw" : "1.5vw" }}
            />
            <Badge>{totalItems}</Badge>
          </CartIconContainer>
        </Row>
      </Row>
      <Search />
      <CategoriesTextRow
        isMobile={isMobile}
        isCategoryTextRowVisible={isCategoryTextVisible}
      />
    </Column>
  );
}

export default Header;

const Column = styled.div`
  margin-bottom: 5px;
  position: sticky;
  /* background-color: white; */
  top: 0;
  margin-top: -10px;
  /* transition: transform 0.3s ease-in-out;
  transform: ${(props) =>
    props.isVisible ? "translateY(0)" : "translateY(-100%)"}; */
  z-index: 1001;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 4px 10px 0px 10px;
`;

const CartIconContainer = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-left: 10px;

  @media (max-width: 768px) {
    margin-left: 0px;
  }
`;

const Badge = styled.span`
  position: absolute;
  top: -5px;
  right: -11px;
  background-color: #6c9c36;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 11px;
  font-weight: bold;
`;

import React from "react";
import styled from "styled-components";
import ProductListDiv from "../../../../../utils/Product/ProductListDiv/ProductListDiv";

function BasedOnCategories({ products }) {
  return (
    products &&
    products.length > 0 && (
      <MainDiv>
        <ProductListDiv
          isFirst={true}
          backgroundColor={"#f9fbfd"}
          Heading={Heading}
          products={products.filter((product) =>
            product.category_to_include.includes("Home")
          )}
          Title={"Home Utilities & Essentials"}
          About={
            "Explore 100% toxin-free and safe skincare products by Mamaearth that are formulated with love and the goodness of natural ingredients"
          }
        />
        <ProductListDiv
          Heading={Heading}
          backgroundColor={"#fffbf8"}
          Title={"Advanced Kitchen Tools"}
          products={products.filter((product) =>
            product.category_to_include.includes("Kitchen")
          )}
          About={
            "Explore 100% toxin-free and safe skincare products by Mamaearth that are formulated with love and the goodness of natural ingredients"
          }
        />
        <ProductListDiv
          Heading={Heading}
          Title={"Bathroom Must Haves"}
          backgroundColor={"#fdfaff"}
          products={products.filter((product) =>
            product.category_to_include.includes("Bathroom")
          )}
          About={
            "Explore 100% toxin-free and safe skincare products by Mamaearth that are formulated with love and the goodness of natural ingredients"
          }
        />
        <ProductListDiv
          Heading={Heading}
          Title={"Supercool Tech Gadgets"}
          backgroundColor={"#fdfaff"}
          products={products.filter((product) =>
            product.category_to_include.includes("Technology")
          )}
          About={
            "Explore 100% toxin-free and safe skincare products by Mamaearth that are formulated with love and the goodness of natural ingredients"
          }
        />
        {/* <ProductListDiv
        Heading={Heading}
        Title={"Cosumables"}
        backgroundColor={"#fffffa"}
        About={
          "Explore 100% toxin-free and safe skincare products by Mamaearth that are formulated with love and the goodness of natural ingredients"
        }
      />
      <ProductListDiv
        Heading={Heading}
        Title={"Perfumes"}
        backgroundColor={"#fffafa"}
        About={
          "Explore 100% toxin-free and safe skincare products by Mamaearth that are formulated with love and the goodness of natural ingredients"
        }
      /> */}
      </MainDiv>
    )
  );
}

export default BasedOnCategories;

const MainDiv = styled.div`
  width: 95%;
  margin: 0px auto;
  margin-top: 60px;
  overflow: hidden;

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 100px;
  }
`;

const Heading = styled.h1`
  font-size: 35px;

  @media (max-width: 768px) {
    font-size: 5.4vw;
  }
`;

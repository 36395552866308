import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Home from "./routes/home/Home";
import { useEffect, useRef, useState } from "react";
import Header from "./header/Header";
import Products from "./routes/products/Products";
import ScrollToTop from "./ScrollToTop";
import Footer from "./footer/Footer";
import Topbar from "./topbar/Topbar";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import ChatWindow from "./routes/chat/ChatWindow";
import styled from "styled-components";
import HeaderNavigation from "./MobileSpecific/HeaderNavigation/HeaderNavigation";
import Cart from "./Cart/Cart";
import { useMediaQuery } from "@mui/material";
import Checkout from "./routes/checkout/Checkout";
import Collections from "./routes/collections/Collections";
import { useProducts } from "./utils/ContextAPI/products/ProductsContext";
import SearchComponent from "./routes/search/SearchComponent";
import Wishlist from "./wishlist/Wishlist";
import Login from "./routes/auth/components/Login";
import ForgotPassword from "./routes/auth/components/ForgotPassword";
import Register from "./routes/auth/components/Register";
import NotFound from "./routes/notFound/NotFound";
import TrackOrder from "./routes/trackorder/TrackOrder";
import ChatSupport from "./routes/chat/components/ChatSupport";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import Categories from "./routes/categories/Categories";
import TawkToWidget from "./routes/chat/components/TawkToWidget";
import PrivacyPolicy from "./routes/legalterms/PrivacyPolicy";
import TermsAndConditions from "./routes/legalterms/Terms";
import CancellationAndRefund from "./routes/legalterms/CancelAndRefund";
import ShippingAndDelivery from "./routes/legalterms/ShippingDelivery";
import ContactUs from "./routes/legalterms/ContactUs";

function App() {
  const isMobile = useMediaQuery("(max-width:768px)");
  const [isOpen, setIsOpen] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [isWishlistOpen, setIsWishlistOpen] = useState(false);
  const [isHeaderNavigationOpen, setIsHeaderNavigationOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [isCategoryTextVisible, setIsCategoryTextVisible] = useState(false);
  // const [lastScrollY, setLastScrollY] = useState(0);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const halfScreenHeight = window.innerHeight / 2.5;

  //     if (window.scrollY > halfScreenHeight) {
  //       if (window.scrollY < lastScrollY) {
  //         // Scroll up
  //         setIsVisible(true);
  //       } else {
  //         // Scroll down
  //         setIsVisible(false);
  //       }
  //     } else {
  //       setIsVisible(true);
  //     }

  //     setLastScrollY(window.scrollY);
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [lastScrollY]);

  useEffect(() => {
    if (isOpen || isCartOpen || isWishlistOpen || isHeaderNavigationOpen) {
      // Disable scrolling
      document.body.style.overflow = "hidden";
    } else {
      // Enable scrolling
      document.body.style.overflow = "visible";
    }
  }, [isOpen, isCartOpen, isWishlistOpen, isHeaderNavigationOpen]);

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  const toggleWishlist = () => {
    setIsWishlistOpen(!isWishlistOpen);
  };

  const toggleHeaderNavigation = () => {
    setIsHeaderNavigationOpen(!isHeaderNavigationOpen);
  };

  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsMobile(window.innerWidth < 768);

  //     window.addEventListener("resize", handleResize);

  //     return () => {
  //       window.removeEventListener("resize", handleResize);
  //     };
  //   };
  // }, []);

  return (
    <div className="App" style={{ position: "relative" }}>
      <Router>
        <AppContent
          isHeaderNavigationOpen={isHeaderNavigationOpen}
          setIsHeaderNavigationOpen={setIsHeaderNavigationOpen}
          setIsCartOpen={setIsCartOpen}
          setIsWishlistOpen={setIsWishlistOpen}
          isMobile={isMobile}
          isVisible={isVisible}
          isCategoryTextVisible={isCategoryTextVisible}
          toggleHeaderNavigation={toggleHeaderNavigation}
          toggleCart={toggleCart}
          isCartOpen={isCartOpen}
          toggleWishlist={toggleWishlist}
          isWishlistOpen={isWishlistOpen}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          toggleChat={toggleChat}
          setIsCategoryTextVisible={setIsCategoryTextVisible}
        />
        <ScrollToTop />
      </Router>
    </div>
  );
}

const AppContent = ({
  setIsCartOpen,
  setIsWishlistOpen,
  isHeaderNavigationOpen,
  setIsHeaderNavigationOpen,
  isMobile,
  isVisible,
  isCategoryTextVisible,
  toggleHeaderNavigation,
  toggleCart,
  toggleWishlist,
  isCartOpen,
  isWishlistOpen,
  isOpen,
  setIsOpen,
  toggleChat,
  setIsCategoryTextVisible,
}) => {
  const { products } = useProducts();

  const location = useLocation();
  const hideHeaderFooter =
    location.pathname === "/checkout" ||
    location.pathname === "/search" ||
    location.pathname === "/chat-support";
  const isInProduct = location.pathname.includes("products");

  const tawkMessengerRef = useRef();
  const toggleTawkTo = () => {
    tawkMessengerRef.current.maximize();
  };

  return (
    <div style={{ userSelect: "none" }}>
      {!hideHeaderFooter && (
        <>
          <Topbar isMobile={isMobile} />
          <Header
            isMobile={isMobile}
            isVisible={isVisible}
            isCategoryTextVisible={isInProduct ? false : isCategoryTextVisible}
            toggleHeaderNavigation={toggleHeaderNavigation}
            toggleCart={toggleCart}
            toggleWishlist={toggleWishlist}
          />
        </>
      )}

      <Routes>
        <Route
          path="/"
          element={
            <Home
              isMobile={isMobile}
              products={products}
              toggleChat={toggleChat}
              isVisible={isVisible}
              setIsCategoryTextVisible={setIsCategoryTextVisible}
            />
          }
        />

        <Route path="/account/login" element={<Login />} />
        <Route path="/account/forgot-password" element={<ForgotPassword />} />
        <Route path="/account/register" element={<Register />} />

        <Route
          path="/search"
          element={<SearchComponent isMobile={isMobile} products={products} />}
        />
        <Route
          path="/products/:id/"
          element={<Products isMobile={isMobile} />}
        />
        <Route
          path="/collections/:category/:subCategory/"
          element={<Collections isMobile={isMobile} products={products} />}
        />
        <Route
          path="/categories/all"
          element={<Categories isMobile={isMobile} products={products} />}
        />

        <Route
          path="/cart"
          element={
            <Cart
              isCartOpen={true}
              toggleCart={toggleCart}
              isMobile={isMobile}
              products={products}
            />
          }
        />
        <Route
          path="/wishlist"
          element={
            <Wishlist
              isWishlistOpen={true}
              toggleWishlist={toggleCart}
              isMobile={isMobile}
              products={products}
            />
          }
        />
        <Route path="/checkout" element={<Checkout isMobile={isMobile} />} />
        {/* <Route
          path="/chat"
          element={<ChatWindow isOpen={isOpen} toggleChat={toggleChat} toggleTawkTo={toggleTawkTo}/>}
        /> */}

        <Route path="/track-my-order" element={<TrackOrder />} />
        <Route path="/chat-support" element={<ChatSupport />} />

        {/* Terms and Policies */}
        <Route path="/policy/terms" element={<TermsAndConditions />} />
        <Route path="/policy/privacy" element={<PrivacyPolicy />} />
        <Route path="/policy/refund" element={<CancellationAndRefund />} />
        <Route path="/policy/shipping" element={<ShippingAndDelivery />} />
        <Route path="/policy/contact_us" element={<ContactUs />} />

        <Route path="*" element={<NotFound isMobile={isMobile} />} />
      </Routes>

      {isMobile && (
        <HeaderNavigation
          toggleCart={toggleCart}
          products={products}
          isHeaderNavigationOpen={isHeaderNavigationOpen}
          toggleHeaderNavigation={toggleHeaderNavigation}
        />
      )}

      {!isMobile && (
        <Cart
          isCartOpen={isCartOpen}
          toggleCart={toggleCart}
          isMobile={isMobile}
          products={products}
        />
      )}

      {!isMobile && (
        <Wishlist
          isWishlistOpen={isWishlistOpen}
          toggleWishlist={toggleWishlist}
          isMobile={isMobile}
          products={products}
        />
      )}

      {/* <SocialIconDiv>
        <SocialIcon src="https://e7.pngegg.com/pngimages/672/312/png-clipart-instagram-logo-icon-others-text-photography-thumbnail.png" alt="Insta"/>
        <SocialIcon style={{marginTop:"5px"}} src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/2062095_application_chat_communication_logo_whatsapp_icon.svg/2048px-2062095_application_chat_communication_logo_whatsapp_icon.svg.png" alt="Insta"/>
      </SocialIconDiv> */}
      {!isMobile && (
        <ChatButton onClick={toggleChat}>
          <MoreHorizOutlinedIcon
            style={{ color: "#067eff", fontSize: "25px" }}
          />
        </ChatButton>
      )}
      <ChatWindow
        isOpen={isOpen}
        toggleChat={toggleChat}
        isMobile={isMobile}
        toggleTawkTo={toggleTawkTo}
      />
      <Footer />

      {((!isMobile && isCartOpen) ||
        (!isMobile && isWishlistOpen) ||
        isOpen ||
        isHeaderNavigationOpen) && (
        <DarkCover
          onClick={() => {
            if (isCartOpen) setIsCartOpen(false);
            else if (isOpen) setIsOpen(false);
            else if (isWishlistOpen) setIsWishlistOpen(false);
            else if (isHeaderNavigationOpen) setIsHeaderNavigationOpen(false);
          }}
        />
      )}
      <TawkToWidget tawkMessengerRef={tawkMessengerRef} />
    </div>
  );
};

export default App;

const ChatButton = styled.button`
  position: fixed;
  bottom: 75px;
  right: 63px;
  /* background-color: #0c5de7; */
  border: none;
  border-radius: 50%;
  padding: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  z-index: 999;
`;

const DarkCover = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  bottom: 0%;
  right: 0;
  left: 0;
  z-index: 1002;
`;

// const SocialIconDiv = styled.div`
//   position: fixed;
//   display: flex;
//   flex-direction: column;
//   bottom: 70px;
//   left: 20px;
//   cursor: pointer;
//   z-index: 1000;
// `;

// const SocialIcon = styled.img`
//   width: 35px;
//   height: 35px;
//   border-radius: 50%;
// `;

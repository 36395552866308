import React from "react";
import { Parallax } from "react-scroll-parallax";
import styled from "styled-components";
import Categories from "../../../categories/Categories";

function WhoAreWe({ isMobile }) {
  return (
    <Column
      style={{
        width: "100%",
        marginTop: "150px",
        backgroundColor: "white",
        position: "relative",
        alignItems: "center",
      }}
    >
      <Parallax
        translateY={isMobile ? ["0%", "-100%"] : ["20%", "-110%"]}
        speed={1}
        easing={"ease"}
        style={{ position: "absolute", width: "100%", zIndex: "0" }}
      >
        <Heading>Who are we?</Heading>
      </Parallax>
      {/* <BackgroundImage
        src="https://i.pinimg.com/736x/e3/62/fd/e362fdf659c0502ffd885987a729f5a4.jpg"
        // src="https://i.pinimg.com/736x/99/3b/fb/993bfb10070371181016cc58c8bbe58a.jpg"
        alt="bg-image"
      /> */}
      <Column
        style={{
          zIndex: "1",
          borderRadius: "6px",
          backgroundColor: "white",
          width: isMobile ? "95%" : "75%",
          // boxShadow: "0px 2px 17px 0px #b8b7b7",
          padding: "5px 10px",
        }}
      >
        <div
          style={{
            width: isMobile ? "100%" : "50%",
            margin: "0 auto",
            backgroundColor: "#cfcfcf",
            height: "300px",
            marginTop: "20px",
          }}
        />

        <Text
          style={{
            fontSize: isMobile ? "3.8vw" : "1vw",
            textAlign: "center",
            width: !isMobile && "70%",
            margin: !isMobile && "20px auto 10px auto",
          }}
        >
          <span style={{ fontWeight: "600" }}>The Prime Provisions</span> <br />
          is a Problem Solving - Unique Product Store Brand with Multiple
          locations all over India, like Pune, Delhi, Ambala and Rohtak. <br />
          <br /> Owned by <span style={{ fontWeight: "600" }}>
            Rohit Singh
          </span>{" "}
          who started the store back in 2019, the store has been growing at a
          tremendous rate and has about 10,000 active customers all over India.
          <br />
          <br />
        </Text>

        <Row style={{ width: !isMobile && "70%", margin: "0 auto" }}>
          <Text
            style={{
              fontSize: isMobile ? "3.2vw" : "1vw",
              textAlign: "center",
            }}
          >
            <span
              style={{
                fontSize: isMobile ? "3.6vw" : "1.2vw",
                fontWeight: "600",
              }}
            >
              Our Mission
            </span>{" "}
            <br />
            To provide the best and most unique products to our customers
            <br />
            <br />
          </Text>

          <div
            style={{
              width: "1px",
              alignSelf: "stretch",
              backgroundColor: "#d3d3d3",
              margin: "10px 5px",
            }}
          />
          <Text
            style={{
              fontSize: isMobile ? "3.2vw" : "1vw",
              textAlign: "center",
            }}
          >
            <span
              style={{
                fontSize: isMobile ? "3.6vw" : "1.2vw",
                fontWeight: "600",
              }}
            >
              Our Vision
            </span>{" "}
            <br />
            To become the leading product store in India with a strong focus on
            Problem Solving
            <br />
            <br />
          </Text>
        </Row>

        <Row
          style={{
            width: isMobile ? "90%" : "50%",
            justifyContent: "space-evenly",
            margin: "30px auto 10px auto",
          }}
        >
          <div>
            <SocialIcon
              src="https://e7.pngegg.com/pngimages/672/312/png-clipart-instagram-logo-icon-others-text-photography-thumbnail.png"
              alt="Insta"
            />
            <Text
              style={{
                fontSize: isMobile ? "2.5vw" : "0.7vw",
                textAlign: "center",
                margin: 0,
              }}
            >
              Instagram
            </Text>
          </div>

          <div>
            <SocialIcon
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/2062095_application_chat_communication_logo_whatsapp_icon.svg/2048px-2062095_application_chat_communication_logo_whatsapp_icon.svg.png"
              alt="WhatsApp"
            />
            <Text
              style={{
                fontSize: isMobile ? "2.5vw" : "0.7vw",
                textAlign: "center",
                margin: 0,
              }}
            >
              WhatsApp
            </Text>
          </div>
          <div>
            <SocialIcon
              src="https://cdn.pixabay.com/photo/2021/06/15/12/51/facebook-6338507_1280.png"
              alt="Facebook"
            />
            <Text
              style={{
                fontSize: isMobile ? "2.5vw" : "0.7vw",
                textAlign: "center",
                margin: 0,
              }}
            >
              Facebook
            </Text>
          </div>
          <div>
            <SocialIcon
              src="https://static-00.iconduck.com/assets.00/youtube-icon-1024x1024-t3zo1lo4.png"
              alt="Youtube"
            />
            <Text
              style={{
                fontSize: isMobile ? "2.5vw" : "0.7vw",
                textAlign: "center",
                margin: 0,
              }}
            >
              Youtube
            </Text>
          </div>
        </Row>

        <div
          style={{
            width: isMobile ? "100%" : "60%",
            margin: "0 auto",
            backgroundColor: "#000000",
            height: isMobile ? "190px" : "40vh",
            marginTop: "20px",
            marginBottom: "80px",
          }}
        />

        <Heading
          style={{
            fontSize: isMobile ? "5.5vw" : "1.3vw",
            fontWeight: "700",
            textAlign: "center",
            fontFamily: "unset",
          }}
        >
          Our Products
        </Heading>
        <Banner src="https://images.squarespace-cdn.com/content/v1/5c0c16cf12b13fa421e4f5f7/7a5c0cb8-2685-445f-a9de-1a1d29b6c8a5/te-line-6.png" />

        <Text
          style={{ fontSize: isMobile ? "3.7vw" : "1vw", textAlign: "center" }}
        >
          {/* ❝❞ */}
          At The Prime Provisions, you will find variety of different{" "}
          <span style={{ fontWeight: "600" }}>
            Home, Kitchen, Bathroom, Technology, Decor, Entertainment and
            Problem Solving
          </span>{" "}
          Products that can and will make you and your Life Smarter.
          {/* These products are tested and are of great quality only. These
          Products are rare to find elsewhere. <br />
          <br /> For these reasons, The Prime Provisions is perfect to become
          your next favourite thing.
          <br /> */}
        </Text>
        <Categories />
        <Heading
          style={{
            fontSize: isMobile ? "5vw" : "1.3vw",
            fontWeight: "700",
            textAlign: "center",
            fontFamily: "unset",
            // marginTop: "80px",
          }}
        >
          Can You Trust Us?
        </Heading>

        <Banner src="https://images.squarespace-cdn.com/content/v1/5c0c16cf12b13fa421e4f5f7/7a5c0cb8-2685-445f-a9de-1a1d29b6c8a5/te-line-6.png" />

        <Heading
          style={{
            fontSize: isMobile ? "4vw" : "1.3vw",
            marginTop: "2vh",
            fontWeight: "500",
            textAlign: "center",
            backgroundColor: "white",
            zIndex: "2",
            fontFamily: "unset",
            width: !isMobile && "70%",
            margin: !isMobile && "0 auto",
          }}
        >
          <span
            style={{ fontSize: isMobile ? "7vw" : "2.8vw", fontWeight: "700" }}
          >
            6530+ <br />
          </span>{" "}
          <br />❝ Original Products from Qriginal Sellers at Original Prices and
          Original Quality.❞
        </Heading>
        <img
          style={{
            margin: isMobile ? "20px auto" : "30px auto",
            width: isMobile ? "100%" : "70%",
          }}
          src="https://media.istockphoto.com/id/1048818390/vector/warehouse-interior-with-boxes-on-rack-and-people-working-flat-vector-and-solid-color-style.jpg?s=612x612&w=0&k=20&c=nIzGWh47LzqiHiVn4gTyHH4M0K7JKqDHd_P8ulCyX3Y="
          alt="bg-image"
        />
        <Text
          style={{
            width: isMobile ? "100%" : "70%",
            margin: !isMobile && "20px auto",
            fontSize: isMobile ? "3.8vw" : "1vw",
            textAlign: "center",
          }}
        >
          We source our Products directly from the Original Sellers /
          Manufacturers{" "}
          {"(Mainly from India, Germany, Korea, Hong Kong and USA)"} in order to
          provide you with the Original Price and maintaining the Original
          Quality.
          <br />
          <br />
        </Text>

        <Heading
          style={{
            fontSize: isMobile ? "5.5vw" : "1.3vw",
            fontWeight: "700",
            textAlign: "center",
            fontFamily: "unset",
            marginTop: "80px",
          }}
        >
          Offline + Online
        </Heading>

        <Banner src="https://images.squarespace-cdn.com/content/v1/5c0c16cf12b13fa421e4f5f7/7a5c0cb8-2685-445f-a9de-1a1d29b6c8a5/te-line-6.png" />

        <img
          style={{
            margin: isMobile ? "20px auto" : "30px auto",
            width: isMobile ? "100%" : "70%",
          }}
          src="https://media.licdn.com/dms/image/C4D12AQHYvkvrZPH3BA/article-cover_image-shrink_600_2000/0/1567341632786?e=2147483647&v=beta&t=q5RkLb4w8pPNsKKWQE8sX1dOMG7pBtNcUVLOqw5VP2w"
          alt="bg-image"
        />
        <Text
          style={{
            fontSize: isMobile ? "3.8vw" : "1vw",
            textAlign: "center",
            width: isMobile ? "100%" : "70%",
            margin: !isMobile && "20px auto",
          }}
        >
          Although we have Strong Base Offline where we store our products and
          have hundreds of Customers daily, Due to increasing hectic and busy
          environment, We decided to expanded our Reach Online as it keeps
          things clean while at the same time connects us to even more Customers
          ALL OVER INDIA.
          <br />
          <br />
        </Text>
        {/* <Heading
          style={{
            fontSize: isMobile ? "4.5vw" : "1.3vw",
            fontWeight: "700",
            textAlign: "center",
            fontFamily: "unset",
            marginTop: "30px",
          }}
        >
          ❝ Our Customers are ❤️ ❞
        </Heading>
        <img
          style={{
            width: isMobile ? "100%" : "60%",
            margin: "0px auto",
            marginTop: "20px",
          }}
          src="https://www.pngitem.com/pimgs/b/352-3526791_grocery-store-manager-super-market-billing-clipart-hd.png"
          alt="bg-image"
        />
        <Text
          style={{
            fontSize: isMobile ? "3.4vw" : "0.9vw",
            textAlign: "center",
            marginTop: "30px",
          }}
        >
          We are very proud to say that we have delivered our Products to 4,000+
          Passionate Customers all over India and soon be reaching more!
          <br />
          <br />
        </Text> */}

        <Row
          style={{ width: "100%", justifyContent: "center", marginTop: "30px" }}
        >
          <Heading
            style={{
              fontSize: isMobile ? "14vw" : "6vw",
              fontWeight: "600",
              padding: "5px 10px",
              border: "1px solid black",
            }}
          >
            1
          </Heading>
          <Heading
            style={{
              fontSize: isMobile ? "14vw" : "6vw",
              fontWeight: "600",
              padding: "5px 10px",
              marginLeft: isMobile ? "10px" : "15px",
              border: "1px solid black",
            }}
          >
            1
          </Heading>
          <Heading
            style={{
              fontSize: isMobile ? "14vw" : "6vw",
              fontWeight: "600",
              padding: "5px 10px",
              marginLeft: isMobile ? "10px" : "15px",
              border: "1px solid black",
            }}
          >
            3
          </Heading>
          <Heading
            style={{
              fontSize: isMobile ? "14vw" : "6vw",
              fontWeight: "600",
              padding: "5px 10px",
              marginLeft: isMobile ? "10px" : "15px",
              border: "1px solid black",
            }}
          >
            4
          </Heading>
          <Heading
            style={{
              fontSize: isMobile ? "14vw" : "6vw",
              fontWeight: "600",
              padding: "5px 10px",
              marginLeft: isMobile ? "10px" : "15px",
              border: "1px solid black",
            }}
          >
            2
          </Heading>
        </Row>
        <Text
          style={{
            textAlign: "center",
            margin: "0",
            marginTop: isMobile ? "-20px" : "-60px",
            fontSize: isMobile ? "3vw" : "0.8vw",
            textAlign: "center",
          }}
        >
          Satisfied Customers All Over India <br />
          <br />
        </Text>
      </Column>
    </Column>
  );
}

export default WhoAreWe;

const Row = styled.div`
  display: flex;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const BackgroundImage = styled.img`
  position: absolute;
  width: 110%;
  opacity: 0.2;
  z-index: 2;
  bottom: 0;
  right: 0;
`;

const Heading = styled.h1`
  margin-left: 15px;
  font-size: 2.4vw;
  font-weight: 600;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 8vw;
    margin-left: 0px;
    font-weight: 900;
    text-align: center;
  }
`;

const Banner = styled.img`
  width: 100%;
  height: 35px;
  opacity: 0.1;
  margin-bottom: 40px;
  /* margin-top: 10px; */
  object-fit: fill;

  @media (max-width: 768px) {
    height: 23px;
    margin-bottom: 10px;
  }
`;

const Text = styled.p`
  margin: 0 5px;
  margin-top: 15px;
  font-size: 0.6vw;
  font-weight: 400;
  text-align: start;
  letter-spacing: 0.02ch;

  @media (max-width: 768px) {
    font-size: 3.4vw;
    line-height: 20px;
  }
`;

const SocialIcon = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
`;

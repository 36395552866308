import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

function CategoriesTextRow({ isMobile, isCategoryTextRowVisible }) {
  const navigate = useNavigate();

  return (
    <Row
      isMobile={isMobile}
      isCategoryTextRowVisible={isCategoryTextRowVisible}
    >
      <CategoryText
        onClick={() => {
          {
            navigate("/collections/Best Sellers/all");
          }
        }}
      >
        Best Sellers
      </CategoryText>
      <CategoryText
        onClick={() => {
          {
            navigate("/collections/Home/all");
          }
        }}
      >
        Home Essentials
      </CategoryText>
      <CategoryText
        onClick={() => {
          {
            navigate("/collections/Kitchen/all");
          }
        }}
      >
        Kitchen Tools
      </CategoryText>
      <CategoryText
        onClick={() => {
          {
            navigate("/collections/Bathroom/all");
          }
        }}
      >
        Bathroom Hacks
      </CategoryText>
      <CategoryText
        onClick={() => {
          {
            navigate("/collections/Cleaning/all");
          }
        }}
      >
        Cleaning Gadgets
      </CategoryText>
      <CategoryText
        onClick={() => {
          {
            navigate("/collections/Instragram/all");
          }
        }}
      >
        Instragram Specials
      </CategoryText>
      <CategoryText
        onClick={() => {
          {
            navigate("/collections/Technology/all");
          }
        }}
      >
        Technology
      </CategoryText>
      <CategoryText
        onClick={() => {
          {
            navigate("/collections/Foldable/all");
          }
        }}
      >
        Foldable Gadgets
      </CategoryText>
      <CategoryText
        onClick={() => {
          {
            navigate("/collections/Organizer/all");
          }
        }}
      >
        Organizer
      </CategoryText>

      <CategoryText
        onClick={() => {
          {
            navigate("/collections/Life Savers/all");
          }
        }}
      >
        Life Savers
      </CategoryText>
      <CategoryText
        onClick={() => {
          {
            navigate("/collections/Travel/all");
          }
        }}
      >
        Travel Hacks
      </CategoryText>
    </Row>
  );
}

export default CategoriesTextRow;

const Row = styled.div`
  width: 100%;
  overflow: scroll;
  display: flex;
  visibility: ${(props) =>
    props.isCategoryTextRowVisible ? "visible" : "hidden"};
  position: absolute;
  top: 100%;
  align-items: flex-start;
  justify-content: center;
  padding: 13px 0px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(10px);

  &::-webkit-scrollbar {
    display: none;
  }

  & {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  @media (max-width: 768px) {
    justify-content: flex-start;
  }
`;

const CategoryText = styled.p`
  margin: 0px 1vw;
  font-size: 0.8vw;
  font-weight: 500;
  white-space: nowrap;
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 3.2vw;
    margin: 0px 3.8vw;
  }

  &:hover {
    border-bottom: 1px solid black;
  }
`;

import React from "react";
import styled from "styled-components";
import SlidingBanners from "./components/SlidingBanners";
import Categories from "./components/Categories";
import AllProducts from "./components/allProducts/AllProducts";
import FromWhereWeSource from "./components/WhoAreWe/FromWhereWeSource";
import CustomerReviews from "./components/customerReviews/CustomerReviews";
import QuestionsAndQueries from "./components/QuestionsAndQueries";
import { ParallaxProvider } from "react-scroll-parallax";
import BottomNavigationBar from "../../MobileSpecific/bottomNavigation/BottomNavigation";
import WhoAreWe from "./components/WhoAreWe/WhoAreWe";

function Home({
  toggleChat,
  isMobile,
  products,
  isVisible,
  setIsCategoryTextVisible,
}) {
  return (
    <ParallaxProvider>
      <Background>
        <Categories
          isMobile={isMobile}
          setIsCategoryTextVisible={setIsCategoryTextVisible}
        />
        {products.length > 0 && <SlidingBanners isMobile={isMobile} />}
        <AllProducts isMobile={isMobile} products={products} />
        <WhoAreWe isMobile={isMobile} />
        {/* <FromWhereWeSource isMobile={isMobile} /> */}
        <CustomerReviews isMobile={isMobile} />
        <QuestionsAndQueries isMobile={isMobile} />
      </Background>
      {isMobile && <BottomNavigationBar toggleChat={toggleChat} />}
    </ParallaxProvider>
  );
}

export default Home;

const Background = styled.div`
  margin: 0;
  /* background: linear-gradient(
    45deg,
    #fff 25%,
    #e2e1e1 25%,
    #f5f5f5 50%,
    #fff 50%,
    #fff 75%,
    #f5f5f5 75%,
    #f5f5f5 100%
  ); */
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;

  background-size: 4px 4px; /* Adjust the gradient size */
  min-height: 100vh; /* Ensure the background covers the whole viewport */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  overflow: hidden;
  padding-bottom: 100px;
`;

import React, { createContext, useContext, useState, useEffect } from "react";
import { getFirestore, collection, getDocs } from "firebase/firestore";

const ProductsContext = createContext();

export const useProducts = () => {
  return useContext(ProductsContext);
};

export const ProductsProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  const loadProducts = async () => {
    const db = getFirestore();
    const productsCollection = collection(db, "products");
    const querySnapshot = await getDocs(productsCollection);

    const productsData = [];
    querySnapshot.forEach((doc) => {
      productsData.push(doc.data());
    });

    setProducts(productsData);
    setLoading(false);
  };

  useEffect(() => {
    loadProducts();
  }, []);

  const updateProduct = (updatedProduct) => {
    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === updatedProduct.id ? updatedProduct : product
      )
    );
  };

  return (
    <ProductsContext.Provider
      value={{ products, setProducts, loadProducts, updateProduct, loading }}
    >
      {children}
    </ProductsContext.Provider>
  );
};

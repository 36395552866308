import React from "react";
import styled from "styled-components";

function FullDescription({ description }) {
  const sortedKeys = Object.keys(description).sort((a, b) => {
    const aNum = parseInt(a.match(/^\d+/)[0], 10);
    const bNum = parseInt(b.match(/^\d+/)[0], 10);
    return aNum - bNum;
  });
  return (
    <Container>
      {sortedKeys.map((key) => {
        if (key.includes("textBlock")) {
          return <Text key={key}>{description[key]}</Text>;
        } else if (key.includes("imageBlock")) {
          return <Image key={key} src={description[key]} alt="" />;
        }
        return null;
      })}
    </Container>
  );
}

export default FullDescription;

const Container = styled.div`
  width: 100%;
`;

const Text = styled.p`
  font-weight: 500;
  margin: 20px 0px;
  line-height: 23px;
  text-align: start;
  font-size: 0.85vw;

  @media (max-width: 767px) {
    font-size: 3.65vw;
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

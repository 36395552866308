import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";
import Person3OutlinedIcon from "@mui/icons-material/Person3Outlined";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";

// const reviews = [
//   {
//     name: "Rohit Singh",
//     date: "18/03/2024",
//     title: "WOW",
//     rating: 4.5,
//     review:
//       "This product is amazing! I've seen a huge difference in my hair texture.",
//   },
//   {
//     name: "User1",
//     date: "18/03/2024",
//     title: "Loved It",
//     rating: 5,
//     review: "Absolutely love it! Highly recommend.",
//   },
//   {
//     name: "User2",
//     date: "18/03/2024",
//     title: "Best Product",
//     rating: 4,
//     review: "Great product, but a bit pricey.",
//   },
//   {
//     name: "User3",
//     date: "18/03/2024",
//     title: "Best Product",
//     rating: 4,
//     review: "Great product, but a bit pricey.",
//   },
//   {
//     name: "User4",
//     date: "18/03/2024",
//     title: "Best Product",
//     rating: 5,
//     review: "Great product, but a bit pricey.",
//   },
//   {
//     name: "Trusted User",
//     date: "18/03/2024",
//     title: "Best Product",
//     rating: 4.5,
//     review: "Great product, but a bit pricey.",
//   },
//   {
//     name: "Shopping Lover ..❤️",
//     date: "18/03/2024",
//     title: "Best Product",
//     rating: 5,
//     review: "Great product, but a bit pricey.",
//   },
//   // Add more reviews as needed
// ];

const calculateAverageRating = (reviews) => {
  if (!reviews || reviews.length === 0) return 0;

  const totalRating = reviews.reduce(
    (sum, review) => sum + parseFloat(review.rating),
    0
  );
  return totalRating / reviews.length;
};

export const renderStars = (rating) => {
  const fullStars = Math.floor(rating);
  const halfStar = rating % 1 >= 0.5;
  const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

  return (
    <>
      {Array.from({ length: fullStars }, (_, index) => (
        <FaStar key={`full-${index}`} />
      ))}
      {halfStar && <FaStarHalfAlt key="half" />}
      {Array.from({ length: emptyStars }, (_, index) => (
        <FaRegStar key={`empty-${index}`} />
      ))}
    </>
  );
};

function Reviews({ isMobile, includeProductName, reviews = [] }) {
  const averageRating = calculateAverageRating(reviews);
  const [showWriteAReview, setShowWriteAReview] = useState(false);
  const [userRating, setUserRating] = useState(null);
  const totalReviews = reviews?.length;

  const ratingDistribution = [5, 4, 3, 2, 1].map((star) => ({
    star,
    count: reviews?.filter((review) => {
      const rating = parseFloat(review.rating);
      return rating >= star - 0.5 && rating <= star + 0.5;
    }).length,
  }));

  const handleRatingSubmit = () => {
    if (userRating) {
      alert(`Thank you for your rating!`);
    }
  };

  return (
    reviews && (
      <MainDiv
        style={{
          flexDirection: isMobile ? "column" : "row",
          width: "90%",
        }}
      >
        <ReviewSection>
          <SectionTitle>Customer Reviews</SectionTitle>

          <Row
            style={{
              width: "100%",
              flexDirection: isMobile ? "column" : "row",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <AverageRating>
              <RatingText>
                <Stars style={{ fontSize: "21px", marginRight: "5px" }}>
                  {renderStars(averageRating)}
                </Stars>
                {averageRating.toFixed(1)} out of 5
              </RatingText>
              <RatingText style={{ marginTop: "8px" }}>
                Based on {reviews.length} reviews
              </RatingText>
            </AverageRating>

            {!isMobile && (
              <div
                style={{
                  height: "120px",
                  width: "1px",
                  backgroundColor: "#dbdeda",
                }}
              />
            )}

            <StarDistribution>
              {ratingDistribution.map(({ star, count }) => (
                <StarRow key={star}>
                  <Stars style={{ fontSize: "16px" }}>
                    {renderStars(star)}
                  </Stars>
                  <ProgressBar>
                    <Progress width={(count / totalReviews) * 100} />
                  </ProgressBar>
                  <ReviewCount>{count}</ReviewCount>
                </StarRow>
              ))}
            </StarDistribution>

            {!isMobile && (
              <div
                style={{
                  height: "120px",
                  width: "1px",
                  backgroundColor: "#dbdeda",
                }}
              />
            )}

            <WriteReviewButton
              onClick={() => {
                setShowWriteAReview(!showWriteAReview);
              }}
            >
              {showWriteAReview ? "Cancel Review" : "Write a review"}
            </WriteReviewButton>
          </Row>

          {showWriteAReview && (
            <>
              <div
                style={{
                  height: "1px",
                  width: "100%",
                  backgroundColor: "#dbdeda",
                  marginTop: "20px",
                }}
              />
              <AddReviewDiv
                style={{
                  padding: isMobile ? "0px" : "10px 0px 30px 0px",
                  marginTop: isMobile ? "20px" : "0px",
                  width: isMobile ? "95%" : "34%",
                }}
              >
                <SectionTitle style={{ fontWeight: "700" }}>
                  Write a Review
                </SectionTitle>

                <Text style={{ marginBottom: "10px" }}>Slide To Rate</Text>
                <RatingSlider
                  userRating={userRating}
                  setUserRating={setUserRating}
                />
                {/* {userRating && <p>Your rating: {userRating} stars</p>} */}

                <Text style={{ marginBottom: "10px" }}>Write Your Review</Text>
                <TextArea placeholder="Write your review here" />
                <Text style={{ marginBottom: "10px" }}>
                  Name (displayed publicly like Rohit Singh)
                </Text>
                <Input
                  style={{ minHeight: "35px" }}
                  placeholder="Your Name (Public)"
                />
                <Text style={{ marginBottom: "10px" }}>
                  Which State are your from in India?
                </Text>
                <Input
                  style={{ minHeight: "35px" }}
                  placeholder="eg. Delhi, Haryana, Punjab etc.."
                />
                <Text style={{ marginBottom: "10px" }}>
                  Email (This will not be displayed)
                </Text>
                <Input style={{ minHeight: "35px" }} placeholder="Your Email" />
                <Text>
                  How we use your data: We’ll only contact you about the review
                  you left, and only if necessary. By submitting your review,
                  you agree to The Prime Provisions’s terms, privacy and content
                  policies.
                </Text>

                <Row style={{ justifyContent: "center" }}>
                  <WriteReviewButton
                    style={{
                      padding: "11px 30px",
                      border: "2px solid #211800",
                      backgroundColor: "transparent",
                      color: "#211800",
                    }}
                    onClick={() => {
                      setShowWriteAReview(!showWriteAReview);
                    }}
                  >
                    Cancel Review
                  </WriteReviewButton>
                  <WriteReviewButton
                    style={{ padding: "11px 30px", marginLeft: "20px" }}
                    onClick={() => {
                      handleRatingSubmit();
                    }}
                  >
                    Submit Review
                  </WriteReviewButton>
                </Row>
              </AddReviewDiv>
            </>
          )}
        </ReviewSection>

        <ReviewList>
          {reviews.map((review, index) => (
            <ReviewsCardDiv
              includeProductName={false}
              key={index}
              stars={review.rating}
              name={review.name}
              imageSrc={review.imageUrl}
              location={review.location}
              title={review.title}
              date={review.createAt}
              rating={review.rating}
              review={review.review}
            />
          ))}
        </ReviewList>

        <Text
          style={{
            marginTop: "30px",
            fontWeight: "600",
            textDecoration: "underline",
          }}
        >
          View All Reviews
        </Text>
      </MainDiv>
    )
  );
}

export default Reviews;

export function ReviewsCardDiv({
  name,
  stars,
  location,
  productName,
  imageSrc,
  review,
  date,
  rating,
  onClick,
  includeProductName,
}) {
  return (
    <ReviewsCard
      onClick={() => {
        if (includeProductName) {
          onClick();
        }
      }}
    >
      <Row
        style={{
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Stars style={{ marginRight: "5px" }}>{renderStars(stars)}</Stars>

        <RatingText
          style={{
            fontWeight: "400",
            fontSize: "12px",
            padding: "3px 5px",
            backgroundColor: "#6c9c36",
            color: "white",
          }}
        >
          {/* {date} */}
          {rating}
        </RatingText>
      </Row>
      <Row
        style={{
          width: "100%",
          alignItems: "start",
          marginTop: "10px",
        }}
      >
        <Person3OutlinedIcon
          style={{
            padding: "8px",
            backgroundColor: "#EFEFEF",
            color: "black",
            borderRadius: "10px",
            marginRight: "10px",
          }}
        />
        <div>
          <RatingText style={{ fontWeight: "600" }}>{name}</RatingText>
          <RatingText
            style={{
              fontWeight: "400",
              fontSize: "10.5px",
              marginTop: "6px",
            }}
          >
            {location ? location : "from Unknown, Unknown"}
          </RatingText>
        </div>
      </Row>

      <RatingText style={{ marginTop: "10px", textAlign: "start",fontWeight: "400", }}>
        {review}
        {/* ❝ {review} ❞ */}
      </RatingText>

      {imageSrc !== "" && <ReviewImage src={imageSrc} alt="Review Image" />}

      {includeProductName && (
        <RatingText
          style={{
            fontWeight: "600",
            fontSize: "12px",
            // color: "#1e1e1e",
            marginTop: "10px",
          }}
        >
          Review For :{" "}
          {productName ? productName : "- The Prime Provision Special"}
        </RatingText>
      )}

      {/* {includeProductName && (
        <RatingText
          style={{
            fontWeight: "400",
            fontSize: "12px",
            marginTop: "20px",
            textDecoration: "underline",
          }}
        >
          Tap To View More
        </RatingText>
      )} */}
    </ReviewsCard>
  );
}

const RatingSlider = ({ userRating, setUserRating }) => {
  const handleChange = (event, newValue) => {
    setUserRating(newValue);
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {/* <Typography gutterBottom>Rate this product:</Typography> */}
      <Slider
        value={userRating || 0}
        onChange={handleChange}
        aria-labelledby="continuous-slider"
        step={0.1}
        min={1}
        max={5}
        valueLabelDisplay="auto"
        marks
        sx={{
          color: "#ffa51e",
          "& .MuiSlider-thumb": {
            backgroundColor: "#ff771c",
          },
          "& .MuiSlider-track": {
            height: 8,
          },
          "& .MuiSlider-rail": {
            height: 8,
            opacity: 0.3,
          },
        }}
        style={{ width: "80%", maxWidth: "400px" }}
      />
      <Typography variant="h6">
        {userRating && userRating.toFixed(1)}
      </Typography>
    </div>
  );
};

// const GiveRatingCard = ({ userRating, setUserRating }) => {
//   const [hover, setHover] = useState(null);
//   const [ratingHover, setRatingHover] = useState(null);

//   const handleClick = (ratingValue) => {
//     setUserRating(ratingValue);
//   };

//   const handleMouseEnter = (ratingValue) => {
//     setHover(ratingValue);
//   };

//   const handleMouseLeave = () => {
//     setHover(null);
//   };

//   const handleStarMouseMove = (e, starValue) => {
//     const { left, width } = e.currentTarget.getBoundingClientRect();
//     const mouseX = e.clientX - left;
//     const rating = (Math.floor(mouseX / (width / 5)) + 0.5).toFixed(1);
//     setRatingHover(Math.min(starValue, rating));
//   };

//   const handleMouseUp = () => {
//     if (ratingHover !== null) {
//       setUserRating(ratingHover);
//     }
//   };

//   return (
//     <StarContainer
//       onMouseLeave={handleMouseLeave}
//     >
//       {[1, 2, 3, 4, 5].map((starValue) => (
//         <Star
//           key={starValue}
//           onClick={() => handleClick(starValue)}
//           onMouseEnter={() => handleMouseEnter(starValue)}
//           onMouseMove={(e) => handleStarMouseMove(e, starValue)}
//           onMouseUp={handleMouseUp}
//           isFilled={starValue <= (hover || userRating)}
//         >
//           <FaStar />
//         </Star>
//       ))}
//     </StarContainer>
//   );
// };

const MainDiv = styled.div`
  margin: 10px auto;
`;

const RatingsDiv = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px 0px;
  font-size: 20px;
`;

const Row = styled.div`
  display: flex;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Heading = styled.h2`
  max-width: 90%;
  margin: 0px;
  font-size: 14px;
  font-weight: 600;
`;

const AddReviewDiv = styled.div`
  border-radius: 5px;
`;

const Text = styled.p`
  font-size: 13px;
  font-weight: 400;
  margin: 23px 0px;
`;

const Input = styled.input`
  width: 100%;
  min-height: 150px;
  padding-left: 15px;
  text-align: start;
  border-radius: 30px;
  border: 0.5px solid #dedcdc;

  @media (max-width: 768px) {
    width: 92%;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  min-height: 150px;
  padding: 15px 10px;
  text-align: start;
  border-radius: 10px;
  line-height: 22px;
  border: 0.5px solid #dedcdc;
  resize: vertical;

  @media (max-width: 768px) {
    width: 92%;
  }
`;

const SubmitButton = styled.button`
  border: none;
  background-color: #041f51;
  color: white;
  font-weight: 600;
  padding: 14px 20px;
  margin-top: 20px;
  cursor: pointer;
`;

const ReviewSection = styled.div`
  padding-top: 5px;
  padding-bottom: 30px;
  border-radius: 10px;
  width: 100%;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 5px 40px rgba(0, 0, 0, 0.1);
`;

const ReviewsCard = styled.div`
  padding: 15px 15px 30px 15px;
  border-radius: 10px;
  background-color: white;
  color: black;
  box-shadow: 0 5px 40px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.5s ease-in-out;
  @media (max-width: 768px) {
    cursor: auto;
  }

  &:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    background-color: #f0ebeb;
  }
`;

const SectionTitle = styled.h2`
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 20px;
  color: #333;
`;

const AverageRating = styled.div``;

const RatingText = styled.span`
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  text-align: start;

  @media (max-width: 767px) {
    font-size: 3.3vw;
  }
`;

const ReviewImage = styled.img`
  width: 90%;
  height: auto;
  object-fit: contain;
  aspect-ratio: 1;
  margin-top: 15px;
`;

const Stars = styled.div`
  display: flex;
  align-items: center;

  svg {
    color: #ffa41c;
    margin-right: 3px;
  }
`;

const Star = styled.div`
  cursor: pointer;
  svg {
    color: ${(props) => (props.isFilled ? "#FFA41C" : "#e0e0e0")};
    width: 40px;
    height: 40px;
    transition: color 0.2s;
  }
`;

const StarDistribution = styled.div`
  width: 17%;
  margin-top: 5px;

  @media (max-width: 767px) {
    width: 80%;
    margin-top: 30px;
  }
`;

const StarRow = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 9px;

  &:hover {
    opacity: 0.5;
  }
`;

const StarLabel = styled.div`
  width: 50px;
  display: flex;
  align-items: center;
  svg {
    color: #ffa41c;
    margin-left: 5px;
  }
`;

const ProgressBar = styled.div`
  flex: 1;
  height: 14px;
  background-color: #e0e0e0;
  border-radius: 20px;
  margin: 0 20px;
  position: relative;
`;

const Progress = styled.div`
  height: 100%;
  background-color: #ffa41c;
  border-radius: 20px;
  width: ${(props) => props.width}%;
  transition: width 0.3s ease;
`;

const ReviewCount = styled.div`
  font-size: 13px;
  text-align: right;
  margin-left: 10px;
`;

const WriteReviewButton = styled.button`
  padding: 13px 70px;
  border: none;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 600;
  color: white;
  background-color: #211800;
  cursor: pointer;

  &:hover {
    background-color: #333333;
  }

  @media (max-width: 767px) {
    margin-top: 20px;
  }
`;

const ReviewList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

const ReviewItem = styled.div`
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ReviewHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const ReviewerName = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: #333;
`;

const ReviewComment = styled.p`
  font-size: 16px;
  color: #555;
  margin: 0;
`;

import React from "react";

const CancellationAndRefund = () => {
  return (
    <div
      style={{
        padding: "50px 20px 100px 20px",
        maxWidth: "800px",
        margin: "auto",
        textAlign: "start",
      }}
    >
      <h1>Cancellation and Refund Policy</h1>
      <p>
        At The Prime Provisions, we strive to ensure your satisfaction with
        every purchase. If you need to cancel your order, please do so within
        the same day of placing it. Refunds will be processed within the same
        day of the cancellation request.
      </p>

      <h2>1. Order Cancellation</h2>
      <p>
        You may cancel your order before it is shipped by contacting us
        immediately at 9992048539 or emailing us at
        theprimeprovisions@gmail.com. If your order has already been shipped,
        you will need to follow our return process.
      </p>

      <h2>2. Refund Process</h2>
      <p>
        Once your cancellation request is approved, we will initiate a refund to
        your original method of payment. You will receive the credit within a
        certain number of days, depending on your card issuer's policies.
      </p>

      <h2>3. Exceptions to Refund Policy</h2>
      <p>
        Certain items, such as perishable goods, custom products, or items that
        have been used or damaged, may not be eligible for a refund. Please
        refer to the product description for details on non-refundable items.
      </p>

      <h2>4. Contact Us</h2>
      <p>
        If you have any questions about our Cancellation and Refund Policy,
        please contact us at:
      </p>
      <ul>
        <li>
          <strong>Email:</strong>{" "}
          <a href="mailto:theprimeprovisions@gmail.com">
            theprimeprovisions@gmail.com
          </a>
        </li>
        <li>
          <strong>Phone:</strong> +919992048539
        </li>
      </ul>
    </div>
  );
};

export default CancellationAndRefund;

import React from "react";
import { Parallax } from "react-scroll-parallax";
import styled from "styled-components";

function FromWhereWeSource({ isMobile }) {
  return (
    <Column
      style={{
        width: isMobile ? "90%" : "55%",
        marginTop: "150px",
        backgroundColor: "white",
        position: "relative",
        alignItems: "center",
      }}
    >
      <Heading>
        From Where do We <br /> Source Products?
      </Heading>

      <Banner src="https://images.squarespace-cdn.com/content/v1/5c0c16cf12b13fa421e4f5f7/7a5c0cb8-2685-445f-a9de-1a1d29b6c8a5/te-line-6.png" />

      {/* <Parallax
        translateY={isMobile ? ["30%", "-80%"] : ["0%", "-70%"]}
        speed={1}
        easing={"ease"}
        style={{ position: "absolute", width: "100%", zIndex: "0" }}
      >
        <Heading>
          From Where do We <br /> Source Products?
        </Heading>
      </Parallax> */}

      <Heading
        style={{
          fontSize: isMobile ? "4vw" : "1.3vw",
          marginTop: "2vh",
          fontWeight: "500",
          textAlign: "center",
          backgroundColor: "white",
          zIndex: "2",
          fontFamily: "unset",
        }}
      >
        <span
          style={{ fontSize: isMobile ? "7vw" : "2.8vw", fontWeight: "700" }}
        >
          10,000+ <br />
        </span>{" "}
        <br />❝ Original Products from Qriginal Manufacturers at Original Prices
        and Original Quality.❞
      </Heading>
      <img
        style={{ marginTop: "20px", width: isMobile ? "100%" : "60%" }}
        src="https://media.istockphoto.com/id/1048818390/vector/warehouse-interior-with-boxes-on-rack-and-people-working-flat-vector-and-solid-color-style.jpg?s=612x612&w=0&k=20&c=nIzGWh47LzqiHiVn4gTyHH4M0K7JKqDHd_P8ulCyX3Y="
        alt="bg-image"
      />
      <Text
        style={{
          width: "90%",
          fontSize: isMobile ? "3.4vw" : "0.9vw",
          textAlign: "center",
          marginTop: isMobile ? "30px" : "40px",
        }}
      >
        We source our Products directly from the Original Factories /
        Manufacturers {"(India, Germany, Korea, Hong Kong and USA)"} in order to
        provide you with the Original Price and maintaining the Original
        Quality.
        <br />
        <br />
      </Text>
    </Column>
  );
}

export default FromWhereWeSource;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Heading = styled.h1`
  margin-left: 15px;
  font-size: 2.2vw;
  font-weight: 600;

  @media (max-width: 768px) {
    /* font-family: cursive; */
    font-size: 7vw;
    margin-left: 0px;
    font-weight: 900;
    text-align: center;
  }
`;

const Text = styled.p`
  margin: 0 5px;
  margin-top: 15px;
  font-size: 3.4vw;
  font-weight: 500;
  line-height: 21px;
  text-align: start;
`;

const Banner = styled.img`
  width: 85%;
  height: 35px;
  opacity: 0.1;
  margin-bottom: 40px;
  /* margin-top: 10px; */
  object-fit: fill;

  @media (max-width: 768px) {
    width: 100%;
    height: 23px;
    margin-bottom: 10px;
  }
`;

const BlackDiv = styled.div`
  width: 100%;
  padding: 20px 10px;
  background-color: black;
`;

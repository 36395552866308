import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

function ForgotPassword() {
  const navigate = useNavigate();
  return (
    <Container>
      <Heading>Recover Password</Heading>

      <p style={{ fontSize: "14px" }}>Please enter your e-mail :</p>

      <TextInput placeholder="Email Address" />

      <ContinueButton>Recover</ContinueButton>

      <p
        style={{
          fontSize: "14px",
          marginBottom: "150px",
          marginTop: "10px",
          cursor: "pointer",
        }}
        onClick={() => navigate(-1)}
      >
        Remember your password?{" "}
        <span style={{ fontWeight: "bold" }}>Back to login</span>
      </p>
    </Container>
  );
}

export default ForgotPassword;

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const Heading = styled.h1`
  margin-top: 100px;
  letter-spacing: 0.02ch;

  @media (max-width: 768px) {
    font-size: 5vw;
    margin-top: 60px;
  }
`;


const TextInput = styled.input`
  width: 23vw;
  height: auto;
  padding: 14px 0px;
  font-size: 14px;
  color: #1c1c1c;
  border: 1px solid #d8d7d7;
  padding-left: 10px;

  &:focus{
    border: 1px solid #0095f6;
    outline: none;
  }


  @media (max-width: 768px) {
    width: 80vw;
    font-size: 3.3vw;
  }
`;

const ContinueButton = styled.button`
  border: none;
  width: 23.5vw;
  padding: 17px 0px;
  font-size: 19px;
  background-color: black;
  color: white;
  margin: 20px 0px 10px 0px;
  cursor: pointer;

  &:hover {
    background-color: #507d3c;
  }

  @media (max-width: 768px) {
    width: 83vw;
    font-size: 3.6vw;
  }
`;

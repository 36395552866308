import React from "react";
import styled from "styled-components";
// import Search from "../../../../header/components/Search";
// import ProductCard from "./components/ProductCard";
import BasedOnInstgram from "./components/BasedOnInstgram";
import BestSellers from "./components/BestSellers";
import BasedOnCategories from "./components/BasedOnCategories";
import { useProducts } from "../../../../utils/ContextAPI/products/ProductsContext";
import SkeletonLoader from "../../../../Global/Loading_Skeleton/SkeletonLoaderCart";
import SkeletonLoaderProducts from "../../../../Global/Loading_Skeleton/SkeletonLoaderProducts";

function AllProducts({ isMobile, products }) {
  const { loading } = useProducts();

  return products && !loading ? (
    <Column isMobile={isMobile}>
      <BestSellers
        isMobile={isMobile}
        products={products.filter((product) =>
          product.tags.includes("Best Sellers")
        )}
      />
      <BasedOnInstgram
        isMobile={isMobile}
        products={products.filter((product) =>
          product.tags.includes("Instagram")
        )}
      />
      <BasedOnCategories products={products} />
    </Column>
  ) : (
    <Column style={{marginTop:0,}}>
      <SkeletonLoaderProducts howmany={6} />
    </Column>
  );
}

export default AllProducts;

const Column = styled.div`
  width: 100%;
  margin-top: ${(props) => (props.isMobile ? "1.5vh" : "18vh")};
  display: flex;
  flex-direction: column;
`;

import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
// import JustBoughtNotification from "../JustBoughtNotification/JustBoughtNotification";
// import { useCart } from "../../../Cart/components/CartContext";
// import { renderStars } from "../../../routes/products/components/Reviews";
import { useMediaQuery } from "@mui/material";

function ProductCardSmaller({ isFirst, product, imageSize }) {
  const isMobile = useMediaQuery("(max-width:768px)");

  const navigate = useNavigate();

  // const { dispatch } = useCart();

  // const addToCart = () => {
  //   dispatch({
  //     type: "ADD_ITEM",
  //     payload: { productId: product.id && product.id },
  //   });
  // };

  return (
    <MainDiv
      style={{
        marginLeft: isFirst ? "0" : "10px",
        border: "0.5px solid #eae8e8",
        borderRadius: "20px",
        overflow: "hidden",
        padding: isMobile ? "15px 0px" : "10px 0px 15px 0px",
      }}
    >
      <Row
        style={{
          flexDirection: "column",
          alignItems: "center",
        }}
        onClick={() => {
          navigate(`/products/${product.id}/`, {
            state: { product: product && product },
          });
        }}
      >
        <Image
          imageSize={imageSize}
          src={product.imageUrls.ShowCaseURL && product.imageUrls.ShowCaseURL}
          alt={product.name && product.name + " Image"}
        />
        <Column>
          <Name>{product.name && product.name}</Name>
          <BestInText>Best In {product.category}</BestInText>
        </Column>
      </Row>

      <Price>
        ₹{product.prices.SellingPrice}
        <ShowCasePrice>
          {" "}
          ₹{product.prices["ComparedShowCase Price"]}{" "}
        </ShowCasePrice>
      </Price>

      {/* <ViewButton
        onClick={(e) => {
          e.stopPropagation();
          addToCart();
        }}
      >
        Add To Cart
      </ViewButton> */}
    </MainDiv>
  );
}

export default ProductCardSmaller;

const MainDiv = styled.div`
  position: relative;
  width: 14vw;
  display: flex;
  flex-direction: column;
  justify-content: start;
  flex-shrink: 0;
  align-items: center;
  background-color: white;
  /* padding: 10px; */
  padding-bottom: 2px;
  cursor: pointer;
  transition: all 0.5s ease;

  @media (max-width: 768px) {
    width: 45vw;
    align-items: start;
    padding: 2px;
    cursor: auto;
  }
`;

const Row = styled.div`
  display: flex;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  align-self: start;
  justify-content: space-between;
`;

const Image = styled.img`
  width: ${(props) => (props.imageSize ? props.imageSize : "95%")};
  height: auto;
  object-fit: contain;
  aspect-ratio: 1;
  margin: 0 auto;
  /* margin-top: 3px; */

  @media (max-width: 768px) {
    width: ${(props) => (props.imageSize ? props.imageSize : "44vw")};
  }
`;

const Name = styled.h1`
  width: 12.5vw;
  text-align: start;
  align-self: flex-start;
  font-size: 1vw;
  font-weight: 600;
  margin: 20px 10px 0px 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 768px) {
    width: 44vw;
    font-size: 4.3vw;
  }
`;

const Price = styled.h1`
  display: flex;
  align-items: center;
  align-self: flex-start;
  font-size: 1.2vw;
  font-weight: 600;
  margin: 8px 0px 0px 15px;

  @media (max-width: 768px) {
    font-size: 4.6vw;
    margin: 10px 10px 0px 15px;
  }
`;

const ShowCasePrice = styled.h1`
  font-size: 0.85vw;
  color: #858585;
  font-weight: 600;
  margin: 0;
  margin-left: 5px;
  text-decoration: line-through;
  letter-spacing: 0.07ch;

  @media (max-width: 768px) {
    font-size: 3.4vw;
  }
`;

const BestInText = styled.p`
  background-color: #ffd900;
  text-align: center;
  padding: 2px 10px;
  font-size: 0.7vw;
  margin: 5px 10px 0px 15px;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 768px) {
    font-size: 2.8vw;
    font-weight: 500;
  }
`;

// const ViewButton = styled.button`
//   width: 85%;
//   border: none;
//   border-radius: 50px;
//   font-size: 0.9vw;
//   margin: 15px auto 8px auto;
//   padding: 12px 8px;
//   /* background-color: #045df6; */
//   background-color: #045df6;
//   color: white;
//   cursor: pointer;
//   &:hover {
//     background-color: #037562;
//   }

//   @media (max-width: 768px) {
//     font-size: 3.6vw;
//     width: 88%;
//     padding: 13px 6px;
//     margin: 15px auto 8px auto;
//   }
// `;

import React from "react";

const ShippingAndDelivery = () => {
  return (
    <div
      style={{
        padding: "50px 20px 100px 20px",
        maxWidth: "800px",
        margin: "auto",
        textAlign: "start",
      }}
    >
      <h1>Shipping and Delivery Policy</h1>
      <p>
        At The Prime Provisions, we are committed to ensuring that your orders
        are delivered to you in a timely and efficient manner. This Shipping and
        Delivery Policy outlines our shipping practices and policies.
      </p>

      <h2>1. Shipping Methods</h2>
      <p>
        We offer several shipping methods to meet your needs. The available
        shipping options will be presented to you at checkout, and you can
        select the method that best suits your preferences.
      </p>

      <h2>2. Shipping Costs</h2>
      <p>
        Shipping costs are calculated based on the weight of your order, the
        shipping method selected, and your delivery location. These costs will
        be displayed at checkout before you complete your purchase.
      </p>

      <h2>3. Delivery Times</h2>
      <p>
        Delivery times vary depending on your location and the shipping method
        chosen. Typically, orders are processed within 1-2 business days.
        Estimated delivery times will be provided at checkout.
      </p>

      <h2>4. Order Tracking</h2>
      <p>
        Once your order has been shipped, you will receive a confirmation email
        with tracking information. You can use this information to monitor the
        progress of your shipment.
      </p>

      <h2>5. International Shipping</h2>
      <p>
        Currently, we only ship within India. We do not offer international
        shipping at this time. If you are located outside of India, please check
        back as we may expand our shipping options in the future.
      </p>

      <h2>6. Lost or Damaged Items</h2>
      <p>
        If your order is lost or damaged during shipping, please contact us
        immediately at 9992048539 or email us at theprimeprovisions@gmail.com.
        We will work with the carrier to resolve the issue and ensure that you
        receive your items in good condition.
      </p>

      <h2>7. Contact Us</h2>
      <p>
        If you have any questions about our Shipping and Delivery Policy, please
        contact us at:
      </p>
      <ul>
        <li>
          <strong>Email:</strong>{" "}
          <a href="mailto:theprimeprovisions@gmail.com">
            theprimeprovisions@gmail.com
          </a>
        </li>
        <li>
          <strong>Phone:</strong> +919992048539
        </li>
      </ul>
    </div>
  );
};

export default ShippingAndDelivery;

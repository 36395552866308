import React from "react";
import styled from "styled-components";
import logo from "../assets/logo/theprimeprovisionswhite.png";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();

  return (
    <MainDiv>
      <img width={"25%"} src={logo} alt="" />
      <Text style={{ fontSize: "3.3vw", margin: "20px" }}>
        {" "}
        A Problem Solving Unique Products Store that offer variety of differnt
        Home, Kitchen and Technology Products that can make you and your Life
        Smarter.
      </Text>
      <Row>
        <Column
          style={{ marginLeft: "20px", marginTop: "10px", textAlign: "start" }}
        >
          <Heading>Categories</Heading>
          <Text>Latest</Text>
          <Text>Most Useful</Text>
          <Text>Best Sellers</Text>
          <Text>Home</Text>
          <Text>Kitchen</Text>
          <Text>Technology</Text>
          <Text>Kids & Toys</Text>
          <Text>Men Specific</Text>
          <Text>Women Related</Text>
        </Column>

        <Column
          style={{ marginLeft: "20px", marginTop: "10px", textAlign: "start" }}
        >
          <Heading>Legal Terms</Heading>
          <Text
            onClick={() => {
              navigate("/policy/contact_us");
            }}
          >
            Contact Us
          </Text>
          <Text
            onClick={() => {
              navigate("/policy/terms");
            }}
          >
            Terms and Conditions
          </Text>
          <Text
            onClick={() => {
              navigate("/policy/privacy");
            }}
          >
            Privacy Policy
          </Text>
          <Text
            onClick={() => {
              navigate("/policy/refund");
            }}
          >
            Cancellation & Refund
          </Text>
          <Text
            onClick={() => {
              navigate("/policy/shipping");
            }}
          >
            Shipping & Delivery
          </Text>
        </Column>
      </Row>
      <BottomDiv>
        <Text style={{ fontSize: "2.5vw", margin: 0 }}>
          © 2024 The Prime Provisions All Rights Reserved
        </Text>
      </BottomDiv>
    </MainDiv>
  );
}

export default Footer;

const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px 0px 0px 0px;
  border-radius: 5px;
  background-color: #2856a5;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  width: 95%;
  display: flex;
  justify-content: space-between;
`;

const Heading = styled.h1`
  margin: 0;
  margin-bottom: 10px;
  font-size: 4.2vw;
  font-weight: 700;
  color: white;
`;

const Text = styled.p`
  margin: 0;
  margin-top: 10px;
  font-size: 3.6vw;
  font-weight: 400;
  color: #c7c5c5;
`;

const BottomDiv = styled.div`
  width: 100%;
  background-color: #072a66;
  margin-top: 30px;
  padding: 10px 0px;
`;

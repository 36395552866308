import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import AboutandImages from "./components/AboutandImages";
import Reviews from "./components/Reviews";
import WhoAreWe from "../home/components/WhoAreWe/WhoAreWe";
import { ParallaxProvider } from "react-scroll-parallax";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import SkeletonLoaderProduct from "../../Global/Loading_Skeleton/SkeletonLoaderProduct";
import CustomerReviews from "../home/components/customerReviews/CustomerReviews";

function Products({ isMobile }) {
  const navigate = useNavigate();
  const location = useLocation();
  const productFromState = location.state?.product;
  const [product, setProduct] = useState(productFromState || null);
  const { id } = useParams();
  const db = getFirestore();

  useEffect(() => {
    const fetchProduct = async () => {
      if (product == null && id) {
        try {
          const productDoc = await getDoc(doc(db, "products", id));
          if (productDoc.exists()) {
            setProduct(productDoc.data());
          } else {
            navigate("/");
          }
        } catch (error) {
          navigate("/");
        }
      }
    };

    fetchProduct();
  }, [id]);

  return product ? (
    <Column>
      <AboutandImages
        name={product.name}
        category={product.category}
        isMobile={isMobile}
        product={product}
        cover1={product.imageUrls.OrganicImageURL}
      />

      <Reviews isMobile={isMobile} reviews={product.reviews} />

      <ParallaxProvider>
        <WhoAreWe isMobile={isMobile} />
      </ParallaxProvider>

      <CustomerReviews isMobile={isMobile} />

      {/* <JustBoughtNotification isAbsolute={true} customRandom={7000} /> */}
    </Column>
  ) : (
    <Column style={{ marginTop: 0 }}>
      <SkeletonLoaderProduct howmany={1} isMobile={isMobile}/>
    </Column>
  );
}

export default Products;

const Row = styled.div`
  display: flex;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: white;
`;

import React from "react";
import styled from "styled-components";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
// import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// import MyLocationIcon from "@mui/icons-material/MyLocation";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import { useLocation, useNavigate } from "react-router-dom";

const BottomNavigationBar = ({ toggleChat, isVisible }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <NavBar isVisible={isVisible} className="change-zIndex">
      {/* <HomeIcon
        style={{ fontSize: "25px" }}
        onClick={() => {
          if (location.pathname !== "/") {
            navigate("/");
          }
        }}
      />
      <ShoppingCartIcon
        style={{ fontSize: "25px" }}
        onClick={() => {
          toggleCart();
        }}
      />
      <CardGiftcardIcon style={{ fontSize: "25px" }}  onClick={() => {
          if (location.pathname !== "/gifts-and-offers") {
            navigate("/gifts-and-offers");
          }
        }}/>
      <AccountCircleIcon
        style={{ fontSize: "25px" }}
        onClick={() => {
          if (location.pathname !== "/account/login") {
            navigate("/account/login");
          }
        }}
      /> */}

      <NavItem
        onClick={() => {
          toggleChat();
        }}
      >
        <SupportAgentIcon />
        <NavText>Help</NavText>
      </NavItem>
      <NavItem
        onClick={() => {
          if (location.pathname !== "/cart") {
            navigate("/cart");
          }
        }}
      >
        <ShoppingCartIcon />
        <NavText>Cart</NavText>
      </NavItem>
      <NavItem
        onClick={() => {
          if (location.pathname !== "/categories/all") {
            navigate("/categories/all");
          }
        }}
      >
        <CategoryOutlinedIcon />
        <NavText>Categories</NavText>
      </NavItem>
      <NavItem
        onClick={() => {
          if (location.pathname !== "/account/login") {
            navigate("/account/login");
          }
        }}
      >
        <LocalMallOutlinedIcon />
        <NavText>My Orders</NavText>
      </NavItem>
    </NavBar>
  );
};

export default BottomNavigationBar;

const NavBar = styled.div`
  position: sticky;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  opacity: 0.95;
  /* transition: transform 0.3s ease-in-out;
  transform: ${(props) =>
    props.isVisible ? "translateY(0)" : "translateY(100%)"}; */
  z-index: 1000;
`;

const NavItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    font-size: 24px;
    color: #2b2002;
  }
`;

const NavText = styled.p`
  margin: 0;
  margin-top: 2px;
  font-size: 11px;
  color: #3a3a3a;
`;

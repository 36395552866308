import React from "react";
import styled from "styled-components";
import { Parallax } from "react-scroll-parallax";
import ProductCardHorizontal from "../../../../../utils/Product/ProductCards/ProductCardHorizontal";
import { useNavigate } from "react-router-dom";

function BasedOnInstgram({ isMobile, products }) {
  const navigate = useNavigate();
  const openInstagram = (username) => {
    // const instagramUrl = `https://ig.me/m/${username}`;
    const instagramUrl = `https://www.instagram.com/${username}`;
    window.open(instagramUrl, "_blank");
  };

  return (
    products &&
    products.length > 0 && (
      <Column>
        <Parallax
          translateY={["90%", "-30%"]}
          speed={0.5}
          easing={"ease"}
          style={{
            zIndex: 0,
            position: "absolute",
            display: "flex",
            width: isMobile ? "98%" : "85%",
            justifyContent: "flex-end",
          }}
        >
          <img
            style={{
              width: isMobile ? "50vw" : "22vw",
              height: "auto",
              opacity: "0.2",
            }}
            src="https://cdn4.iconfinder.com/data/icons/social-media-logos-6/512/62-instagram-512.png"
            alt="instagram"
          />
        </Parallax>

        <div style={{ zIndex: 2 }}>
          <Heading>Based On Instagram</Heading>

          <Heading
            style={{
              zIndex: 1,
              fontSize: isMobile ? "3.1vw" : "1vw",
              fontWeight: "600",
              fontFamily: "inherit",
              textDecoration: "underline",
              cursor: "grab",
              marginTop: isMobile ? "-10px" : "-20px",
              marginBottom: isMobile ? "40px" : "60px",
            }}
            onClick={() => {
              openInstagram("the.prime.provisions");
            }}
          >
            @the.prime.provisions
          </Heading>
        </div>

        <Row>
          {products.map((product, index) => (
            <ProductCardHorizontal
              key={index}
              isFirst={index === 0}
              product={product}
            />
          ))}
        </Row>

        <ViewAllProductsButton
          onClick={() => {
            {
              navigate("/collections/Instragram/all");
            }
          }}
        >
          EXPLORE ALL INSTAGRAM PRODUCTS
        </ViewAllProductsButton>
      </Column>
    )
  );
}

{
  /* 
  
  Old Products

  <ProductCardHorizontal
        productId={"home-shoe-wipes-6058-e26b-f0fe"}
          isFirst
          imageSrc="https://images-cdn.ubuy.co.in/657531b82270b43c0a75e7b1-phomemo-m110-label-makers-barcode.jpg"
          name={"Phomemo Printer"}
          cover1="https://images-cdn.ubuy.co.in/657531b82270b43c0a75e7b1-phomemo-m110-label-makers-barcode.jpg"
          title={"A Printer that can make your Business!"}
          description={
            "If you are a stundent or a business owner, you need to check out this Mini printer!"
          }
          category={"Useful Tech, Printers"}
          comparedPrice={"999"}
          price={"499"}
          rating={"4.5"}
        />
        <ProductCardHorizontal
          imageSrc="https://rukminim2.flixcart.com/image/850/1000/xif0q/portable-projector/q/w/q/led-android-portable-mini-projector-for-home-4k-1080p-hd-wifi-original-imagsfg8twwfgdds.jpeg?q=90&crop=false"
          cover1="https://rukminim2.flixcart.com/image/850/1000/xif0q/portable-projector/q/w/q/led-android-portable-mini-projector-for-home-4k-1080p-hd-wifi-original-imagsfg8twwfgdds.jpeg?q=90&crop=false"
          title={"You don't need to have a TV Now !!"}
          description={
            "If you are a stundent or a business owner, you need to check out this Mini printer!"
          }
          name={"Mini Android Projector"}
          category={"Useful Tech, Projectors"}
          comparedPrice={"999"}
          price={"499"}
          rating={"4.5"}
        />

        <ProductCardHorizontal
          imageSrc="https://crowcrowcrow.com/cdn/shop/files/51Tq-43n7xL._AC_SX466_b6308325-fb43-4904-813a-12cfce9e1351.jpg?v=1692004144"
          cover1="https://crowcrowcrow.com/cdn/shop/files/51Tq-43n7xL._AC_SX466_b6308325-fb43-4904-813a-12cfce9e1351.jpg?v=1692004144"
          title={"You can Explore Quantum World with this!"}
          description={
            "If you are a stundent or a business owner, you need to check out this Mini printer!"
          }
          name={"400X Zoom Cover"}
          category={"Useful Tech, Covers"}
          comparedPrice={"999"}
          price={"499"}
          rating={"4.5"}
        />
        <ProductCardHorizontal
          imageSrc="https://m.media-amazon.com/images/I/71l+JbGKK9L._SL1329_.jpg"
          cover1="https://m.media-amazon.com/images/I/71l+JbGKK9L._SL1329_.jpg"
          title={"Bring Stars and Galaxies in your Bedroom!"}
          description={
            "If you are a stundent or a business owner, you need to check out this Mini printer!"
          }
          name={"Astronaut Projector"}
          category={"Useful Tech, Projectors"}
          comparedPrice={"999"}
          price={"499"}
          rating={"4.5"}
        />

        <ProductCardHorizontal
          imageSrc="https://thegreytechnologies.in/cdn/shop/products/portable-washing-machine-3_2000x.jpg?v=1688381901"
          cover1="https://thegreytechnologies.in/cdn/shop/products/portable-washing-machine-3_2000x.jpg?v=1688381901"
          title={"A Washing Machine that Folds!"}
          description={
            "If you are a stundent or a business owner, you need to check out this Mini printer!"
          }
          name={"Washing Machine"}
          category={"Home, Laundary"}
          comparedPrice={"999"}
          price={"499"}
          rating={"4.5"}
        />
        <ProductCardHorizontal
          imageSrc="https://img.joomcdn.net/94fe28469c5e82bd6e48fe1fdcb0df82e1b63892_original.jpeg"
          cover1="https://img.joomcdn.net/94fe28469c5e82bd6e48fe1fdcb0df82e1b63892_original.jpeg"
          title={"Are you also Tired of Small Smartphone Keyboard?!"}
          description={
            "If you are a stundent or a business owner, you need to check out this Mini printer!"
          }
          name={"Foldable Keyboard"}
          category={"Useful Tech"}
          comparedPrice={"999"}
          price={"499"}
          rating={"4.5"}
        />

        <ProductCardHorizontal
          imageSrc="https://rukminim2.flixcart.com/image/850/1000/kwcfngw0/home-security-camera/k/i/i/1-ccduyr76-indoor-outdoor-security-camera-jronj-original-imag9fmze86d5tfx.jpeg?q=90&crop=false"
          cover1="https://rukminim2.flixcart.com/image/850/1000/kwcfngw0/home-security-camera/k/i/i/1-ccduyr76-indoor-outdoor-security-camera-jronj-original-imag9fmze86d5tfx.jpeg?q=90&crop=false"
          title={
            "You won't believe the Size and Features of this Mini Security Camera.."
          }
          description={
            "If you are a stundent or a business owner, you need to check out this Mini printer!"
          }
          name={"Mini Security Camera"}
          category={"Useful Tech"}
          comparedPrice={"999"}
          price={"499"}
          rating={"4.5"}
        /> */
}

export default BasedOnInstgram;

const Column = styled.div`
  /* width: 98%; */
  /* margin-top: 1.5vh; */
  display: flex;
  flex-direction: column;
  margin-top: 5vh;

  @media (max-width: 768px) {
    margin-top: 6vh;
  }
`;

const Row = styled.div`
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly; */
  width: 80%;
  margin: 0px auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px 0px;
  padding: 5px 0px;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  & {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  @media (max-width: 768px) {
    width: 100%;
    gap: 10px 0px;
    grid-template-columns: repeat(1, 1fr);
    padding: 0px;
  }
`;

const Heading = styled.h1`
  /* margin-right: 30vw; */
  font-size: 2.5vw;
  font-weight: 600;
  z-index: 1;

  @media (max-width: 768px) {
    font-size: 9vw;
    font-family: cursive;
    text-align: start;
    margin-left: 20px;
    font-weight: 900;
  }
`;

const TagDiv = styled.div`
  display: flex;
  overflow-x: auto;
  margin: 5px 0px 10px 15px;
`;

const Tag = styled.button`
  margin: 0px;
  margin-left: 10px;
  border-radius: 5px;
  border: 0.5px solid #7d7c7c;
  font-weight: 400;
  font-size: 13px;
  padding: 4px 10px;
  cursor: pointer;

  &:hover {
    background-color: #045df6;
    color: white;
  }
`;

const ViewAllProductsButton = styled.button`
  width: 100vw;
  border: none;
  border-bottom: 1px solid black;
  background-color: transparent;
  color: black;
  align-self: center;
  font-weight: 600;
  padding: 18px 0px;
  font-size: 0.9vw;
  margin-top: 60px;
  /* border-radius: 50px; */
  cursor: pointer;
  /* box-shadow: 0px 4px 20px 0px #8eb7fd; */
  transition: background-color 0.2s ease-in;

  &:hover {
    color: white;
    background-color: #037562;
  }

  @media (max-width: 768px) {
    font-size: 3.2vw;
    padding: 18px 0px;

    margin-top: 30px;
  }
`;

import React from "react";
import styled from "styled-components";

function QuestionsAndQueries({ isMobile }) {
  return (
    <Column
      style={{
        width: isMobile ? "95%" : "65%",
        padding: "20px 0px",
        marginTop: isMobile ? "30px" : "60px",
      }}
    >
      <Heading
        style={{
          fontSize: isMobile ? "5.5vw" : "2vw",
          marginTop: "0px",
          marginBottom: isMobile?"40px":"60px",
          textAlign: "center",
          textDecoration: "none",
        }}
      >
        Questions and Queries
      </Heading>
      <Heading>Why are these Products so cheap?</Heading>
      <Paragraph>
        Our low prices are due to several reasons - <br />
        1. Our Supply Chain runs directly from cosumer to seller. <br />
        This means that Prime Provisions can cut prices without a middleman
        unlike Amazon and Meesho. <br />
        <br />
        2. We also connect customers directly with manufacturers unlike
        traditional retailers that use a network of wholesalers, dirtributors,
        and retailers.
      </Paragraph>

      <Heading>How much time will it take to get my order?</Heading>
      <Paragraph>
        It depends on the product and your location. Usually, the delivery takes
        around 2-3 days but as we restock these products directly from
        Manufacturers for the best quality, sometimes it may take an extra day
        or 2.
      </Paragraph>

      <Heading>How can i Return/Replace a defective order?</Heading>
      <Paragraph>
        Go to My Orders / Order History / Product Name / In the End, Click on
        Return/Replace this order. <br />
        <br />
        Then you need to fill the form and show us the defective part of the
        order and we will replace the product within 1 week.
      </Paragraph>
    </Column>
  );
}

export default QuestionsAndQueries;

const Column = styled.div`
  background-color: white;
  text-align: start;
`;

const Heading = styled.h1`
  margin: 20px 20px 0 20px;
  font-size: 1.3vw;
  font-weight: 700;
  text-decoration: underline;
  text-align: start;

  @media (max-width: 768px) {
    font-size: 3.7vw;
    text-align: start;
  }
`;

const Paragraph = styled.p`
  margin: 10px 20px 0 20px;
  font-size: 0.8vw;
  text-align: start;
  @media (max-width: 768px) {
    font-size: 3.2vw;
  }
`;

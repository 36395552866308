import React from "react";
import styled, { keyframes } from "styled-components";
import Wave from "react-wavify";
// import SlidingTextBar from "./components/SlidingTextBar";

function Topbar({ isMobile }) {
  // const topBarTexts = ["100% TRUSTED", "FREE SHIPPING", "INDIA ONLY"];

  const openWhatsApp = (phoneNumber) => {
    const sanitizedPhoneNumber = phoneNumber.replace(/\D/g, "");

    const whatsappUrl = `https://wa.me/${sanitizedPhoneNumber}`;
    window.open(whatsappUrl, "_blank");
  };

  const openInstagram = (username) => {
    const instagramUrl = `https://ig.me/m/${username}`;
    // const instagramUrl = `https://www.instagram.com/${username}`;
    window.open(instagramUrl, "_blank");
  };

  return (
    <div>
      <Container isMobile={isMobile}>
        {/* SHIPPING ACROSS THE GLOBE, WITH LOVE! */}

        {!isMobile && (
          <Text style={{ marginLeft: "20px" }}>
            The India's Prime Provision
          </Text>
        )}
        <Text style={{ width: "100%" }}>
          100% TRUSTED | FREE SHIPPING | INDIA ONLY
        </Text>
        {/* <MarqueeContainer>
          <MarqueeText>100% TRUSTED | FREE SHIPPING | INDIA ONLY</MarqueeText>
        </MarqueeContainer> */}
        {/* <SlidingTextBar texts={topBarTexts}/> */}

        {!isMobile && (
          <Text style={{ marginRight: "12px" }}>Connect With Us :</Text>
        )}
        {!isMobile && (
          <SocialIconDiv>
            <SocialIcon
              onClick={openInstagram}
              src="https://e7.pngegg.com/pngimages/672/312/png-clipart-instagram-logo-icon-others-text-photography-thumbnail.png"
              alt="Insta"
            />
            <SocialIcon
              onClick={openWhatsApp}
              style={{ marginLeft: "10px" }}
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/2062095_application_chat_communication_logo_whatsapp_icon.svg/2048px-2062095_application_chat_communication_logo_whatsapp_icon.svg.png"
              alt="WhatsApp"
            />
            <SocialIcon
              style={{ marginLeft: "10px" }}
              src="https://cdn.pixabay.com/photo/2021/06/15/12/51/facebook-6338507_1280.png"
              alt="Facebook"
            />
            <SocialIcon
              style={{ marginLeft: "10px" }}
              src="https://static-00.iconduck.com/assets.00/youtube-icon-1024x1024-t3zo1lo4.png"
              alt="Youtube"
            />
          </SocialIconDiv>
        )}

        {/* ORIGINAL PRODUCTS - ORIGINAL MANUFACTURERS */}
      </Container>
      <Wave
        fill="#0c5de7"
        paused={false}
        style={{
          transform: "rotate(180deg)",
          height: "16px",
          marginTop: "-1px",
        }}
        options={{
          height: 6,
          amplitude: 6,
          speed: 0.2,
          points: isMobile ? 3 : 4,
        }}
      />
    </div>
  );
}

export default Topbar;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-color: #0c5de7;
  /*   background-color: #0c5de7; */
  box-shadow: 0px 2px 10px #4d0fdf;
  padding: ${(props) => (props.isMobile ? "2.8vw 0px" : "0.5vw 0px")};

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const Text = styled.div`
  text-align: center;
  letter-spacing: 0.15ch;
  font-size: 0.7vw;
  font-weight: 400;
  white-space: nowrap;
  color: white;

  @media (max-width: 768px) {
    font-size: 3vw;
  }
`;

const SocialIconDiv = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin-right: 20px;
`;

const SocialIcon = styled.img`
  width: 25px;
  height: 25px;
  border-radius: 50%;
`;

// const scrollText = keyframes`
//   0% {
//     transform: translateX(100%);
//   }
//   100% {
//     transform: translateX(-100%);
//   }
// `;

// const MarqueeContainer = styled.div`
// width: 100%;
//   overflow: hidden;
//   white-space: nowrap;
//   box-sizing: border-box;
// `;

// const MarqueeText = styled.div`
//   display: inline-block;
//   padding-left: 5%;
//   animation: ${scrollText} 20s linear infinite;
//   text-align: center;
//   letter-spacing: 0.15ch;
//   font-size: 0.75vw;
//   font-weight: 400;
//   white-space: nowrap;
//   color: white;

//   @media (max-width: 768px) {
//     font-size: 3vw;
//   }
// `;

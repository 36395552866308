import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

function Categories() {
  const navigate = useNavigate();
  return (
    <Container>
      <CategoryColumn
        onClick={() => {
          {
            navigate("/collections/Home/all");
          }
        }}
      >
        <CategoryImages
          src="https://cdn.shopclues.com/images1/thumbnails/101630/280/1/147125571-101630757-1562392793.jpg"
          alt="Category 1"
        />
        <DarkCover />
        <CategoryText>Home Utilities</CategoryText>
      </CategoryColumn>
      <CategoryColumn
        onClick={() => {
          {
            navigate("/collections/Kitchen/all");
          }
        }}
      >
        <CategoryImages
          src="https://cdn.shopify.com/s/files/1/0358/3511/7703/files/Sep_03_480x480.png?v=1631939705"
          alt="Category 1"
        />
        <DarkCover />
        <CategoryText>Kitchen Accessories</CategoryText>
      </CategoryColumn>
      <CategoryColumn
        onClick={() => {
          {
            navigate("/collections/Bathroom/all");
          }
        }}
      >
        <CategoryImages
          src="https://png.pngtree.com/png-vector/20240208/ourlarge/pngtree-housekeeping-and-cleaning-service-png-image_11719478.png"
          alt="Category 1"
        />
        <DarkCover />
        <CategoryText>Bathroom</CategoryText>
      </CategoryColumn>
      <CategoryColumn
        onClick={() => {
          {
            navigate("/collections/Technology/all");
          }
        }}
      >
        <CategoryImages
          src="https://i.pinimg.com/736x/0a/48/55/0a48556cc1b291f006391b624175f719.jpg"
          alt="Category 1"
        />
        <DarkCover />
        <CategoryText>Technology</CategoryText>
      </CategoryColumn>
      <CategoryColumn
        onClick={() => {
          {
            navigate("/collections/Best Sellers/all");
          }
        }}
      >
        <CategoryImages
          src="https://thegreytechnologies.in/cdn/shop/products/portable-washing-machine-3_2000x.jpg?v=1688381901"
          alt="Category 1"
        />
        <DarkCover />
        <CategoryText>Best Sellers</CategoryText>
      </CategoryColumn>
      <CategoryColumn
        onClick={() => {
          {
            navigate("/collections/Cleaning/all");
          }
        }}
      >
        <CategoryImages
          src="https://img.freepik.com/free-vector/surface-cleaning-equipment_23-2148530194.jpg"
          alt="Category 1"
        />
        <DarkCover />
        <CategoryText>Cleaning</CategoryText>
      </CategoryColumn>
      <CategoryColumn
        onClick={() => {
          {
            navigate("/collections/Organizer/all");
          }
        }}
      >
        <CategoryImages
          src="https://www.peelorange.com/cdn/shop/files/Untitleddesign-2024-03-06T213342.337_600x.png?v=1709789707"
          alt="Category 1"
        />
        <DarkCover />
        <CategoryText>Organizers</CategoryText>
      </CategoryColumn>
      <CategoryColumn
        onClick={() => {
          {
            navigate("/collections/Foldable/all");
          }
        }}
      >
        <CategoryImages
          src="https://www.angelfurniture.in/image/cache/catalog/Products/AF402P/71oarCkmJlL._SL1500_%20(1)-550x550.png"
          alt="Category 1"
        />
        <DarkCover />
        <CategoryText>Foldables</CategoryText>
      </CategoryColumn>
    </Container>
  );
}

export default Categories;

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 50px 0px 100px 0px;

  @media (max-width: 768px) {
    width: 98%;
    grid-template-columns: repeat(2, 1fr);
  }
`;

const CategoryColumn = styled.div`
  /* padding: 10px; */
  margin: 10px 5px;
  position: relative;
  cursor: pointer;

  @media (max-width: 768px) {
    cursor: auto;
  }
`;

const CategoryImages = styled.img`
  border-radius: 5px;
  aspect-ratio: 1;
  object-fit: cover;
  width: 18vw;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const DarkCover = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.65);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &:hover{
    background-color: rgba(0, 0, 0, 0.25);
  }
`;

const CategoryText = styled.p`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1vw;
  letter-spacing: -0.02em;
  color: #fff;
  text-align: center;
  margin: 0;
  @media (max-width: 768px) {
    font-size: 4vw;
  }
`;
